import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardContent, Avatar, Typography, Button, Box, Container, CircularProgress, IconButton, Modal, Grid, TextField, Checkbox, FormControlLabel, Radio } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Swal from 'sweetalert2';  // Make sure to import SweetAlert2

const SupervisorSearch = () => {
    const [supervisors, setSupervisors] = useState([]);
    const token = localStorage.getItem("Token");
    const [loading, setLoading] = useState(true);
    const auth = `Bearer ${token}`;
    const headers = {
        Authorization: auth,
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/Users/supervisorMarketingList`, {
                headers,
            })
            .then((response) => {
                setSupervisors(response);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching supervisors", error);
                setLoading(false);
            });
    }, []);
    const [Image, setImage] = useState({});
    useEffect(() => {
        supervisors.forEach((supervisor) => {
            if (supervisor.profilePic) {
                axios
                    .get(`${process.env.REACT_APP_API_URL}/UserDocument/ViewPatientDocument?path=${supervisor.profilePic}`, { headers })
                    .then((response) => {
                        setImage((prevImages) => ({
                            ...prevImages,
                            [supervisor.userId]: response, // Store by userId
                        }));
                    })
                    .catch((error) => {
                        console.error(`Error fetching image for ${supervisor.fullName}`, error);
                    });
            }
        });
    }, [supervisors]);
    const [open, setOpen] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleOpen = (userId) => {
        setSelectedUserId(userId);
        setOpen(true);
    };
    const handleClose = (userId) => {
        setSelectedUserId(0);
        setOpen(false);
        setBriefBio('')
        setComments('')
        setSupervisionStatus(false)
    };
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        height: 500, // Set fixed height
        maxHeight: "90vh", // Ensures it doesn’t go beyond viewport height
        bgcolor: "#3A6A57",
        borderRadius: "10px",
        boxShadow: 24,
        p: 3,
        border: "2px solid #C2A27C",
        zindex: 999,
        overflowY: "auto", // Enables vertical scroll if content overflows
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            background: "#C2A27C",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#3A6A57",
            borderRadius: "10px",
            border: "2px solid #C2A27C",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#2E5544",
        },
        scrollbarColor: "#3A6A57 #C2A27C",
        scrollbarWidth: "thin",
    };
    const [urlFile, seturlFile] = React.useState('')
    const [ImageOfUser, setImageOfUser] = React.useState('')
    const [Details, setDetails] = React.useState([])
    const userid = localStorage.getItem('userid')
    const ProfilePictureBase64 = localStorage.getItem('ProfilePictureBase64')
    useEffect(() => {
        if (userid) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/Users/getUserById?id=${userid}`, {
                    headers,
                })
                .then((response) => {
                    setDetails(response)
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        }
    }, [open]);
    useEffect(() => {
        if (ProfilePictureBase64) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/UserDocument/userDocumentById?Id=${ProfilePictureBase64}`, {
                    headers,
                })
                .then((response) => {
                    seturlFile(response.fileUrl)
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        }
    }, [ProfilePictureBase64]);
    useEffect(() => {
        if (urlFile) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/UserDocument/ViewPatientDocument?path=${urlFile}`, {
                    headers,
                })
                .then((response) => {
                    setImageOfUser(response)
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        }
    }, [urlFile]);

    const [briefBio, setBriefBio] = useState('');
    const [comments, setComments] = useState('');
    const [supervisionStatus, setSupervisionStatus] = useState(null);

    const handleChange = (event) => {
        setSupervisionStatus(event.target.value === 'yes');
    };

    const handleSave = async () => {
        try {
            const requestData = {
                fullName: Details.fullName,
                email: Details.email,
                contactNo: Details.contactNo,
                licenseType: Details.licenseType,
                licenseState: Details.licenseState,
                directHours: Details.directhourshave,
                indirectHours: Details.indirecthourshave,
                briefBio: briefBio,
                comments: comments,
                underSupervison: supervisionStatus,
                supervisorId: selectedUserId,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/TherapistRequest/Request-to-supervisor`,
                requestData,
                { headers }
            );

            handleClose();
            Swal.fire({
                title: 'Request Sent',
                text: 'Your request has been sent successfully!',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                zindex: 99999
            });
        } catch (error) {
            handleClose();
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while sending your request. Please try again.',
                icon: 'error',
                confirmButtonText: 'Okay',
                zindex: 99999
            });
        }
    };
    return (
        <div className="page-content">
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={modalStyle}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Grid container alignItems="center" sx={{ background: "#C2A27C", borderRadius: "8px", padding: "10px" }}>
                                <Grid item xs={11}>
                                    <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
                                        <GroupIcon sx={{ fontSize: 35, }} />  Match Request Form
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: "right" }}>
                                    <CancelOutlinedIcon onClick={handleClose} sx={{ color: "red", cursor: "pointer" }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Avatar
                                    sx={{ width: 130, height: 130, border: "3px solid white" }}
                                    src={`${ImageOfUser}`}
                                    alt="Profile Picture"
                                />
                                <Typography variant="body1" sx={{
                                    maxWidth: '90%',
                                    color: "#C2A27C",
                                    marginLeft: '20px',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    textAlign: 'center'
                                }}>{Details.licenseType}<strong> | {Details.licenseState}</strong> </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={1} >
                                <Grid item xs={12} >
                                    <Typography variant="body1" sx={{ color: "#fff" }}><strong>First Name:</strong> {Details.fullName}</Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant="body1" sx={{ color: "#fff" }}><strong>Email:</strong> {Details.email}</Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography variant="body1" sx={{ color: "#fff" }}><strong>Phone:</strong> {Details.contactNo}</Typography>
                                </Grid>
                                <Grid item xs={6} ></Grid>
                                <Grid item xs={6} >
                                    <Typography variant="body1" sx={{ color: "#fff" }}><strong>Currently Under Supervison:</strong> </Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={supervisionStatus === true}
                                                    onChange={handleChange}
                                                    value="yes"
                                                />
                                            }
                                            label="YES"
                                            sx={{ color: '#fff' }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={supervisionStatus === false}
                                                    onChange={handleChange}
                                                    value="no"
                                                />
                                            }
                                            label="NO"
                                            sx={{ color: '#fff' }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography variant="body1" sx={{ color: "#fff" }}><strong>Direct Hours Completed:</strong> {Details.directhourshave}</Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography variant="body1" sx={{ color: "#fff" }}><strong>Indirect Hours Completed:</strong> {Details.indirecthourshave}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" sx={{ color: "#fff" }}><strong>Brief Bio:</strong></Typography>
                                    <TextField fullWidth multiline rows={2} value={briefBio} onChange={(e) => setBriefBio(e.target.value)} placeholder="Please provide a brief bio. 150 word count" variant="outlined" sx={{ backgroundColor: "#fff", borderRadius: "5px" }} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: "#fff" }}><strong>Comments:</strong></Typography>
                            <TextField fullWidth multiline rows={1} value={comments} onChange={(e) => setComments(e.target.value)} placeholder="Please provide additional comments related to your request. 150 words max." variant="outlined" sx={{ backgroundColor: "#fff", borderRadius: "5px" }} />
                        </Grid>

                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} sx={{ textAlign: "center", mt: 2 }}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleSave}
                                sx={{
                                    backgroundColor: "#C2A27C",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    boxShadow: "4px 4px 5px rgba(0, 0, 0, 0.3)",
                                    "&:hover": { backgroundColor: "#A9906C" }
                                }}
                            >
                                SEND MATCH REQUEST
                            </Button>
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </Box>
            </Modal>
            <Box sx={{ backgroundColor: "#f4f4f4", minHeight: "100vh", }}>
                <Container sx={{ backgroundColor: "#98b2a1", p: 1, borderRadius: 2, mb: 3 }}>
                    <Typography align="center" variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
                        <b>Supervisor Search</b>
                    </Typography>
                </Container>

                <Box
                    sx={{
                        maxWidth: "90%",
                        mx: "auto",
                        padding: '10px',
                        height: "700px",  // Set fixed height here
                        overflowY: "auto", // Enables vertical scrolling
                        borderRadius: "8px", // Rounded corners
                        '&::-webkit-scrollbar': {
                            width: '12px',  // Increase the scrollbar width
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#3A6A57',  // Lighter shade of the previous color
                            borderRadius: '8px',  // Rounded scrollbar thumb
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',  // Scrollbar track color
                            borderRadius: '8px',  // Rounded track corners
                        }
                    }}
                >
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress sx={{ color: "#1B4D3E" }} />
                        </Box>
                    ) : (
                        supervisors.map((supervisor) => (
                            <Card key={supervisor.userId} sx={{ backgroundColor: "#1B4D3E", color: "white", mb: 3, borderRadius: 3, boxShadow: 3 }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            //    src={`${Image}`[supervisor.userId]}
                                            src={Image[supervisor.userId] || "/default-avatar.png"}
                                            alt={supervisor.fullName}
                                            sx={{ width: 70, height: 70, mr: 2, border: "3px solid white" }}
                                        />
                                        <Box>
                                            <Typography variant="h6" fontWeight="bold" color='#C2A27C'>
                                                {supervisor.fullName} | {supervisor.licenseType || "LPC-S"} | {supervisor.licenseState} | {supervisor.chargeforSupervisor}
                                            </Typography>

                                        </Box>
                                    </Box>
                                    <Typography variant="subtitle1" fontWeight="bold" color='#fff'>
                                        Bio
                                    </Typography>
                                    <Typography variant="body2" >{supervisor.briefBio}</Typography>
                                    <Box mt={2}>
                                        <Typography variant="subtitle1" fontWeight="bold" color='#fff'>SUPERVISION STYLE</Typography>
                                        <ul>
                                            {Array.from({ length: 9 }, (_, i) => supervisor[`dropDown${i + 1}`])
                                                .filter(Boolean)
                                                .map((value, index) => (
                                                    <li key={index}>{value}</li>
                                                ))}
                                        </ul>
                                    </Box>
                                    <Box mt={2} display="flex" alignItems="center">
                                        {[...Array(5)].map((_, i) => (
                                            <StarIcon key={i} sx={{ color: "#C2A27C" }} />
                                        ))}
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                                        <Button variant="contained" sx={{ mt: 2, backgroundColor: "#C2A27C", fontWeight: "bold" }}>
                                            SEE ALL REVIEWS
                                        </Button>

                                        <IconButton onClick={() => handleOpen(supervisor.userId)}>
                                            <GroupIcon sx={{ fontSize: 40, color: "#C2A27C" }} />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default SupervisorSearch;