import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Typography, Box, CircularProgress, Grid, Button } from "@mui/material";
import axios from "axios";

const MavesorySubscription = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [InvoiceID, setInvoiceID] = useState('');
    const token = localStorage.getItem("Token");
    const auth = `Bearer ${token}`;
    const headers = { Authorization: auth };
    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/Users/GetAllUserSubscription`,
                    { headers }
                );
                setSubscriptions(response);
                setInvoiceID(response[0].stripeInvoiceId);
            } catch (error) {
                console.error("Error fetching profile:", error);
            } finally {
                setLoading(false); // Set loading to false after API call completes
            }
        };

        fetchSubscriptions();
    }, []);
    const [urlFile, seturlFile] = useState('')
    const [invoiceData, setInvoiceData] = useState(null);
    useEffect(() => {
        if (InvoiceID) {
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/Stripe/Get-Invoicce-by-incoiveId?Id=${InvoiceID}`, { headers }
                )
                .then((response) => {
                    setInvoiceData(response);
                    seturlFile(response.hostedInvoiceUrl);
                })
                .catch((error) => {
                    console.error("Error fetching invoice:", error);
                });
        }
    }, [InvoiceID]);

    return (
        <div className="page-content">
            <Grid container spacing={3} justifyContent="center">
                {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                        <CircularProgress />
                    </Box>
                ) : subscriptions.length > 0 ? (
                    subscriptions.map((sub, index) => (
                        <Grid item key={index} xs={12} >
                            <Card
                                variant="outlined"
                                sx={{
                                    borderRadius: 4,
                                    boxShadow: 3,
                                    bgcolor: "#f5f5f5",
                                    color: "#333",
                                    transition: "0.3s",
                                    "&:hover": { boxShadow: 6 },
                                }}
                            >
                                <CardHeader
                                    title={sub.packageName}
                                    sx={{
                                        bgcolor: "#6F8C75",
                                        color: "white",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        py: 1.5,
                                    }}
                                />
                                <CardContent sx={{ p: 3 }}>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Name:</strong> {sub.fullName}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Email:</strong> {sub.email}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Duration:</strong> {sub.duration}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Price:</strong> ${sub.price}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Start Date:</strong> {new Date(sub.startDate).toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>End Date:</strong> {new Date(sub.endDate).toLocaleDateString()}
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Typography variant="h6" color="textSecondary">
                        No active subscriptions found.
                    </Typography>
                )}

                {invoiceData ? (
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            margin: "auto",
                            width: "80%", // Ensures full width
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                overflow: "hidden",
                                textAlign: "center",
                                width: "100%", // Ensures text expands fully
                            }}
                        >
                            Invoice is available.
                        </Typography>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <a
                                href={urlFile}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none", width: "100%" }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 2,
                                        backgroundColor: "#C2A27C",
                                        fontWeight: "bold",
                                        boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
                                        "&:hover": { backgroundColor: "#6F8C75" },
                                        width: "100%", // Makes button full width
                                    }}
                                >
                                    View Invoice
                                </Button>
                            </a>
                        </Grid>
                    </Box>
                ) : (
                    <Typography sx={{ textAlign: "center", width: "100%" }}>No Invoice found</Typography>
                )}

            </Grid>

        </div>
    );
};

export default MavesorySubscription;
