import React, { useState, useEffect } from "react";
import { Card, CardContent, InputLabel } from "@mui/material";
import { Tabs, Tab, Box, Container, CircularProgress, Grid, Typography, Button, Modal, IconButton, TextField, MenuItem, Checkbox, Paper, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import axios from "axios";
import ReactSelect from "react-select";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Swal from 'sweetalert2';
import Select from "react-select";
import moment from 'moment'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const SupervisorSupervisee = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    const [supervisionModalOpen, setSupervisionModalOpen] = useState(false);
    const [selectedSupervisee, setSelectedSupervisee] = useState({});
    const [terminationDate, setTerminationDate] = useState("");
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [otherReason, setOtherReason] = useState("");

    const handleReasonChange = (reason) => {
        setSelectedReasons((prev) =>
            prev.includes(reason)
                ? prev.filter((r) => r !== reason) // Remove if already selected
                : [...prev, reason] // Add if not selected
        );
    };

    const handleAddCloseupervision = () => {
        setSupervisionModalOpen(false)
        setSelectedSupervisee({})
        setTerminationDate('')
        setSelectedReasons([])
        setOtherReason('')
    };
    const handleEndSupervisionClick = (row) => {
        setSelectedSupervisee(row);
        setSupervisionModalOpen(true);
    };
    const handleTerminateSupervision = () => {
        const postData = {
            requestId: selectedSupervisee.id,
            therapistId: selectedSupervisee.id,
            terminationDate: terminationDate,
            reasonOfTermination: selectedReasons.join(","),
            specifyReason: selectedReasons.includes("other") ? otherReason : null,
            inActive: false
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/TherapistRequest/EndSupervison`, postData, { headers })
            .then((response) => {
                handleAddCloseupervision()
                Swal.fire("Success", "Supervision has been terminated.", "success");

            })
            .catch((error) => {
                handleAddCloseupervision()
                Swal.fire("Error", "Failed to terminate supervision.", "error");
            });
    };

    const superviseeColumns = [
        {
            field: 'userName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Name',
            cellClassName: 'gridCell',
            flex: 0.28,
        },

        {
            field: 'licenseNumber',
            headerClassName: 'super-app-theme--header',
            headerName: 'License Number',
            flex: 0.32
        },
        {
            field: 'licenseExpirationDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Expiration Date',
            flex: 0.32,
            valueGetter: (params) => params.value ? moment(params.value).format('MM-DD-YYYY') : ''
        },
        {
            field: '',
            headerClassName: 'super-app-theme--header',
            headerName: 'End Supervison',
            flex: 0.2,
            renderCell: (cell) => (
                <Button
                    sx={{
                        backgroundColor: '#D2B48C', // Tan color
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: '8px',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                        '&:hover': {
                            backgroundColor: '#C19A6B',
                        },
                    }}
                    // onClick={() => {
                    //     Swal.fire({
                    //         title: 'Are you sure?',
                    //         text: 'Do you want to delete the record?',
                    //         icon: 'warning',
                    //         showCancelButton: true,
                    //         confirmButtonColor: '#d33',
                    //         cancelButtonColor: '#3085d6',
                    //         confirmButtonText: 'Yes, delete it!',
                    //         cancelButtonText: 'No',
                    //     }).then((result) => {
                    //         if (result.isConfirmed) {
                    //             const postData = {
                    //                 inActive: true,
                    //                 id: cell.row.id
                    //             };

                    //             axios
                    //                 .post(
                    //                     `${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`,
                    //                     postData,
                    //                     { headers }
                    //                 )
                    //                 .then((response) => {
                    //                     Swal.fire('Deleted!', 'The record has been deleted.', 'success');
                    //                 })
                    //                 .catch((error) => {
                    //                     console.error('There was an error!', error);
                    //                     Swal.fire('Error!', 'There was an error deleting the record.', 'error');
                    //                 });
                    //         }
                    //     });
                    // }}
                    onClick={() => handleEndSupervisionClick(cell.row)}
                >
                    End Supervison
                </Button>
            ),
        },

    ]
    const [AddGroupModal, setAddGroupModal] = React.useState(false);
    const [selectedGroupName, setSelectedGroupName] = React.useState('');
    const [selectedGroupId, setselectedGroupId] = React.useState('');
    const [isRemoving, setIsRemoving] = React.useState(false);

    const handleAddOpen = (groupName, removing = false) => {
        setSelectedGroupName(groupName.groupName);
        setselectedGroupId(groupName.id);
        setIsRemoving(removing); // Set the removing flag
        setAddGroupModal(true);
    };

    const handleAddClose = () => {
        setAddGroupModal(false);
        setSelectedGroupName('')
        setTherapistID('')
    };
    const [RemoveGroupModal, setRemoveGroupModal] = React.useState(false);
    const handleRemove = (row) => {
        RemoveGroupModal(true)
    };
    const handleRemoveClose = () => {
        RemoveGroupModal(false);
    }
    const [GroupData, setGroupData] = React.useState([]);

    useEffect(() => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_URL}/SupervisorGroups/GetAllGroupMember?GroupId=${selectedGroupId}`, {
                headers
            })
            .then(response => {
                setGroupData(response)
                setLoading(false)
            })
            .catch(error => { });
    }, [selectedGroupId]);
    const GroupColumns = [
        {
            field: 'groupName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Group Name',
            cellClassName: 'gridCell',
            flex: 0.28,
        },

        {
            field: 'createdDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Group Created Date',
            flex: 0.32
        },
        {
            field: 'add',
            headerClassName: 'super-app-theme--header',
            headerName: 'Action',
            flex: 0.2,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#D2B48C', // Tan color
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: '8px',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                        '&:hover': {
                            backgroundColor: '#C19A6B',
                        },
                    }}
                    onClick={() => handleAddOpen(params.row, false)}
                >
                    Add Member
                </Button>
            ),
        },
        {
            field: 'remove',
            headerClassName: 'super-app-theme--header',
            headerName: 'Action',
            flex: 0.2,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#D2B48C', // Tan color
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: '8px',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                        '&:hover': {
                            backgroundColor: '#C19A6B',
                        },
                    }}
                    onClick={() => handleAddOpen(params.row, true)}
                >
                    Remove Member
                </Button>
            ),

        },
        {
            field: 'cancel',
            headerClassName: 'super-app-theme--header',
            headerName: 'Action',
            flex: 0.2,
            renderCell: (cell) => (
                <Button
                    variant="contained"
                    onClick={() => {
                        Swal.fire({
                            title: 'Are you sure?',
                            text: 'Do you want to delete the record?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: 'Yes, delete it!',
                            cancelButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                const postData = {
                                    inActive: true,
                                    id: cell.row.id
                                };

                                axios
                                    .post(
                                        `${process.env.REACT_APP_API_URL}/SupervisorGroups/addSupervisorGroup`,
                                        postData,
                                        { headers }
                                    )
                                    .then((response) => {
                                        Swal.fire('Deleted!', 'The record has been deleted.', 'success');
                                    })
                                    .catch((error) => {
                                        console.error('There was an error!', error);
                                        Swal.fire('Error!', 'There was an error deleting the record.', 'error');
                                    });
                            }
                        });
                    }}
                    sx={{
                        backgroundColor: '#D2B48C', // Tan color
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: '8px',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                        '&:hover': {
                            backgroundColor: '#C19A6B',
                        },
                    }}
                >
                    Remove Group
                </Button>
            ),

        },

    ]
    const gridRowStyle = {
        boxShadow: 2,
        border: 2,
        borderRadius: 2,
        background: 'drak',
        height: '300px',
        fontSize: '13.4px !important',
        color: 'drak !important',
        borderColor: activeTab === 0 ? '#C2A27C' : '#6F8C75',
        '& .MuiDataGrid-cell:hover': {
            color: 'rgba(5, 152, 236, 0.637) !important',
            fontSize: '14.6px !important',
        },
        '& .super-app-theme--header': {
            background: activeTab === 0 ? '#C2A27C' : '#6F8C75',
            borderRadius: 0.1,
            color: 'white',
            fontSize: '16px !important',

        },

    }
    const gridClasses = {
        columnHeader: 'MuiDataGrid-columnHeaders',
    };
    const _userId = localStorage.getItem('userid')
    const [isLoading, setLoading] = useState(false)
    const token = localStorage.getItem('Token')
    const auth = `Bearer ${token}`
    const headers = {
        Authorization: auth
    }
    const [TherapistID, setTherapistID] = useState("");
    const [TherapistList, setTherapistList] = useState([]);
    const [GroupRows, setGroupRows] = useState([]);
    const [SuperviseeRows, setSuperviseeRows] = useState([]);

    useEffect(() => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/GetThrepistListBySupervisorId?supervisorId=${_userId}`, { headers })
            .then(response => {
                setLoading(false)
                setSuperviseeRows(response)
                var prac = [];
                response.forEach(field => {
                    prac.push({
                        label: field.fullName,
                        value: field.id
                    });
                });
                setTherapistList(prac);

            })
            .catch(error => { })
    }, [activeTab])
    const [groupID, setGroupID] = useState("");
    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/SupervisorGroups/supervisorGroupList?supervisorId=${_userId}`, {
                headers
            })
            .then(response => {
                setGroupRows(response)
                var prac = [];
                response.forEach(field => {
                    prac.push({
                        label: field.groupName,
                        value: field.id
                    });
                });
                setGroupList(prac);

            })
            .catch(error => { });

    }, [activeTab]);
    const [groupcomment, setGroupComment] = useState('');
    const [GroupNameField, setGroupNameField] = useState('');
    const [groupName, setGroupName] = React.useState(false);
    const handleOpengroupName = () => setGroupName(true);
    const handleClosegroupName = () => {
        setGroupName(false);
        setGroupComment('');
        setGroupNameField('');
    };
    const [error, setError] = useState({ GroupNameField: false, groupcomment: false });

    const validateFields = () => {
        const errors = {
            groupName: !GroupNameField.trim(),
            groupcomment: !groupcomment.trim(),
        };
        setError(errors);
        return !errors.GroupNameField && !errors.groupcomment;
    };

    const handleAddGroup = () => {
        if (!validateFields()) {
            handleClosegroupName();
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please fill out all required fields.',
            });
            return;
        }

        const postData = {
            groupName: GroupNameField,
            comments: groupcomment,
            supervisorId: _userId,
        };

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/SupervisorGroups/addSupervisorGroup`,
                postData,
                { headers }
            )
            .then(() => {
                handleClosegroupName(); // Call the handle function first

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Record saved successfully.',
                    timer: 2000,
                    timerProgressBar: true,
                })
            })
            .catch((error) => {
                handleClosegroupName(); // Call the handle function first
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Error: ${error.message}`,
                });
            });
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
    };
    const [therapistsIdError, setTherapistsIdError] = React.useState(false);
    const [GroupIdError, setGroupIdError] = React.useState(false);

    const AddTherapist = (event) => {
        if (!TherapistID) {
            setTherapistsIdError(true);
            return;
        }
        if (!groupID) {
            setGroupIdError(true);
            return;
        }

        const postData = {
            therapistId: TherapistID,
            supervisorId: _userId,
            groupId: groupID,
            type: "Therapist"
        };


        axios
            .post(
                `${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`,
                postData,
                { headers }
            )
            .then((response) => {
                setTherapistsIdError(false)
                setGroupIdError(false)
                Swal.fire({
                    title: 'Success!',
                    text: 'Therapist added successfully.',
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false,
                });
            })
            .catch((error) => {
                if (error == 'Request failed with status code 400') {
                    Swal.fire({
                        title: 'Duplicate Entry',
                        text: 'The selected therapist is already assigned to this group.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: `An unexpected error occurred: ${error.message}`,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                }
            });
    };
    const handleAddSupervisee = () => {
        if (!TherapistID) {
            setTherapistsIdError(true);
            return;
        }

        const apiUrl = isRemoving
            ? `${process.env.REACT_APP_API_URL}/SupervisorTherapist/RemoveGroupMember`
            : `${process.env.REACT_APP_API_URL}/SupervisorTherapist/addGroupMember`;

        axios
            .post(`${apiUrl}?GroupId=${selectedGroupId}&TherapistId=${TherapistID}`, {}, { headers })
            .then(() => {
                setTherapistsIdError(false);
                handleAddClose();

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: isRemoving ? 'Member removed successfully.' : 'Record saved successfully.',
                    timer: 2000,
                    timerProgressBar: true,
                });
            })
            .catch((error) => {
                console.error("API Error:", error);
                handleAddClose();

                Swal.fire({
                    icon: 'error',
                    text: error == "Request failed with status code 400" && !isRemoving
                        ? 'This therapist is already added in a Group'
                        : "Something went wrong.",
                });
            });
    };


    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [selectedState, setSelectedState] = useState(null);
    const states = [
        { value: "Alabama", label: "Alabama" },
        { value: "Alaska", label: "Alaska" },
        { value: "Arizona", label: "Arizona" },
        { value: "Arkansas", label: "Arkansas" },
        { value: "California", label: "California" },
        { value: "Colorado", label: "Colorado" },
        { value: "Connecticut", label: "Connecticut" },
        { value: "Delaware", label: "Delaware" },
        { value: "Florida", label: "Florida" },
        { value: "Georgia", label: "Georgia" },
        { value: "Hawaii", label: "Hawaii" },
        { value: "Idaho", label: "Idaho" },
        { value: "Illinois", label: "Illinois" },
        { value: "Indiana", label: "Indiana" },
        { value: "Iowa", label: "Iowa" },
        { value: "Kansas", label: "Kansas" },
        { value: "Kentucky", label: "Kentucky" },
        { value: "Louisiana", label: "Louisiana" },
        { value: "Maine", label: "Maine" },
        { value: "Maryland", label: "Maryland" },
        { value: "Massachusetts", label: "Massachusetts" },
        { value: "Michigan", label: "Michigan" },
        { value: "Minnesota", label: "Minnesota" },
        { value: "Mississippi", label: "Mississippi" },
        { value: "Missouri", label: "Missouri" },
        { value: "Montana", label: "Montana" },
        { value: "Nebraska", label: "Nebraska" },
        { value: "Nevada", label: "Nevada" },
        { value: "New Hampshire", label: "New Hampshire" },
        { value: "New Jersey", label: "New Jersey" },
        { value: "New Mexico", label: "New Mexico" },
        { value: "New York", label: "New York" },
        { value: "North Carolina", label: "North Carolina" },
        { value: "North Dakota", label: "North Dakota" },
        { value: "Ohio", label: "Ohio" },
        { value: "Oklahoma", label: "Oklahoma" },
        { value: "Oregon", label: "Oregon" },
        { value: "Pennsylvania", label: "Pennsylvania" },
        { value: "Rhode Island", label: "Rhode Island" },
        { value: "South Carolina", label: "South Carolina" },
        { value: "South Dakota", label: "South Dakota" },
        { value: "Tennessee", label: "Tennessee" },
        { value: "Texas", label: "Texas" },
        { value: "Utah", label: "Utah" },
        { value: "Vermont", label: "Vermont" },
        { value: "Virginia", label: "Virginia" },
        { value: "Washington", label: "Washington" },
        { value: "West Virginia", label: "West Virginia" },
        { value: "Wisconsin", label: "Wisconsin" },
        { value: "Wyoming", label: "Wyoming" },
    ];
    const [licenseNumber, setLicenseNumber] = useState("");
    const [selectedLicenses, setSelectedLicenses] = useState([]);
    const licenseOptions = [
        "LPC-ASSOCIATE",
        "LCSW-ASSOCIATE",
        "LMSW OR MSW",
        "LCDC-I",
        "LICENSED SPECIALIST IN SCHOOL PSYCHOLOGY",
        "LICENSED PSYCHOLOGICAL ASSOCIATE"
    ];
    const handleLicenseChange = (license) => {
        setSelectedLicenses((prev) =>
            prev.includes(license) ? prev.filter((item) => item !== license) : [...prev, license]
        );
    };

    const modalStyleLink = {
        position: "absolute",
        top: "50%",
        left: "58%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        height: 700, // Set fixed height
        maxHeight: "90vh", // Ensures it doesn’t go beyond viewport height
        bgcolor: "white",
        borderRadius: "10px",
        boxShadow: 24,
        p: 3,
        border: "2px solid #C2A27C",
        overflowY: "auto", // Enables vertical scroll if content overflows
        "&::-webkit-scrollbar": {
            width: "12px", // Thickness of the scrollbar
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1", // Track color
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#C2A27C", // Scrollbar color
            borderRadius: "10px",
            border: "3px solid #f1f1f1", // Adds padding effect
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#A08A67", // Darker shade on hover
        },
    };
    const [openModalinkSupervisor, setopenModalinkSupervisor] = useState(false);
    const [openModalDirectSign, setopenModalDirectSign] = useState(false);

    const handleOpenModallink = () => setopenModalinkSupervisor(true);
    const [SeacrhData, setSeacrhData] = useState(null);
    const [SeacrhDataShow, setSeacrhDataShow] = useState(false);

    const handleCloseModallink = () => {
        setopenModalinkSupervisor(false);
        setSeacrhData(null)
        setSeacrhDataShow(false)
        setLastName("");
        setFirstName("");
        setSelectedState(null);
        setLicenseNumber("");
        setSelectedLicenses([])
    }


    const handleOpenModalDirectSign = () => setopenModalDirectSign(true);
    const handleCloseModalDirectSign = () => setopenModalDirectSign(false);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const requestData = {
                lastname: lastName,
                firstname: firstName,
                licenseNumber: licenseNumber,
                licenseState: selectedLicenses.join(","),
                state: selectedState
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/TherapistRequest/TherapistSearch`, requestData,
                {
                    headers: headers
                }
            );

            setSeacrhData(response);
            setSeacrhDataShow(true);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [currentAction, setCurrentAction] = useState(''); // 'Approve' or 'Cancel'
    const [currentRow, setCurrentRow] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    // Function to open the action modal and set action type (Approve or Cancel)
    const handleActionModal = (actionType, row) => {
        setCurrentAction(actionType);
        setCurrentRow(row);
        setIsActionModalOpen(true);
        setErrorMessage('');
    };

    // Function to handle close modal
    const handleCloseActionModal = () => {
        setIsActionModalOpen(false);
        setCurrentAction('');
        setCurrentRow(null);
        setErrorMessage('');
    };

    // Function to handle the request based on action type (Approve/Reject)
    const handleRequest = () => {
        const postData = {
            // requestId: currentRow.id,
        };

        const url =
            currentAction === 'Approve'
                ? `${process.env.REACT_APP_API_URL}/TherapistRequest/ApproveRequest?requestId=${currentRow.id}`
                : `${process.env.REACT_APP_API_URL}/TherapistRequest/RejectRequest?requestId=${currentRow.id}`;

        axios
            .post(url, postData, { headers })
            .then((response) => {
                Swal.fire(`${currentAction}d!`, `The record has been ${currentAction.toLowerCase()}d.`, 'success');
                handleCloseActionModal(); // Close modal after success
            })
            .catch((error) => {
                console.error('There was an error!', error);
                setErrorMessage(`There was an error ${currentAction.toLowerCase()}ing the request. Please try again later.`);
            });
    };

    const RequestColumns = [
        {
            field: 'fullName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Name',
            cellClassName: 'gridCell',
            flex: 0.28,
        },
        {
            field: 'licenseNumber',
            headerClassName: 'super-app-theme--header',
            headerName: 'License #',
            cellClassName: 'gridCell',
            flex: 0.32
        },
        {
            field: 'licenseState',
            headerClassName: 'super-app-theme--header',
            headerName: 'State',
            cellClassName: 'gridCell',
            flex: 0.32
        },
        {
            field: 'licenseType',
            headerClassName: 'super-app-theme--header',
            headerName: 'License Type',
            cellClassName: 'gridCell',
            flex: 0.32
        },
        // {
        //     field: 'remove',
        //     headerClassName: 'super-app-theme--header',
        //     headerName: 'Approve',
        //     flex: 0.2,
        //     renderCell: (cell) => (
        //         <IconButton
        //             onClick={() => {
        //                 handleActionModal('Approve', cell);
        //             }}
        //         >
        //             <CheckCircleIcon sx={{ color: '#D2B48C', '&:hover': { color: '#C19A6B' } }} />
        //         </IconButton>
        //     ),
        // },
        // {
        //     field: 'Cancel',
        //     headerClassName: 'super-app-theme--header',
        //     headerName: 'Cancel',
        //     flex: 0.2,
        //     renderCell: (cell) => (
        //         <IconButton
        //             onClick={() => {
        //                 handleActionModal('Cancel', cell);
        //             }}
        //         >
        //             <CancelOutlinedIcon sx={{ color: '#D2B48C', '&:hover': { color: '#C19A6B' } }} />
        //         </IconButton>
        //     ),
        // },



    ]
    const innerPopUpStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        margin: 'auto',
        padding: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 24,
        zIndex: 9999,
        textAlign: 'center',
    };

    const yesButtonStyle = (action) => ({
        backgroundColor: action === 'Approve' ? '#4CAF50' : '#f44336',
        color: 'white',
        '&:hover': {
            backgroundColor: action === 'Approve' ? '#388E3C' : '#D32F2F',
        },
    });

    const noButtonStyle = {
        borderColor: '#888',
        color: '#888',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    };
    const [directLinkFirstName, setDirectLinkFirstName] = useState("");
    const [directLinkLastName, setDirectLinkLastName] = useState("");
    const [directLinkEmail, setDirectLinkEmail] = useState("");

    const [errors, setErrors] = useState({});

    const validateFieldsLINk = () => {
        let errors = {};
        if (!directLinkLastName.trim()) {
            errors.lastName = "Please enter Last Name";
        }
        if (!directLinkFirstName.trim()) {
            errors.firstName = "Please enter First Name";
        }
        if (!directLinkEmail.trim()) {
            errors.email = "Please enter Email";
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(directLinkEmail)) {
            errors.email = "Please enter a valid Email";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleSubmitDirectLink = async () => {
        if (!validateFieldsLINk()) return;
        const payload = {
            firstName: directLinkFirstName,
            lastName: directLinkLastName,
            email: directLinkEmail,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/TherapistRequest/Send-Email-Link`, payload, { headers });
            handleCloseModalDirectSign();
            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Link sent successfully!",
                });
                setDirectLinkFirstName("");
                setDirectLinkLastName("");
                setDirectLinkEmail("");

            }
        } catch (error) {
            handleCloseModalDirectSign();
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to send link. Please try again.",
            });
        }
    };
    return (
        <div className="page-content">
            <Box>
                <Modal open={isActionModalOpen} onClose={handleCloseActionModal}>
                    <Box sx={innerPopUpStyle}>
                        <Typography variant="h6" gutterBottom>
                            {currentAction === 'Approve' ? 'Approve Request' : 'Cancel Request'}
                        </Typography>
                        <Typography variant="body1">
                            Are you sure you want to {currentAction.toLowerCase()} this request?
                        </Typography>

                        {/* Display error message if there is one */}
                        {errorMessage && (
                            <Typography variant="body2" sx={{ color: 'red', marginTop: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                            <Button variant="outlined" onClick={handleCloseActionModal} sx={noButtonStyle}>
                                No
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleRequest}
                                sx={yesButtonStyle(currentAction)}
                            >
                                Yes, {currentAction === 'Approve' ? 'Approve' : 'Cancel'}
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Modal open={AddGroupModal} onClose={handleAddClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                    <Box sx={modalStyleLink}>
                        <Grid container spacing={2}>
                            {/* Modal Header */}
                            <Grid item xs={12}>
                                <Grid container alignItems="center" style={{ background: '#C2A27C', borderRadius: '8px', padding: '10px' }}>
                                    <Grid item xs={11}>
                                        <Typography variant="h6" style={{ color: '#fff', fontWeight: 'bold' }}>
                                            {isRemoving ? 'Remove Superviee' : "Add Supervisee"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                                        <CancelOutlinedIcon
                                            onClick={handleAddClose}
                                            style={{ color: 'red', cursor: 'pointer' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>

                                {isLoading ? (
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    GroupData?.length > 0 ? (
                                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, flexWrap: "wrap" }}>
                                            <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center", color: "#333", mb: 1 }}>
                                                Group Members
                                            </Typography>
                                            {GroupData.map((row, index) => (
                                                <Paper
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{
                                                        bgcolor: "#F0F6F8",
                                                        borderRadius: "15px",
                                                        border: "2px solid #C2A27C",
                                                        boxShadow: 1,
                                                        padding: 2,
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#333" }}>
                                                        Supervisee: {row.fullName}
                                                    </Typography>
                                                </Paper>
                                            ))}
                                        </Box>
                                    ) : (
                                        <Typography sx={{ textAlign: "center", color: "gray", fontSize: "14px", mt: 2 }}>
                                            No Supervisees Found in This Group
                                        </Typography>
                                    )
                                )}

                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "left", color: "#ccccc", mb: 1, textDecoration: 'underline' }}>
                                    {selectedGroupName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ marginBottom: '5px' }}>Group Name</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Enter Group Name"
                                    value={selectedGroupName}
                                    // onChange={(e) => setGroupNameField(e.target.value)}
                                    InputProps={{
                                        readOnly: true, // Make the field readonly
                                    }}

                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography style={{ marginBottom: '5px' }}>Supervisee</Typography>
                                <ReactSelect
                                    fullWidth
                                    value={TherapistList.filter(function (option) {
                                        return (
                                            option.value === TherapistID
                                        );
                                    })}
                                    onChange={e => {
                                        setTherapistID(e.value);
                                        setTherapistsIdError(false)
                                    }}
                                    options={TherapistList}
                                />
                                {therapistsIdError && <p style={{ color: 'red', fontSize: '14px' }}>Please select a Superviee.</p>}
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 2,
                                        backgroundColor: "#C2A27C",
                                        fontWeight: "bold",
                                        boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
                                        "&:hover": { backgroundColor: "#B2956E" }
                                    }}
                                    onClick={handleAddSupervisee}
                                >
                                    {isRemoving ? 'Remove Superviee' : "Add Supervisee"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Group Name Modal */}
                <Modal open={groupName} onClose={handleClosegroupName} aria-labelledby="modal-title" aria-describedby="modal-description">
                    <Box sx={modalStyleLink}>
                        <Grid container spacing={2}>
                            {/* Modal Header */}
                            <Grid item xs={12}>
                                <Grid container alignItems="center" style={{ background: '#B2956E', borderRadius: '8px', padding: '10px' }}>
                                    <Grid item xs={11}>
                                        <Typography variant="h6" style={{ color: '#fff', fontWeight: 'bold' }}>
                                            CREATE NEW GROUP
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                                        <CancelOutlinedIcon
                                            onClick={handleClosegroupName}
                                            style={{ color: 'red', cursor: 'pointer' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ marginBottom: '5px' }}>Group Name</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Enter Group Name"
                                    value={GroupNameField}
                                    onChange={(e) => setGroupNameField(e.target.value)}
                                    error={error.groupname}
                                    helperText={error.groupname && 'Group name is required.'}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography style={{ marginBottom: '5px' }}>Comment</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Enter Comment"
                                    value={groupcomment}
                                    onChange={(e) => setGroupComment(e.target.value)}
                                    multiline
                                    rows={3}
                                    error={error.groupcomment}
                                    helperText={error.groupcomment && 'Comment is required.'}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 2,
                                        backgroundColor: "#C2A27C",
                                        fontWeight: "bold",
                                        boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
                                        "&:hover": { backgroundColor: "#B2956E" }
                                    }}
                                    onClick={handleAddGroup}
                                >
                                    SAVE
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Supervisor Link Modal */}
                <Modal open={openModalinkSupervisor} onClose={handleCloseModallink} aria-labelledby="modal-title" aria-describedby="modal-description">
                    <Box sx={modalStyleLink}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" sx={{ background: SeacrhDataShow == false ? "#6F8C75" : '#B2956E', borderRadius: "8px", padding: "10px" }}>
                                    <Grid item xs={11}>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            {SeacrhDataShow && (
                                                <IconButton onClick={() => setSeacrhDataShow(false)} sx={{
                                                    bgcolor: '#fff',
                                                    color: 'black',
                                                    '&:hover': { bgcolor: '#B2956E', color: '#fff' },
                                                    boxShadow: 2,
                                                    transition: 'all 0.3s ease-in-out',
                                                }}>
                                                    <ArrowBackIcon />
                                                </IconButton>
                                            )}

                                            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
                                                {SeacrhDataShow == false ? 'Search and Link Supervisee' : 'Search Result'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} sx={{ textAlign: "right" }}>
                                        <CancelOutlinedIcon onClick={handleCloseModallink} sx={{ color: "red", cursor: "pointer" }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {SeacrhDataShow == false && (
                                <>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>LAST NAME:</Typography>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Type Last Name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>FIRST NAME:</Typography>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Type First Name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>STATE:</Typography>
                                        <Select
                                            options={states}
                                            value={states.find(option => option.label === selectedState)} // Set the selected state
                                            onChange={(option) => {
                                                setSelectedState(option.label); // Update local state
                                            }}
                                            placeholder="Select a state"
                                            menuPortalTarget={document.body} // Render menu in the body for proper z-index
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    height: '40px', // Make it match the TextField height
                                                    zIndex: 1, // Ensure control stays above overlapping elements
                                                }),
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999, // High z-index for the dropdown menu
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    maxHeight: '190px', // Set the dropdown max height to 100px
                                                    overflowY: 'auto', // Add a scrollbar if options exceed the height
                                                }),
                                                menuList: (base) => ({
                                                    ...base,
                                                    maxHeight: '180px',
                                                    marginBottom: '10px',
                                                    // Scrollbar styling
                                                    '&::-webkit-scrollbar': {
                                                        width: '6px',
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: '#aec3b0', // Scrollbar color
                                                        borderRadius: '10px',
                                                    },
                                                    '&::-webkit-scrollbar-thumb:hover': {
                                                        backgroundColor: '#8B4513', // Brown hover effect
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        backgroundColor: '#f5f5f5', // Track color
                                                    },
                                                }),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>LICENSE NUMBER:</Typography>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Please type their license number"
                                            value={licenseNumber}
                                            onChange={(e) => setLicenseNumber(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>
                                            LICENSE TYPE: (PLEASE SELECT THE APPROPRIATE LICENSE TYPE)
                                        </Typography>
                                        {licenseOptions.map((license) => (
                                            <FormControlLabel
                                                key={license}
                                                control={<Checkbox checked={selectedLicenses.includes(license)} onChange={() => handleLicenseChange(license)} />}
                                                label={license}
                                                sx={{ display: "block", color: "#776B5D" }}
                                            />
                                        ))}
                                    </Grid>
                                    <Grid item xs={3} >
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: "center", mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleSearch}
                                            sx={{
                                                backgroundColor: "#C2A27C",
                                                color: "#fff",
                                                fontWeight: "bold",
                                                boxShadow: "4px 4px 5px rgba(0, 0, 0, 0.3)",
                                                "&:hover": { backgroundColor: "#A9906C" }
                                            }}

                                        >
                                            {isLoading ? '...Searching' : "Search"}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3} >
                                    </Grid>
                                </>
                            )}
                            {SeacrhDataShow && (
                                <Grid item xs={12}>
                                    {isLoading ? (
                                        <div style={{ textAlign: "center", margin: "5rem" }}>
                                            <CircularProgress />
                                        </div>
                                    ) : SeacrhData && SeacrhData.length > 0 ? (
                                        <DataGrid
                                            rows={SeacrhData}
                                            classes={gridClasses}
                                            rowHeight={20}
                                            columns={RequestColumns}
                                            fullWidth
                                            sx={gridRowStyle}
                                            pagination
                                            autoPageSize
                                        />
                                    ) : (
                                        <Typography sx={{ textAlign: "center", mt: 2 }}>No Records Found</Typography>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Modal>
                {/* Direct Sign Modal */}
                <Modal open={openModalDirectSign} onClose={handleCloseModalDirectSign} aria-labelledby="modal-title" aria-describedby="modal-description">
                    <Box sx={modalStyleLink}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" sx={{ background: "#C2A27C", borderRadius: "8px", padding: "10px" }}>
                                    <Grid item xs={11}>
                                        <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
                                            Direct Sign Up Link
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ textAlign: "right" }}>
                                        <CancelOutlinedIcon onClick={handleCloseModalDirectSign} sx={{ color: "red", cursor: "pointer" }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>LAST NAME:</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Type Last Name"
                                    value={directLinkLastName} onChange={(e) => setDirectLinkLastName(e.target.value)}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>FIRST NAME:</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Type First Name"
                                    value={directLinkFirstName} onChange={(e) => setDirectLinkFirstName(e.target.value)}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>Email:</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Please type Email"
                                    value={directLinkEmail} onChange={(e) => setDirectLinkEmail(e.target.value)}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={3} >
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "center", mt: 2 }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "#C2A27C",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        boxShadow: "4px 4px 5px rgba(0, 0, 0, 0.3)",
                                        "&:hover": { backgroundColor: "#A9906C" }
                                    }}
                                    onClick={handleSubmitDirectLink}
                                >
                                    SEND LINK
                                </Button>
                            </Grid>
                            <Grid item xs={3} >
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* End Supervsion Modal  */}
                <Modal open={supervisionModalOpen} onClose={handleAddCloseupervision} aria-labelledby="modal-title" aria-describedby="modal-description">
                    <Box sx={modalStyleLink}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" sx={{ background: "#C2A27C", borderRadius: "8px", padding: "10px" }}>
                                    <Grid item xs={11}>
                                        <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
                                            End Supervison
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ textAlign: "right" }}>
                                        <CancelOutlinedIcon onClick={handleAddCloseupervision} sx={{ color: "red", cursor: "pointer" }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ color: "#776B5D" }}><b>User NAME:</b>{selectedSupervisee.userName}</Typography>

                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ color: "#776B5D" }}><b>License Number:</b>{selectedSupervisee.licenseNumber}</Typography>

                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ color: "#776B5D" }}><b>License ExpirationDate:</b>{moment(selectedSupervisee.licenseExpirationDate).format("MM-DD-YYYY")}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ color: "#776B5D" }}>Termination Date</Typography>

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="date"
                                    size="small"
                                    value={terminationDate}
                                    onChange={(e) => setTerminationDate(e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: "bold", color: "#776B5D" }}>Reason for Terminating Supervision: </Typography>

                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedReasons.includes("completed")}
                                            onChange={() => handleReasonChange("completed")}
                                        />
                                    }
                                    label="The supervisee has completed all board requirements and is no longer under supervision."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedReasons.includes("transitioning")}
                                            onChange={() => handleReasonChange("transitioning")}
                                        />
                                    }
                                    label="The supervisee is transitioning to a new supervisor."
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedReasons.includes("other")}
                                            onChange={() => handleReasonChange("other")}
                                        />
                                    }
                                    label="Other (please specify):"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {selectedReasons.includes("other") && (
                                    <TextField
                                        value={otherReason}
                                        onChange={(e) => setOtherReason(e.target.value)}
                                        placeholder="Specify reason..."
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            </Grid>

                            <Grid item xs={3} >
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "center", mt: 2 }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={handleTerminateSupervision}
                                    sx={{
                                        backgroundColor: "#C2A27C",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        boxShadow: "4px 4px 5px rgba(0, 0, 0, 0.3)",
                                        "&:hover": { backgroundColor: "#A9906C" }
                                    }}
                                >
                                    Terminate and Notify Sites
                                </Button>
                            </Grid>
                            <Grid item xs={3} >
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Card
                    style={{
                        padding: "20px",
                        // maxWidth: "800px",
                        margin: "20px auto",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <CardContent>
                        <Box style={{ borderBottom: "2px solid #ccc", marginBottom: "20px" }}>
                            <Tabs
                                value={activeTab}
                                onChange={handleTabChange}
                                centered
                                TabIndicatorProps={{
                                    style: {
                                        height: "4px",
                                        width: '100%',
                                        backgroundColor: activeTab === 0 ? "#fff" : "#fff",
                                    },
                                }}
                            >
                                <Tab
                                    label="Supervisees"
                                    style={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        fontSize: '25px',
                                        width: '100%',
                                        color: activeTab === 0 ? "#fff" : "#c4a866",
                                        backgroundColor: activeTab === 0 ? "#c4a866" : "#f4f4f4",
                                        borderRadius: "4px 0 0 4px",
                                    }}
                                />
                                <Tab
                                    label="Groups"
                                    style={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        width: '100%',
                                        fontSize: '25px',
                                        color: activeTab === 1 ? "#fff" : "#98b2a1",
                                        backgroundColor: activeTab === 1 ? "#98b2a1" : "#f4f4f4",
                                        borderRadius: "0 4px 4px 0",
                                    }}
                                />
                            </Tabs>
                        </Box>


                        {activeTab === 1 && (
                            <>
                                <Box style={{ marginTop: "20px", width: "100%" }}>
                                    <Grid container spacing={1}>
                                        {/* <Grid item xs={4} >
                                            <InputLabel>Group</InputLabel>
                                            <ReactSelect
                                                fullWidth
                                                value={groupList.filter(function (option) {
                                                    return (
                                                        option.value === groupID
                                                    );
                                                })}
                                                onChange={e => {
                                                    setGroupID(e.value);
                                                }}
                                                options={groupList}
                                            />
                                            {GroupIdError && <p style={{ color: 'red', fontSize: '14px' }}>Please select a Group.</p>}
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                onClick={handleOpengroupName}
                                                style={{
                                                    color: 'green',
                                                    padding: '5px',
                                                    float: 'right',
                                                    marginTop: '25px'
                                                }}
                                            >
                                                <AddCircleOutlineRoundedIcon fontSize="large" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={4} >
                                            <InputLabel>Supervisee</InputLabel>
                                            <ReactSelect
                                                fullWidth
                                                value={TherapistList.filter(function (option) {
                                                    return (
                                                        option.value === TherapistID
                                                    );
                                                })}
                                                onChange={e => {
                                                    setTherapistID(e.value);
                                                }}
                                                options={TherapistList}
                                            />
                                            {therapistsIdError && <p style={{ color: 'red', fontSize: '14px' }}>Please select a Therapist.</p>}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button
                                                className="Button"
                                                onClick={AddTherapist}
                                                fullWidth
                                                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold', marginTop: '25px' }}
                                            >
                                                Add
                                            </Button>
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} xl={12}>
                                            {isLoading ? (
                                                <div style={{ margin: '5rem', textAlign: 'center' }}>
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <div mb={0} style={{ height: 340, width: '100%' }}>

                                                    {GroupRows === null || GroupRows.length === 0 ? ("No Record Found") : (
                                                        <DataGrid
                                                            rows={GroupRows}
                                                            classes={gridClasses}
                                                            rowHeight={20}
                                                            columns={GroupColumns}
                                                            fullWidth
                                                            sx={gridRowStyle}
                                                            pagination
                                                            autoPageSize
                                                        />
                                                    )}

                                                </div>
                                            )}

                                        </Grid>
                                    </Grid>

                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            mt: 2,
                                            backgroundColor: "#C2A27C",
                                            fontWeight: "bold",
                                            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
                                            "&:hover": { backgroundColor: "#B2956E" }
                                        }}
                                        onClick={handleOpengroupName}
                                    >
                                        CREATE NEW GROUP
                                    </Button>

                                    {/* <Button
                                        variant="contained"
                                        sx={{
                                            mt: 2,
                                            backgroundColor: "#C2A27C",
                                            fontWeight: "bold",
                                            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
                                            "&:hover": { backgroundColor: "#B2956E" }
                                        }}
                                    >
                                        REMOVE A GROUP
                                    </Button> */}
                                </Box>
                            </>
                        )}

                        {activeTab === 0 && (
                            <>
                                <Box style={{ marginTop: "20px", width: "100%" }}>
                                    {isLoading ? (
                                        <div style={{ margin: '5rem', textAlign: 'center' }}>
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <div mb={0} style={{ height: 340, width: '100%' }}>
                                            {SuperviseeRows === null || SuperviseeRows.length === 0 ? ("No Record Found") : (
                                                <DataGrid
                                                    rows={SuperviseeRows}
                                                    classes={gridClasses}
                                                    rowHeight={20}
                                                    columns={superviseeColumns}
                                                    fullWidth
                                                    sx={gridRowStyle}
                                                    pagination
                                                    autoPageSize
                                                />
                                            )}
                                        </div>
                                    )}
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            mt: 2,
                                            backgroundColor: "#C2A27C",
                                            fontWeight: "bold",
                                            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
                                            "&:hover": { backgroundColor: "#B2956E" }
                                        }}
                                        onClick={handleOpenModallink}
                                    >
                                        SEARCH AND LINK SUPERVISEE
                                    </Button>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            mt: 2,
                                            backgroundColor: "#C2A27C",
                                            fontWeight: "bold",
                                            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
                                            "&:hover": { backgroundColor: "#B2956E" }
                                        }}
                                        onClick={handleOpenModalDirectSign}
                                    >
                                        SEND DIRECT SIGN UP LINK TO SUPERVISEE
                                    </Button>
                                </Box>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Box >
        </div >
    );
};

export default SupervisorSupervisee;
