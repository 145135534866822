const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    
    {
        label: "Supervisor Dashboard",
        icon: "mdi mdi-view-dashboard",
        isHasArrow: true,
        url: "/SuperviserDashboard",
    },
    {
        label: "Supervisee",
        icon: "mdi mdi-account-group",
        isHasArrow: true,
        url: "/Supervisee",
    },
    {
        label: "Therapist Dashboard",
        icon: "mdi mdi-view-dashboard",
        isHasArrow: true,
        url: "/TherapistDashboard",
    },
    
    {
        label: "Calendar",
        icon: "mdi mdi-calendar",
        isHasArrow: true,
        url: "/Scheduler",
    },
    {
        label: "Sites",
        icon: "mdi mdi-earth",
        isHasArrow: true,
        url: "/SitePage",
    },
   
    {
        label: "Agendas",
        icon: "mdi mdi-book-outline",
        isHasArrow: true,
        url: "/Agendas",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline", // Icon for documents
        isHasArrow: true,
        url: "/Documents",
    },
    {
        label: "Time Tracking",
        icon: "mdi mdi-clock-outline",
        isHasArrow: true,
        url: "/TrackHours",
    },
    {
        label: "Growth Plans",
        icon: "mdi mdi-rocket-launch-outline", // Icon for growth
        isHasArrow: true,
        url: "/GrowthPlane",
    },
    {
        label: "Incident Reporting",
        icon: "mdi mdi-alert-outline",
        isHasArrow: true,
        url: "/IncidentReporting",
    },
    // {
    //     label: "Message",
    //     icon: "mdi mdi-message-outline",
    //     isHasArrow: true,
    //     url: "/Message",
    // },
    {
        label: "Leave Request",
        icon: "mdi mdi-leaf",
        isHasArrow: true,
        url: "/LeaveRequest",
    },

    {
        label: "Billing",
        icon: "mdi mdi-cash-usd",
        isHasArrow: true,
        url: "/PaymentMethod",
    },


    
   
    {
        label: "Setting",
        isMainMenu: true,
    },
    // {
    //     label: "User",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/User",
    // },
    // {
    //     label: "Therapist",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/Therapist",
    // },
    // {
    //     label: "Supervisor",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/Supervisor",
    // },
    // {
    //     label: "Sites",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/SitePage",
    // },

    {
        label: "User",
        icon: "mdi mdi-account",
        isHasArrow: true,
        url: "/User",
    },
    {
        label: "Therapist",
        icon: "mdi mdi-account-heart",
        isHasArrow: true,
        url: "/Therapist",
    },
    {
        label: "Supervisor",
        icon: "mdi mdi-account-tie",
        isHasArrow: true,
        url: "/Supervisor",
    },
   
    {
        label: "Signup Requests",
        icon: "mdi mdi-account-check-outline",
        isHasArrow: true,
        url: "/SignupRequests",
    },
    {
        label: "Tickets",
        icon: "mdi mdi-ticket-outline",
        isHasArrow: true,
        url: "/Tickets",
    },
   
    // {
    //     label: "VideoComponent",
    //     icon: "mdi mdi-earth",
    //     isHasArrow: true,
    //     url: "/VideoComponent",
    // },
    
    // {
    //     label: "Education Details",
    //     icon: "mdi mdi-school-outline",
    //     isHasArrow: true,
    //     url: "/EducationDetails",
    // },
    
   
    
    
    
]
export default SidebarData;