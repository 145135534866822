import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import RadioGroup from '@mui/material/RadioGroup'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment'
import { Workbook } from 'exceljs';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select,
  Dialog, DialogActions, DialogContent, DialogContentText, Alert
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import { toast } from 'react-toastify';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SignInIcon from '@mui/icons-material/PersonAdd';


const GrowthPlanRegistration = props => {

  const fields = [
    "id",
    "practiceId",
    "supervisorId",
    "growthPlanId",
    "firstName",
    "lastName",
    "conductRequiringRemediation",
    "ethicalRules",
    "remediationPlan",
    "educationalSteps",
    "therapistId",
    "supervisorSign",
    "superViseeSign",
    "approveDateTime",
    "approve",
    "pendingApproval"
  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    supervisorId: 0,
    growthPlanId: 0,
    firstName: "",
    lastName: "",
    conductRequiringRemediation: "",
    ethicalRules: "",
    remediationPlan: "",
    educationalSteps: "",
    therapistId: "",
    supervisorSign: '',
    superViseeSign: '',
    approveDateTime: '',
    approve: false,
    pendingApproval: false
  };
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const Userid = localStorage.getItem('userid')
  const UserType = localStorage.getItem("User_Type")
  const SupervisorIDOfTherapist = localStorage.getItem("SupervisorID")
  const [Growthid, setGrowthid] = useState(null);
  useEffect(() => {
    if (UserType == "Therapist") {
      setGrowthid(SupervisorIDOfTherapist);
    } else if (UserType == "Supervisor") {
      setGrowthid(Userid);
    }
  }, [UserType, Userid]);
  const validationSchema = Yup.object().shape({
    // therapistId: Yup.string().required('Therapist is required'),  
    conductRequiringRemediation: Yup.string().required('Conduct Requiring Remediation is required'),
    ethicalRules: Yup.string().required('Ethical Rules are required')
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.1,
    },
    {
      field: 'lastName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Therapist Name ',
      cellClassName: 'gridCell',
      flex: 0.28,
      renderCell: (cellValues) => (
        <Button
          style={{ color: 'rgba(58, 53, 65, 0.87)', display: 'flex', justifyContent: 'flex-start' }}
          size="small"
          variant="text"
          color="info"
          onClick={() => {
            setOpenModal(true);
            setRowID(cellValues.row.id);
            let rowid = cellValues.row.id;
            axios
              .get(`${process.env.REACT_APP_API_URL}/GrowthPlan/GrowthPlanById?id=${rowid}`, { headers })
              .then((response) => {
                fields.forEach(field => {
                  setValue(field, response[field]);
                  setTherpistName(response.lastName)
                  setTherapistID(response.therapistId)
                  setApprove(response.approve)
                })
                var dateYMD = Moment(response.completionDate).format('YYYY-MM-DD')
                setcompletionDate(dateYMD)
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
              });
          }}
        >{`${cellValues.row.lastName}`}</Button>
      ),
    },

    {
      field: 'supervisorSign',
      headerClassName: 'super-app-theme--header',
      headerName: 'Supervisor Sign',
      flex: 0.32
    },
    {
      field: 'superViseeSign',
      headerClassName: 'super-app-theme--header',
      headerName: 'Supervisee Sign',
      flex: 0.32
    },

    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.14,
      renderCell: cellValues => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '-1px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={() => handleOpenModalDel(cellValues.row)}
        />
      )
    }



  ]

  const [completionDate, setcompletionDate] = React.useState(Moment().format('YYYY-MM-DD'))

  const [alertOpen, setAlertOpen] = useState(false);
  const [therapistSignError, settherapistSignError] = useState(false);
  const [SupervisorSignError, setSupervisorSignError] = useState(false);
  const UserName = localStorage.getItem("UserName")

  const handleSignInSupervisor = () => {
    setValue('supervisorSign', UserName);
    setSupervisorSignError(false)
  };

  const handleSignInSupervisee = () => {
    setValue('superViseeSign', UserName);
    settherapistSignError(false)

  };
  const [TherapistError, setTherapistError] = useState(false)

  const onSubmit = (data) => {
    let postData = {
      ...data, supervisorId: Growthid, lastName: TherpistName, therapistId: TherapistID,
    };
    if (!TherapistID) {
      setTherapistError(true)
      setSubmitting(false);
      return;
    }

    if (UserType == 'Supervisor') {
      if (!data.supervisorSign) {
        setSupervisorSignError(true);
        setSubmitting(false);
        return;
      }
      postData.approveDateTime = Moment().format('YYYY-MM-DD HH:mm');
      postData.approve = true
    }
    if (UserType == 'Therapist') {
      if (!data.superViseeSign) {
        settherapistSignError(true);
        setSubmitting(false);
        return;
      }
      postData.therapistTimeStamp = Moment().format('YYYY-MM-DD HH:mm');

    }

    setSubmitting(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/GrowthPlan/addGrowthPlan`, postData, { headers })
      .then(response => {
        setSubmitting(false);
        setRefresh(!refresh)
        HandleCloseModal()
        setAlertOpen(true); // Show alert dialog
        setTimeout(() => {
          setAlertOpen(false); // Close alert dialog after 2 seconds
        }, 2000);
      })
      .catch(error => {
        setSubmitting(false);
        alert(`Error: ${error.message}`);
      });
  };
  const [refresh, setRefresh] = useState(false)
  const [rows, setRows] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [OpenModal, setOpenModal] = React.useState(false);
  const [rowId, setRowID] = React.useState(0);

  const HandleOpen = () => {
    setOpenModal(true)
  };
  const HandleCloseModal = () => {
    setOpenModal(false)
    fields.forEach((field) => setValue(field, ''));
    reset();
    setRowID(0);
    setApprove(false)
  };

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [Approve, setApprove] = useState(false);

  const handleOpenModalDel = (row) => {
    setDeleteRow(row);
    setDeleteAlert(true);
  };

  const handleCloseModal = () => {
    setDeleteAlert(false);
    setDeleteRow(null);

  };
  const handleDelete = () => {
    const postData = {
      inactive: true,
      id: deleteRow.id,
    };

    axios.post(`${process.env.REACT_APP_API_URL}/GrowthPlan/addGrowthPlan`, postData, { headers })
      .then(() => {
        setRefresh(!refresh);
        isLoading(true)
        handleCloseModal();
      })
      .catch(error => {
        console.error('There was an error!', error);
        handleCloseModal();
      });
  };

  useEffect(() => {
    if (Growthid) {
      setLoading(true);

      // Construct API URL based on UserType
      let apiUrl = `${process.env.REACT_APP_API_URL}/GrowthPlan/GrowthPlanlistBySupervisorId`;

      // Conditionally append parameters based on UserType
      if (UserType === 'Therapist') {
        apiUrl += `?therapistId=${Userid}`;
      } else {
        apiUrl += `?supervisorId=${Growthid}`;
      }

      axios
        .get(apiUrl, { headers })
        .then(response => {
          setLoading(false);
          setRows(response);
          setData(response);
        })
        .catch(error => {
          setLoading(false); // Ensure loading state is set to false even on error
          console.error('Error fetching data:', error);
        });
    }
  }, [Growthid, UserType, refresh]);


  const [Data, setData] = useState([])
  const handleDownload = async () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Set column widths
    worksheet.columns = [
      { header: 'ID', key: 'id', width: 7 },
      { header: 'Conduct Requiring Remediation', key: 'conductRequiringRemediation', width: 25 },
      { header: 'Ethical Rules', key: 'ethicalRules', width: 25 },
      { header: 'Remediation Plan', key: 'remediationPlan', width: 25 },
      { header: 'Therapist Name', key: 'lastName', width: 18 },
    ];

    // Add data to the worksheet
    Data.forEach((item) => {
      worksheet.addRow({
        id: item.id,
        conductRequiringRemediation: item.conductRequiringRemediation,
        ethicalRules: item.ethicalRules,
        remediationPlan: item.remediationPlan,
        lastName: item.lastName,

      });
    });

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;

    const fileName = `GrowthPlan_${moment(new Date()).format('DD_MMM_YY')}.xlsx`;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 470,
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    // overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflowY: 'auto'


  }
  const [TherapistList, setTherapistList] = useState([]);
  const [TherpistName, setTherpistName] = useState("");
  const [TherapistID, setTherapistID] = useState(null);


  useEffect(() => {
    if (UserType != 'Supervisor') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.fullName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/GetThrepistListBySupervisorId?supervisorId=${Userid}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.fullName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    }
  }, []);
  useEffect(() => {
    if (UserType == "Therapist") {
      setTherapistID(Userid);
      setTherpistName(UserName);
    }
  }, [UserType, Userid]);

  const [FilePath, setFilePath] = useState('')
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/GrowthPlan/PdfGrowthPlan?id=${rowId}`,
        { headers }
      )
      .then((response) => {

        setFilePath(response)
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, [rowId]);
  const [pdfFile, setpdfFile] = useState('');
  // const [openPdf, setOpenPdf] = useState(false);
  // const handleClosePDF = () => {
  //   setOpenPdf(false);
  // };

  const handleDownloadPDF = () => {
    // setOpenPdf(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/ProviderDocument/DownloadAWSFile?path=${FilePath}`, {
        headers,
        responseType: 'blob',
      })
      .then((response) => {
        // const url = window.URL.createObjectURL(new Blob([response]));
        // setpdfFile(url);

        // // Automatically trigger the download
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'file.pdf'); // Specify the file name
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);
        const fileExtension = FilePath.split('.').pop().toLowerCase();
        const blobUrl = window.URL.createObjectURL(new Blob([response]));
        const fileName =
          fileExtension === 'pdf'
            ? 'file.pdf'
            : `image.${fileExtension}`; // Use dynamic naming for images
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', fileName); // Dynamically set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };

  return (
    <div className="page-content">
      <Container>
        <Dialog
          open={deleteAlert}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontWeight: 'bolder' }} id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ fontWeight: '200' }}>
              Do you want to delete the record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} variant="contained" style={{ background: '#377562' }}>
              No
            </Button>
            <Button onClick={handleDelete} variant="contained" style={{ background: '#377562' }} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <Alert severity="success">
              Growth Plan saved successfully!
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={OpenModal}
          onClose={HandleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'}
        >
          <Box sx={style}>
            <Headroom >
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          // textAlign: 'left',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Growth Plan

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={HandleCloseModal}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>
            </Headroom>

            <Grid container spacing={1}>
              <Grid item xs={8} style={{ marginTop: '10px' }}>
              </Grid>
              <Grid item xs={3} style={{ marginTop: '10px' }}>
                <PictureAsPdfIcon
                  fontSize="large"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    float: 'right'
                    // marginLeft: 'auto',
                    // marginRight: '1.5rem'
                  }}
                  onClick={handleDownloadPDF}
                >
                  Pdf Download
                </PictureAsPdfIcon>
              </Grid>
              {/* <Grid item xs={2} style={{ marginTop: '10px' }}>
            </Grid>
            <Grid item xs={8} style={{ marginTop: '10px' }}>
              {FilePath.length > 0 ? (

                <img
                  height='400px'
                  width='850px'
                  style={{ border: '4px solid #000' }}
                  src={pdfFile}
                  alt='Document'
                />
              ) : ''}
            </Grid>
            <Grid item xs={2} style={{ marginTop: '10px' }}>
            </Grid> */}

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Therapist
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                {/* <Typography style={{ fontSize: '14px' }}>
                First Name
                  </Typography> */}
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name="therapistId"
                  control={control}
                  {...register('therapistId')}
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        fullWidth
                        isDisabled={UserType === "Therapist" || Approve === true}
                        value={TherapistList.find((option) => option.value == TherapistID)}
                        onChange={e => {
                          setValue("therapistId", e.value);
                          setTherpistName(e.label);
                          setTherapistID(e.value);
                          setTherapistError(false)

                        }}
                        options={TherapistList}

                      />
                    );
                  }}
                />
                {TherapistError && (
                  <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                    Please Select Therapist
                  </Typography>
                )}
              </Grid>


              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px', textAlign: 'center' }}>
                  The following is identified as conduct requiring remediation in accordance with licensing rules <b style={{ color: 'black' }}> (Supervisor outlines specific scenario)</b>:

                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='conductRequiringRemediation'
                  {...register('conductRequiringRemediation')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='Enter Requiring Remediation'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '70px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      fullWidth
                      disabled={Approve === true}
                      error={errors.conductRequiringRemediation}
                    />
                  )}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.conductRequiringRemediation?.message}</p>

              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px', textAlign: 'center' }}>
                  The following ethical rules are applicable in the aforementioned <b style={{ color: 'black' }}>(List specific Rules from Respective Ethical Board, if Ethics; other areas as noted)</b>:

                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='ethicalRules'
                  {...register('ethicalRules')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='Enter Ethical Rules'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '70px', // set a fixed height here
                        //   overflow: 'scroll'

                      }}
                      {...field}
                      fullWidth
                      disabled={Approve === true}
                      error={errors.ethicalRules}
                    />
                  )}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.ethicalRules?.message}</p>

              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px', textAlign: 'center' }}>
                  The following Remediation Plan is implemented  <b style={{ color: 'black' }}>(Supervisor lists specific behaviors or steps that are taken, dates, and any other parties that are involved)</b>:

                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='remediationPlan'
                  {...register('remediationPlan')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='Enter Remediation Plan'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '70px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      disabled={Approve === true}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px', textAlign: 'center' }}>
                  The following educational steps are assigned to increase knowledge  <b style={{ color: 'black' }}>(Supervisor lists specific training, or other educational materials to support the plan, if applicable)</b>:

                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='educationalSteps'
                  {...register('educationalSteps')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='Enter Educational Steps'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '70px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      disabled={Approve == true}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Supervisee Signature

                </Typography>

              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Supervisor Signature

                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>



              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="superViseeSign"
                  control={control}
                  {...register("superViseeSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisee Signature..."
                      {...field}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSignInSupervisee}
                              disabled={UserType === 'Supervisor'} // Disable button for Therapist
                            >
                              <SignInIcon style={{ color: '#356F60' }} />

                            </IconButton>
                          </InputAdornment>
                        ),
                      }}

                      onChange={e => {
                        setValue('superViseeSign', e.target.value);
                      }}

                    />
                  }
                />
                {therapistSignError && <p style={{ color: 'red' }}> Supervisee Sign required</p>}

              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="supervisorSign"
                  control={control}
                  {...register("supervisorSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervsior Signature..."
                      {...field}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSignInSupervisor}
                              disabled={UserType === 'Therapist'} // Disable button for Therapist
                            >
                              <SignInIcon style={{ color: '#356F60' }} />

                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => {
                        setValue('supervisorSign', e.target.value);
                      }}

                    />
                  }
                />
                {SupervisorSignError && <p style={{ color: 'red' }}> Supervisor Sign required</p>}

              </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Button
                  className="Button"
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting}
                  // disabled={submitting}
                  fullWidth
                  variant='contained' style={{
                    fontSize: '14px', fontWeight: 'bold',
                  }}
                >
                  {UserType == 'Therapist' ? 'Singed' : 'Save'}
                </Button>
              </Grid>


              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            </Grid>

          </Box>

        </Modal>
        <Grid container spacing={1}>
          <Grid item xs={6}></Grid>
          {UserType !== 'Therapist' ? (
            <Grid item xs={3} sm={3} >
              <Button className="Button" fullWidth onClick={HandleOpen} variant='contained' style={{ height: '35px', marginBottom: '5px' }}>
                <AddOutlinedIcon
                  fontSize='small'
                  style={{
                    color: 'white',
                    paddingRight: '4px',
                    paddingBottom: '2px',

                  }}
                ></AddOutlinedIcon>
                Add New
              </Button>
            </Grid>
          ) : (
            <Grid item xs={3} sm={3} xl={3}></Grid>
          )}
          <Grid item xs={3} sm={3} xl={3}>
            <Button
              className="Button"
              onClick={handleDownload}
              fullWidth
              disabled={!Data || Data.length === 0}
              variant='contained' style={{
                fontSize: '14px', fontWeight: 'bold',
                float: 'right'
              }}
              type="submit"
            >
              Download
              <DownloadForOfflineIcon />
            </Button>



          </Grid>
        </Grid>


        <Grid item xs={12} sm={12} xl={12}>
          <Box mb={0} style={{ marginTop: 0 }}>
            {isLoading ? (
              <div style={{ margin: '5rem', textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <div mb={0} style={{ height: 400, width: '100%' }}>
                {rows === null || rows.length === 0 ? ("No Record Found") : (
                  <DataGrid
                    rows={rows}
                    classes={gridClasses}
                    rowHeight={20}
                    columns={columns}
                    getRowSpacing={getRowSpacing}
                    //checkboxSelection
                    fullWidth
                    sx={gridRowStyle}
                    pagination
                    autoPageSize
                  />
                )}
              </div>
            )}
          </Box>
        </Grid>
      </Container>
    </div>
  );
};
export default GrowthPlanRegistration;
