import { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, CircularProgress, Container, Grid, IconButton, Typography, Modal, Button, InputLabel, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Swal from 'sweetalert2';
import Select from "react-select";
import moment from 'moment';
const TherapistRequest = () => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('Token')
    const auth = `Bearer ${token}`
    const headers = {
        Authorization: auth
    }
    const statusOptions = [
        { value: "all", label: "All" },
        { value: "approved", label: "Approved" },
        { value: "rejected", label: "Rejected" }
    ];
    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
    const fetchRequests = async (status) => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/TherapistRequest/GetAllRequests?requestString=${status.value}`,
                // {},  
                { headers }
            );

            setRows(response);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRequests(selectedStatus);
    }, [selectedStatus]);

    const [selectedTherapistId, setSelectedTherapistId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [ActionRowid, setActionRowid] = useState(null);

    const handleOpenModal = (therapistId) => {
        setSelectedTherapistId(therapistId);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedTherapistId(null);
        setActionRowid(null)
    };
    const columns = [
        {
            field: 'therapistName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Name',
            cellClassName: 'gridCell',
            flex: 0.4,
            renderCell: (cell) => (
                <Button
                    style={{
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        width: '100%', // Ensures the button takes full width
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                    size='small'
                    variant='text'
                    color='info'
                    onClick={() => {
                        setActionRowid(cell.row.id);
                        handleOpenModal(cell.row.therapistId);
                    }}
                >
                    {`${cell.row.therapistName}`}</Button>
            ),
        },
        {
            field: 'licenseNumber',
            headerClassName: 'super-app-theme--header',
            headerName: 'License #',
            cellClassName: 'gridCell',
            flex: 0.2
        },
        {
            field: 'licenseState',
            headerClassName: 'super-app-theme--header',
            headerName: 'State',
            cellClassName: 'gridCell',
            flex: 0.2
        },
        {
            field: 'licenseType',
            headerClassName: 'super-app-theme--header',
            headerName: 'License Type',
            cellClassName: 'gridCell',
            flex: 0.35
        },
        {
            field: "approve",
            headerClassName: "super-app-theme--header",
            headerName: "Approved",
            cellClassName: "gridCell",
            flex: 0.2,
            renderCell: (params) => (
                params.value ? (
                    <CheckCircleIcon sx={{ color: '#22d155' }} />
                ) : (
                    <CancelOutlinedIcon sx={{ color: 'red' }} />
                )
            ),
        }

    ];

    const gridRowStyle = {
        boxShadow: 2,
        border: 2,
        borderRadius: 2,
        background: 'drak',
        height: '300px',
        fontSize: '13.4px !important',
        color: 'drak !important',
        borderColor: '#C2A27C',
        '& .MuiDataGrid-cell:hover': {
            color: 'rgba(5, 152, 236, 0.637) !important',
            fontSize: '14.6px !important',
        },
        '& .super-app-theme--header': {
            background: '#C2A27C',
            borderRadius: 0.1,
            color: 'white',
            fontSize: '16px !important',

        },

    }
    const gridClasses = {
        columnHeader: 'MuiDataGrid-columnHeaders',
    };
    const modalStyleLink = {
        position: "absolute",
        top: "50%",
        left: "58%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        height: 700, // Set fixed height
        maxHeight: "90vh", // Ensures it doesn’t go beyond viewport height
        bgcolor: "white",
        borderRadius: "10px",
        boxShadow: 24,
        p: 3,
        border: "2px solid #C2A27C",
        overflowY: "auto", // Enables vertical scroll if content overflows
        "&::-webkit-scrollbar": {
            width: "12px", // Thickness of the scrollbar
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1", // Track color
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#C2A27C", // Scrollbar color
            borderRadius: "10px",
            border: "3px solid #f1f1f1", // Adds padding effect
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#A08A67", // Darker shade on hover
        },
    };
    const [UserData, setUserData] = useState(null);

    useEffect(() => {
        if (selectedTherapistId) {
            setIsLoading(true)
            axios
                .get(`${process.env.REACT_APP_API_URL}/Users/getUserById?id=${selectedTherapistId}`, {
                    headers
                })
                .then(response => {
                    setUserData(response)
                    setIsLoading(false)
                })
                .catch(error => { })
                .finally(() => {
                    setIsLoading(false); // Stop loading after request completes
                });
        }
    }, [modalOpen]);

    const handleApproveRequest = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/TherapistRequest/ApproveRequest?requestId=${ActionRowid}`,
                {},
                { headers }
            );
            handleCloseModal()
            Swal.fire("Success", "Request approved successfully!", "success");
        } catch (error) {
            handleCloseModal()
            Swal.fire("Error", "Something went wrong!", "error");
            console.error("Approval error:", error);
        }
    }

    const handleRejectRequest = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/TherapistRequest/RejectRequest?requestId=${ActionRowid}`,
                {},
                { headers }
            );
            handleCloseModal()
            Swal.fire("Success", "Request Reject successfully!", "success");
        } catch (error) {
            handleCloseModal()
            Swal.fire("Error", "Something went wrong. Please try again!", "error");
            console.error("Reject error:", error);
        }
    };

    return (
        <div className="page-content">
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box sx={modalStyleLink}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" style={{ background: '#C2A27C', borderRadius: '8px', padding: '10px' }}>
                            <Grid item xs={11}>
                                <Typography variant="h6" style={{ color: '#fff', fontWeight: 'bold' }}>
                                    SUPERVISEE INFO
                                </Typography>
                            </Grid>
                            <Grid item xs={1} style={{ textAlign: 'right' }}>
                                <CancelOutlinedIcon
                                    onClick={handleCloseModal}
                                    style={{ color: 'red', cursor: 'pointer' }}
                                />
                            </Grid>
                        </Grid>
                        {isLoading ? (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={1}>

                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>User Name</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.fullName : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Email</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.email : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>DOB</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData && UserData.dob ? moment(UserData.dob).format('MM-DD-YYYY') : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>ZipCode</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.zipCode : 'N/A'}
                                    </Typography>
                                </Grid>


                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Gender</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.gender : 'N/A'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Preferred Gender</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.preferredGender : 'N/A'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Ethnicity</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.ethnicity : 'N/A'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Address</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.address : 'N/A'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Address</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.address : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Contact No #</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.contactNo : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>License Type</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.licenseType : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>License Number</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.licenseNumber : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>License State</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.licenseState : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>City</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.city : 'N/A'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Initial Licensure Date</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData && UserData.initialLicensureDate ? moment(UserData.initialLicensureDate).format('MM-DD-YYYY') : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>License Expiration Date</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData && UserData.licenseExpirationDate ? moment(UserData.licenseExpirationDate).format('MM-DD-YYYY') : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Liability Insurance Carrier</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.liabilityInsuranceCarrier : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Insurance Policy Start Date</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData && UserData.insurancePolicyStartDate ? moment(UserData.insurancePolicyStartDate).format('MM-DD-YYYY') : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Insurance Policy End Date</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData && UserData.insurancePolicyEndDate ? moment(UserData.insurancePolicyEndDate).format('MM-DD-YYYY') : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Policy Number</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.policyNumber : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel sx={{ fontWeight: 'bold', color: 'gray', mb: 0.5 }}>Type</InputLabel>
                                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', fontWeight: '500', p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                                        {UserData ? UserData.userType : 'N/A'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            mt: 2,
                                            py: 1.5,
                                            backgroundColor: "#C2A27C",
                                            fontWeight: "bold",
                                            fontSize: "1rem",
                                            boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
                                            borderRadius: "8px",
                                            "&:hover": { backgroundColor: "#B2956E" },
                                        }}
                                        onClick={handleApproveRequest}
                                    >
                                        Approve Request
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            mt: 2,
                                            py: 1.5,
                                            backgroundColor: "#C2A27C",
                                            fontWeight: "bold",
                                            fontSize: "1rem",
                                            boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
                                            borderRadius: "8px",
                                            "&:hover": { backgroundColor: "#B2956E" },
                                        }}
                                        onClick={handleRejectRequest}
                                    >
                                        Cancel Request
                                    </Button>
                                </Grid>

                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Modal>
            <Container>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Select
                                options={statusOptions}
                                value={selectedStatus}
                                onChange={setSelectedStatus}
                                isSearchable={false}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={2} style={{ height: 400, width: "100%" }}>
                            {isLoading ? (
                                <div style={{ textAlign: "center", marginTop: "2rem" }}>
                                    <CircularProgress />
                                </div>
                            ) : rows.length === 0 ? (
                                "No Record Found"
                            ) : (
                                <DataGrid
                                    rows={rows}
                                    classes={gridClasses}
                                    rowHeight={20}
                                    columns={columns}
                                    fullWidth
                                    sx={gridRowStyle}
                                    pagination
                                    autoPageSize
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default TherapistRequest;
