import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import withRouter from "../withRouter";
import { Link, useNavigate } from 'react-router-dom';
// users
// import user1 from "../../../assets/images/users/avatar-1.jpg";
import { Typography } from "@mui/material";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const userName = localStorage.getItem("UserName")
  const UserType = localStorage.getItem('User_Type')
  const [username, setusername] = useState("Admin");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);
  const navigate = useNavigate()
  const logout = () => {
    localStorage.setItem('lastLogout', new Date().toISOString()); // Store last logout time in UTC
    navigate('/login'); // Redirect to login page
  };
  const checkForMidnightLogout = () => {
    const now = new Date();
    const lastLogout = localStorage.getItem('lastLogout'); // Get the last logout time

    if (lastLogout) {
      const lastLogoutDate = new Date(lastLogout);
      const isNewDay = now.getUTCDate() !== lastLogoutDate.getUTCDate() ||
                       now.getUTCMonth() !== lastLogoutDate.getUTCMonth() ||
                       now.getUTCFullYear() !== lastLogoutDate.getUTCFullYear();

      if (isNewDay) {
        logout(); // Logout if it's a new day
      }
    } else {
      // If no lastLogout is found, set it to the current time
      localStorage.setItem('lastLogout', now.toISOString());
    }
  }; 
  useEffect(() => {
    checkForMidnightLogout();
    const now = new Date();
    const nextMidnight = new Date(now);
    nextMidnight.setUTCHours(24, 0, 0, 0); 

    const timeUntilMidnight = nextMidnight - now;
    const timeoutId = setTimeout(() => {
      logout();
      const intervalId = setInterval(logout, 86400000);
      return () => clearInterval(intervalId); 
    }, timeUntilMidnight);

    return () => clearTimeout(timeoutId); 
  }, [navigate]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src="/Avatar.png"
            alt="Header Avatar"
          /> */}
          {/* <span className="d-none d-xl-inline-block ms-2 me-2"><b>{userName}</b></span> */}
          <span className="d-none d-xl-inline-block ms-2 me-2"><b style={{ fontSize: "19px" }}>Mavesory</b></span>
          <i style={{ fontSize: "20px" }} className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem style={{ textAlign: "left" }}>
            {/* <i className="ri-user-line align-middle me-2 text-info" />
            My Profile */}
            <Link to="/Profile"  style={{ display: "flex", alignItems: "left", textDecoration: "none",color:'#505d69' }}>
            <i className="ri-user-line align-middle me-2 text-info" />
              <Typography style={{ fontSize: "14px", textAlign: "left" }}>
                {props.t("My Profile")}
              </Typography>
            </Link>
          </DropdownItem>
          <DropdownItem style={{ textAlign: "left" }}>
          <Link to="/MavesorySubscription"  style={{ display: "flex", alignItems: "left", textDecoration: "none",color:'#505d69' }}>
            <i className="ri-file-text-line align-middle me-2 text-success" />
            <Typography style={{ fontSize: "14px", textAlign: "left" }}>
                {props.t("Subscrition")}
              </Typography>
            </Link>
          </DropdownItem>
          <DropdownItem style={{ textAlign: "left" }}>
            <i className="ri-file-list-line align-middle me-2 text-warning" />
            User Agreement
          </DropdownItem>
          {/* <DropdownItem style={{ textAlign: "left" }}>
            <i className="ri-question-line align-middle me-2 text-primary" />
            Submit a Help
          </DropdownItem> */}
          <DropdownItem style={{ textAlign: "left"  }}>
            {/* <Link to="/Tickets" className="dropdown-item" style={{ textDecoration: "none" }}>
              <i className="ri-ticket-line align-middle me-2 text-secondary"  />
              <Typography style={{ fontSize: "14px", textAlign: "left" }}>
                {/* {props.t("Submit a Help Ticket")} 
              </Typography>
            </Link>Tickets */}
            <Link to="/Tickets" style={{ display: "flex", alignItems: "left", textDecoration: "none",color:'#505d69' }} >
            <Typography style={{ textAlign: "left", fontSize: '14px' }}>
            <i className="ri-ticket-line align-middle me-2 text-secondary"  />
              {props.t("Submit a Help Ticket")}</Typography>
          </Link>
          </DropdownItem>

          <div className="dropdown-divider" />
          <Typography style={{ color: 'black', textAlign: "center", fontWeight: 'bold', fontSize: '12px' }}>{UserType}</Typography>

          <Link to="/logout" className="dropdown-item">
            <Typography style={{ textAlign: "center", fontSize: '14px' }}>
              <i className="ri-shut-down-line align-middle me-2 text-danger" />
              {props.t("Logout")}</Typography>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
