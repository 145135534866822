import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Card,
    CardContent,
    Avatar,
    Grid,
    Button,
    Box,
    TextField,
    CircularProgress,
    IconButton,
    Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Swal from 'sweetalert2';  // Make sure to import SweetAlert2
import Rating from '@mui/material/Rating';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const MarketingProfile = () => {
    const [profile, setProfile] = useState({});
    const [urlFile, setUrlFile] = useState("");
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(true); // Loading state for the profile API
    const [error, setError] = useState(null);

    const ProfilePictureBase64 = localStorage.getItem("ProfilePictureBase64");
    const userid = localStorage.getItem("userid");
    const token = localStorage.getItem("Token");
    const auth = `Bearer ${token}`;
    const headers = { Authorization: auth };

    useEffect(() => {
        const fetchProfilePicture = async () => {
            if (ProfilePictureBase64) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/UserDocument/userDocumentById?Id=${ProfilePictureBase64}`,
                        { headers }
                    );
                    setUrlFile(response.fileUrl);
                } catch (error) {
                    console.error("Error fetching profile picture URL:", error);
                    setError("Failed to load profile picture.");
                }
            }
        };

        fetchProfilePicture();
    }, [ProfilePictureBase64]);
    useEffect(() => {
        const fetchImage = async () => {
            if (urlFile) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/UserDocument/ViewPatientDocument?path=${urlFile}`,
                        { headers }
                    );
                    setImage(response);
                } catch (error) {
                    console.error("Error fetching image:", error);
                    setError("Failed to load image.");
                }
            }
        };

        fetchImage();
    }, [urlFile]);
    const [refresh, setrefresh] = useState(false);
    const [rating, setRating] = React.useState(profile.rating || 0);
    console.log(rating, 'This is Ratinf')

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/Users/GetUserMarketingProfile?uId=${userid}`,
                    { headers }
                );
                setProfile(response);
            } catch (error) {
                console.error("Error fetching profile:", error);
                setError("Failed to load profile data.");
            } finally {
                setLoading(false); // Set loading to false after API call completes
            }
        };

        fetchProfile();
    }, [refresh]);
    const [chargeforSupervisor, setchargeforSupervisor] = useState("");
    const [supervisonSpotsAvailible, setsupervisonSpotsAvailible] = useState("");
    const [bio, setBio] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const [modalities, setModalities] = useState({
        CBT: false,
        DBT: false,
        SolutionFocused: false,
        Humanistic: false,
        REBT: false,
        PersonCentered: false,
        Adlerian: false,
        Gestalt: false,
        CouplesMarriage: false,
        RegisteredPlayTherapist: false,
        EMDR: false,
        AppliedBehaviorAnalysis: false,
    });
    const formatLabel = (label) => {
        if (typeof label !== "string") {
            return ""; // Return an empty string or a default value
        }
        return label.replace(/([a-z])([A-Z])/g, "$1 $2");
    };
    const [populationServed, setPopulationServed] = useState({
        Preschool: false,
        Children: false,
        Adolescents: false,
        YoungAdults: false,
        Adults: false,
        Aging: false,
    });
    const populationLabels = {
        Preschool: "Preschool (2-4 yrs old)",
        Children: "Children (5-12 yrs old)",
        Adolescents: "Adolescents (13-17 yrs old)",
        YoungAdults: "Young Adults (18-34 yrs old)",
        Adults: "Adults (35-64 yrs old)",
        Aging: "Aging (65+ yrs old)",
    };
    const [supervisionStyle, setSupervisionStyle] = useState({
        dropDown1: "",
        dropDown2: "",
        dropDown3: "",
        dropDown4: "",
        dropDown5: "",
        dropDown6: "",
        dropDown7: "",
        dropDown8: "",
        dropDown9: "",
    });

    const handleSupervisionStyleChange = (event) => {
        setSupervisionStyle({
            ...supervisionStyle,
            [event.target.name]: event.target.value,
        });
    };

    const dropDownOptions = {
        dropDown1: [
            "I create a supportive and nonjudgmental space where my supervisees feel comfortable discussing challenges and concerns.",
            "I am approachable and available when my supervisees need guidance or have questions.",
            "I demonstrate respect for their perspectives and individual growth needs, fostering an open and trusting supervisory relationship."
        ],
        dropDown2: [
            "I provide clear, actionable feedback on my supervisees' clinical work and progress.",
            "My feedback is balanced, acknowledging both strengths and areas for improvement in their clinical practice.",
            "I actively listen during case discussions, allowing my supervisees to express their thoughts and concerns while providing reflective guidance.",
            "Supervision sessions are timely, well-structured, and organized, with clear expectations set for each meeting."
        ],
        dropDown3: [
            "I offer specific clinical advice that helps my supervisees develop their therapeutic skills and clinical judgment.",
            "During case discussions, I help my supervisees strengthen their decision-making and problem-solving abilities.",
            "I support my supervisees in building confidence, particularly in handling complex or challenging cases.",
            "I provide thorough, thoughtful answers to questions about treatment approaches, ethical dilemmas, and clinical processes."
        ],
        dropDown4: [
            "I help my supervisees identify their strengths and areas for growth as clinicians.",
            "I tailor supervision to meet their developmental needs as provisionally licensed clinicians, helping them move toward independent practice.",
            "I create a clear plan for them to achieve their goals, including gaining clinical hours, improving competency in specific therapeutic modalities, and meeting licensure requirements.",
            "I guide them through the licensing process and ensure that they are meeting all necessary supervision requirements for licensure."
        ],
        dropDown5: [
            "I offer educational resources or suggest continuing education opportunities to enhance their learning.",
            "I create opportunities for supervisees to ask questions and explore new clinical techniques or interventions.",
            "I encourage self-reflection and critical thinking to deepen their understanding of therapy and the therapeutic process.",
            "I use role-playing, case simulations, or other skill-building activities to support their clinical practice development."
        ],
        dropDown6: [
            "I provide clear guidance on ethical and legal standards in clinical practice.",
            "I proactively discuss ethical dilemmas, professional boundaries, and other critical ethical issues relevant to my supervisees' work.",
            "I help them navigate complex ethical situations, ensuring that client welfare and professional standards are prioritized in their practice."
        ],
        dropDown7: [
            "Supervision sessions are consistent and scheduled regularly, as agreed upon at the start of the supervisory relationship.",
            "Each supervision meeting follows a clear structure, including time for check-ins, case reviews, feedback, and goal-setting.",
            "There is adequate time during each session for discussing cases and providing feedback, and I ensure that supervisees have an opportunity to bring up any concerns.",
            "I provide timely feedback, ideally in real-time or shortly after client sessions, so it remains relevant and actionable."
        ],
        dropDown8: [
            "I offer emotional support when supervisees are dealing with difficult or emotionally charged cases.",
            "I model healthy professional boundaries and encourage supervisees to practice self-care and maintain a healthy work-life balance.",
            "I am sensitive to the risk of burnout and proactively discuss strategies for managing stress and maintaining well-being."
        ],
        dropDown9: [
            "I demonstrate cultural competence and encourage my supervisees to integrate cultural considerations into their clinical work.",
            "I create an open space for discussing diversity and cultural issues as they relate to therapy and client care.",
            "I am aware of and sensitive to my supervisees' unique backgrounds and perspectives and create an inclusive environment that supports their growth as culturally competent clinicians."
        ]
    };

    const handleModalityChange = (event) => {
        setModalities({
            ...modalities,
            [event.target.name]: event.target.checked,
        });
    };

    const handlePopulationServedChange = (event) => {
        setPopulationServed({
            ...populationServed,
            [event.target.name]: event.target.checked,
        });
    };
    const [base64Image, setBase64Image] = useState("");

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = async () => {
        try {
            const selectedModalities = Object.keys(modalities)
                .filter(key => modalities[key])
                .join(' ');
            const selectedPopulations = Object.keys(populationServed)
                .filter(key => populationServed[key])
                .join(' ');

            const payload = {
                briefBio: bio || profile.briefBio || '',
                id: profile.id,
                userId: parseInt(userid, 10),
                modalities: selectedModalities || profile.modalities || '',
                populationServed: selectedPopulations || profile.populationServed || '',
                dropDown1: supervisionStyle.dropDown1 || profile.dropDown1 || '',
                dropDown2: supervisionStyle.dropDown2 || profile.dropDown2 || '',
                dropDown3: supervisionStyle.dropDown3 || profile.dropDown3 || '',
                dropDown4: supervisionStyle.dropDown4 || profile.dropDown4 || '',
                dropDown5: supervisionStyle.dropDown5 || profile.dropDown5 || '',
                dropDown6: supervisionStyle.dropDown6 || profile.dropDown6 || '',
                dropDown7: supervisionStyle.dropDown7 || profile.dropDown7 || '',
                dropDown8: supervisionStyle.dropDown8 || profile.dropDown8 || '',
                dropDown9: supervisionStyle.dropDown8 || profile.dropDown9 || '',
                supervisonSpotsAvailible: supervisonSpotsAvailible || profile.supervisonSpotsAvailible || '',
                chargeforSupervisor: chargeforSupervisor || profile.chargeforSupervisor || '',
                rating: rating || profile.rating || '',
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/Users/AddEditUserMaketingProfile`,
                payload,
                { headers }
            );

            if (base64Image) {
                await axios.post(`${process.env.REACT_APP_API_URL}/Users/UpdateProfilePic`,
                    JSON.stringify(base64Image), // Ensure it's a string
                    {
                        headers: {
                            ...headers,
                            "Content-Type": "application/json",
                        }
                    }
                );
            }


            Swal.fire({
                title: 'Profile Saved',
                text: 'Your profile has been saved successfully!',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            }).then(() => {

                setBio('');
                setSupervisionStyle({
                    dropDown1: "",
                    dropDown2: "",
                    dropDown3: "",
                    dropDown4: "",
                    dropDown5: "",
                    dropDown6: "",
                    dropDown7: "",
                    dropDown8: "",
                    dropDown9: "",
                });
                setModalities({
                    CBT: false,
                    DBT: false,
                    SolutionFocused: false,
                    Humanistic: false,
                    REBT: false,
                    PersonCentered: false,
                    Adlerian: false,
                    Gestalt: false,
                    CouplesMarriage: false,
                    RegisteredPlayTherapist: false,
                    EMDR: false,
                    AppliedBehaviorAnalysis: false,
                });
                setPopulationServed({
                    Preschool: false,
                    Children: false,
                    Adolescents: false,
                    YoungAdults: false,
                    Adults: false,
                    Aging: false,
                });
                setIsEditing(false);
                setrefresh(!refresh);
            });

        } catch (error) {
            console.error('Error saving profile:', error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while saving your profile. Please try again.',
                icon: 'error',
                confirmButtonText: 'Okay',
            });
        }
    };
    return (
        <div className="page-content">
            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <CircularProgress />
                </Box>
            )}
            {!loading && (
                <>
                    <Container sx={{ backgroundColor: "#98b2a1", p: 2, borderRadius: 2, mb: 3 }}>
                        <Typography align="center" variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
                            Marketing Profile
                        </Typography>
                    </Container>

                    <Container sx={{ mb: 4 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3.5}>
                                <Card
                                    sx={{
                                        backgroundColor: "#1B4D3E",
                                        color: "white",
                                        borderRadius: 2,
                                        // height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        p: 2,
                                    }}
                                >
                                    <Box sx={{ position: "relative", width: 120, height: 120, mx: "auto", mb: 2 }}>
                                        <Avatar
                                            src={image}
                                            alt="Profile"
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                border: "2px solid #fff",
                                                transition: "opacity 0.3s ease-in-out",
                                                opacity: isEditing ? 0.6 : 1
                                            }}
                                        />
                                        {isEditing && (
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    borderRadius: "50%",
                                                    cursor: "pointer",
                                                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" }
                                                }}
                                                onClick={() => document.getElementById("imageUpload").click()}
                                            >
                                                <CloudUploadIcon sx={{ color: "#C2A27C", fontSize: 40 }} />
                                            </Box>
                                        )}
                                        <input
                                            id="imageUpload"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            onChange={handleImageUpload}
                                        />
                                    </Box>

                                    <Typography
                                        variant="h6"
                                        sx={{ color: "#C2A27C", fontWeight: "bold", textAlign: "center", mb: 1 }}
                                    >
                                        {profile.fullName}
                                    </Typography>
                                    <Typography variant="body2" style={{
                                        maxWidth: '100%',
                                        marginLeft: '20px',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        textAlign: 'center'
                                    }}>
                                        {profile.licenseType} <b>| {profile.licenseState}</b>
                                    </Typography>
                                    <IconButton sx={{ color: "#C2A27C" }} onClick={() => setIsEditing(!isEditing)}>
                                        <EditIcon />
                                    </IconButton>
                                    {isEditing ? (
                                        <TextField
                                            placeholder="Supervison Spots Availible"
                                            value={supervisonSpotsAvailible}
                                            onChange={(e) => setsupervisonSpotsAvailible(e.target.value)}
                                            InputProps={{
                                                style: { height: '40px', color: '#fff', textAlign: 'justify', marginBottom: '9px' }, // Justify text
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'white',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#C2A27C',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#C2A27C',
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: '#fff',
                                                    fontSize: '0.85rem',
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: '#fff', // Label color to white
                                                },
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="body2" style={{
                                            maxWidth: '100%',
                                            textAlign: 'center'
                                        }}>
                                            <b>{profile.supervisonSpotsAvailible}</b>
                                        </Typography>
                                    )}
                                    {isEditing ? (
                                        <TextField
                                            placeholder="Charge for Supervisor?"
                                            value={chargeforSupervisor}
                                            onChange={(e) => setchargeforSupervisor(e.target.value)}
                                            InputProps={{
                                                style: { height: '40px', color: '#fff', textAlign: 'justify', marginBottom: '9px' },
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'white',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#C2A27C',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#C2A27C',
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: '#fff',
                                                    fontSize: '0.85rem',
                                                },

                                            }}
                                        />
                                    ) : (
                                        <Typography variant="body2" style={{
                                            maxWidth: '100%',
                                            textAlign: 'center'
                                        }}>
                                            {profile.chargeforSupervisor}$
                                        </Typography>
                                    )}
                                    {isEditing && (
                                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                            <Button
                                                variant="contained"
                                                onClick={handleSave}
                                                sx={{
                                                    backgroundColor: "#C2A27C",
                                                    color: "black",
                                                    width: "64%",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                SAVE CHANGES
                                            </Button>
                                        </Box>
                                    )}

                                </Card>
                            </Grid>
                            <Grid item xs={12} md={8.5}>
                                <Card sx={{ backgroundColor: "#1B4D3E", color: "white", borderRadius: 2, height: "100%" }}>
                                    <CardContent>
                                        <Typography variant="h6" fontWeight="bold" sx={{ color: "#C2A27C", }}>
                                            BRIEF BIO
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: "#fff" }}>
                                            {isEditing ? (
                                                <TextField
                                                    fullWidth
                                                    label="Please provide a brief bio (150 words)"
                                                    value={bio}
                                                    onChange={(e) => setBio(e.target.value)}
                                                    size="small"
                                                    InputProps={{
                                                        style: { height: '40px', color: '#fff' }, // Change text color to white
                                                    }}
                                                    InputLabelProps={{
                                                        style: { color: '#fff' }, // Change label color to white
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'white',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#C2A27C',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#C2A27C',
                                                            },
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            color: '#fff',
                                                        },
                                                        '& .MuiFormLabel-root': {
                                                            color: '#fff', // Label color to white
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <Typography variant="body2" sx={{ color: "#fff" }}>
                                                    {profile.briefBio}
                                                </Typography>
                                            )}
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Typography variant="h6" fontWeight="bold" sx={{ color: "#C2A27C", mb: 0.5 }}>
                                            SUPERVISION STYLE
                                        </Typography>
                                        {isEditing && (
                                            <Typography sx={{ color: "white", fontSize: 14 }}>
                                               (Select the Best Option in the Drop Down Menu)
                                            </Typography>
                                        )}
                                        </Box>

                                        <Box component="ul" sx={{ pl: 2, }}>
                                            {!isEditing ? (
                                                profile &&
                                                Object.keys(profile)
                                                    .filter((key) => key.startsWith("dropDown") && profile[key])
                                                    .map((key, i) => (
                                                        <Box component="li" key={i} sx={{ color: "#fff", fontSize: "0.775rem" }}>
                                                            {profile[key]}
                                                        </Box>
                                                    ))
                                            ) : (
                                                [...Array(9)].map((_, i) => {
                                                    const fieldName = `dropDown${i + 1}`;
                                                    return (
                                                        <FormControl fullWidth margin="normal" key={fieldName} sx={{ minHeight: 40 }}>
                                                            {/* <InputLabel htmlFor={fieldName} sx={{ color: "white" }}>
                                                                {`Drop Down Menu ${i + 1}`}
                                                            </InputLabel> */}
                                                            <Select
                                                                id={fieldName} // Ensure the Select has an id for association with InputLabel
                                                                name={fieldName}
                                                                value={supervisionStyle[fieldName] || ""}
                                                                onChange={handleSupervisionStyleChange}
                                                                displayEmpty
                                                                renderValue={(selected) => (selected ? selected : `Drop Down Menu ${i + 1}`)}
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    height: 40,
                                                                    color: "black", // Text color inside dropdown
                                                                    "& .MuiSvgIcon-root": { color: "white" }, // Dropdown arrow color
                                                                    "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" }, // Border color
                                                                    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#C2A27C" }, // Hover effect
                                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#C2A27C" }, // Focus effect
                                                                }}
                                                            >
                                                                {/* <MenuItem value="" disabled>
                                                                    <em style={{ color: "black" }}>{fieldName}</em>
                                                                </MenuItem> */}
                                                                {dropDownOptions[fieldName].map((option, idx) => (
                                                                    <MenuItem key={idx} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    );
                                                })

                                            )}


                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Typography variant="h6" fontWeight="bold" sx={{ color: "#C2A27C", }}>
                                            MODALITIES
                                        </Typography>
                                        {isEditing && (
                                            <Typography sx={{ color: "white", fontSize: 14 }}>
                                               (Select Up to 3)
                                            </Typography>
                                        )}
                                        </Box>
                                        <Typography variant="body2" sx={{ color: "#fff", }}>

                                            {isEditing ? (
                                                Object.keys(modalities).map((key) => (
                                                    <FormControlLabel
                                                        key={key}
                                                        control={
                                                            <Checkbox
                                                                checked={modalities[key]}
                                                                onChange={handleModalityChange}
                                                                name={key}
                                                                size="small"
                                                                sx={{
                                                                    color: "white", // Checkbox color
                                                                    "&.Mui-checked": { color: "#C2A27C" }, // Checked color
                                                                }}
                                                            />
                                                        }
                                                        // label={key}
                                                        label={
                                                            <Typography variant="body2" sx={{ color: "white" }}>{formatLabel(key)}</Typography>
                                                        }

                                                    />
                                                ))
                                            ) : (
                                                <Typography variant="body2" sx={{ color: "#fff" }}>
                                                    {formatLabel(profile?.modalities)}
                                                </Typography>
                                            )}
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Typography variant="h6" fontWeight="bold" sx={{ color: "#C2A27C" }}>
                                            POPULATION SERVED
                                        </Typography>
                                        {isEditing && (
                                            <Typography sx={{ color: "white", fontSize: 14 }}>
                                               (Select Up to 3)
                                            </Typography>
                                        )}
                                        </Box>
                                        <Typography variant="body2" sx={{ color: "#fff", }}>
                                            {isEditing ? (
                                                Object.keys(populationServed).map((key) => (
                                                    <FormControlLabel
                                                        key={key}
                                                        control={
                                                            <Checkbox
                                                                checked={populationServed[key]}
                                                                onChange={handlePopulationServedChange}
                                                                name={key}
                                                                size="small"
                                                                sx={{
                                                                    color: "white", // Checkbox color
                                                                    "&.Mui-checked": { color: "#C2A27C" }, // Checked color
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Typography variant="body2" sx={{ color: "white" }}>{populationLabels[key]}</Typography>
                                                        }
                                                    />
                                                ))
                                            ) : (
                                                <Typography variant="body2" sx={{ color: "#fff" }}>
                                                    {formatLabel(profile?.populationServed)}
                                                </Typography>
                                            )}
                                        </Typography>

                                        <Typography variant="h6" fontWeight="bold" sx={{ color: "#C2A27C" }}>
                                            OVERALL RATING
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                            {isEditing ? (
                                                <Rating
                                                    name="overall-rating"
                                                    value={rating} // Controlled value
                                                    onChange={(event, newValue) => setRating(newValue)} // Update state on change
                                                    precision={0.5} // Allows half-star ratings
                                                    size="large"
                                                    sx={{
                                                        color: "gold",
                                                        "& .MuiRating-iconFilled": { color: "#FFD700" }, // Custom color for filled stars
                                                        "& .MuiRating-iconHover": { color: "#FFA500" }, // Color on hover
                                                    }}
                                                />
                                            ) : (
                                                <Rating
                                                    name="overall-rating"
                                                    value={profile.rating}
                                                    precision={0.5} // Allows half-star ratings
                                                    size="large"
                                                    readOnly
                                                    sx={{
                                                        color: "gold",
                                                        "& .MuiRating-iconFilled": { color: "#FFD700" }, // Custom color for filled stars
                                                        "& .MuiRating-iconHover": { color: "#FFA500" }, // Color on hover
                                                    }}
                                                />
                                            )}
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#C2A27C",
                                                    color: "black",
                                                    ":hover": { backgroundColor: "#b0926e" },
                                                    width: "40%",
                                                    py: 1.5,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                PAST REVIEWS
                                            </Button>
                                            <Typography
                                                fontWeight="bold"
                                                sx={{ color: "#fff", display: 'flex', alignItems: 'center' }}
                                            >
                                                SEND SUPERVISOR REVIEW FORM
                                                <SendRoundedIcon sx={{ transform: 'rotate(-43deg)', ml: 1, mt: -1, color: "#C2A27C", fontSize: '29px' }} />
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )
            }
        </div >
    );
};

export default MarketingProfile;