import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Modal,
  TextField,
  Box,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  FormHelperText,
  InputLabel
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/Inbox";
import ReplyIcon from "@mui/icons-material/Reply";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import AddIcon from '@mui/icons-material/Add'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import SendIcon from '@mui/icons-material/Send'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';  // Ensure you have SweetAlert imported

const Message = () => {
  document.title = "Message | Maevisory";
  const validationSchema = Yup.object({
    messagesTos: Yup.string()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .nullable() // Allow null values
      .required('Please Select User Email.'),
    subject: Yup.string().required('Please Enter Subject.'),
  })

  const fields = [
    'id',
    'practiceId',
    'parentId',
    'sendBy',
    'sendDate',
    'inbox',
    'sentMessage',
    'isDraft',
    'subject',
    'bodyMessage',
    'isRead',
    'readDate',
    'isActive',
    'createdBy',
    'createdDate',
    'updatedBy',
    'updatedDate',
    'clientCreatedDate',
    'clientUpdatedDate',
    'messagesTos',
  ]
  const defValues = {
    id: 0,
    practiceId: '',
    parentId: '',
    sendBy: '',
    sendDate: '',
    inbox: '',
    sentMessage: '',
    isDraft: 'false',
    subject: '',
    bodyMessage: '',
    isRead: 'false',
    readDate: '',
    isActive: 'false',
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
    clientCreatedDate: '',
    clientUpdatedDate: '',
    messagesTos: null
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const [tabValue, setTabValue] = useState("1");
  const [inboxData, setInboxData] = useState([]);
  const [sentMessagesData, setSentMessagesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [submitting, setSubmitting] = useState(true)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const token = localStorage.getItem("Token");
  const auth = `Bearer ${token}`;
  const headers = {
    Authorization: auth,
  };

  const fetchInboxData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/Messages/getInbox`,
        { headers }
      );
      setInboxData(response);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSentMessagesData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/Messages/getsentMessages`,
        { headers }
      );
      setSentMessagesData(response);
    } catch (error) {
      console.error("Error fetching sent messages data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tabValue === "2") {
      setLoading(true);
      fetchSentMessagesData();
    } else {
      setLoading(true);
      fetchInboxData();
    }
  }, [tabValue]);
  const maill = localStorage.getItem('Emailaddress')
  const [userList, setUserList] = useState([]);
  const UserType = localStorage.getItem('User_Type')
  const Superviser_ID = localStorage.getItem('SupervisorID')
  const Userid = localStorage.getItem('userid')
  const [messagesToss, setMessagesToss] = React.useState([])
  const handleRowClick = (message) => {
    setSelectedMessage(message);
    setReplyData(message)
    setDetailsModalOpen(true);
  };
  const [openNewMail, setOpenNewMail] = React.useState(false)
  const [email, setEmail] = React.useState('')

  useEffect(() => {
    if (UserType == 'Therapist') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Users/getUserById?id=${Superviser_ID}`, {
          headers
        })
        .then(response => {
          const user = response;
          const prac = [{
            label: `${user.userName}<${user.email}>`,
            value: user.userName
          }];
          setUserList(prac);
        })
        .catch(error => { });
    } else if (UserType == 'Admin') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?showAll=${true}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: `${field.userName}<${field.email}>`,
              value: field.userName
            });
          });
          setUserList(prac);
        })
        .catch(error => { });
    } else if (UserType == 'Supervisor') {
      axios
        // .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/GetUsersBySupervisorId?supervisorId=${Userid}&userType=${'Therapist'}`, {
        .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/GetThrepistListBySupervisorId?supervisorId=${Userid}`, {
          headers
        })
        .then(response => {
          // var prac = [];
          // response.forEach(field => {
          //   prac.push({
          //     label: `${field.userName}<${field.email}>`,
          //     value: field.userName
          //   });
          // });
          // setUserList(prac);
          var prac = [];
          response.forEach((field) => {
            prac.push({
              label: `${field.userName}<${field.email}>`,
              value: field.userName,
            });
          });
          setUserList(prac);

        })
        .catch(error => { });
    }
  }, []);

  const handleCloseMail = () => {
    setValue('subject', '');
    setValue('bodyMessage', '');
    setValue('messagesTos', '');
    setEmail('');
    setOpenNewMail(false);
    setReplyFormVisible(false);
  };


  const onSubmit = (data) => {
    setSubmitting(true);
    const postData = { ...data }; // Spread data to ensure we don't mutate the original
    if (messagesToss.length > 0) {
      postData.messagesTos = messagesToss;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/Messages/addMessage`, postData, {
        headers,
      })
      .then((response) => {
        setSubmitting(false);
        // Handle success actions here
        // setRefresh(!refresh);
        handleCloseMail()
        Swal.fire({
          icon: 'success',
          title: 'Email Sent Successfully.',
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        setSubmitting(false);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Error: ${error.message}`,
          showConfirmButton: true,
        });
      });
  };
  const [replyFormVisible, setReplyFormVisible] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [replyData, setReplyData] = useState(null);

  const handleReplyButtonClick = () => {
    setReplyFormVisible(true);
  };

  const handleCloseReply = () => {
    setReplyFormVisible(false);
    setReplyMessage("");
  };
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);

  const handleReplySend = () => {
    const postData = {
      messagesTos: [{ email: replyData.sentBy }],
      bodyMessage: replyMessage,
      sentBy: replyData.sentBy,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Messages/addMessage`, postData, {
        headers,
      })
      .then((response) => {
        setSuccessMessageVisible(true);

        setTimeout(() => {
          setSuccessMessageVisible(false);
          setReplyFormVisible(false);
          setReplyMessage("");
          setDetailsModalOpen(false); // Close the modal
        }, 2000);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  return (
    <div className="page-content">
      <Container>
        {/* <Typography variant="h4" gutterBottom>
          Message Management
        </Typography> */}
        <Button
          className="Button"
          onClick={() => setOpenNewMail(true)}
          variant="contained"
          color="primary"
          style={{
            marginBottom: '10px',
            marginLeft: 'auto',
            height: '35px',
            color: 'white',
            fontSize: '14px',
          }}
        >
          <AddIcon fontSize="medium" />
          New Message
        </Button>

        <Modal
          open={openNewMail}
          onClose={handleCloseMail}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form
            onSubmit={handleSubmit(onSubmit)} >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 800,
                bgcolor: "background.paper",
                p: 2,
                borderRadius: 2,
                boxShadow: 24,
                maxHeight: "85vh", // Fixed height relative to viewport
                overflowY: "auto", // Scrollable content
              }}
            >
              <Headroom>
                <Grid container style={{ backgroundColor: '#356F60', position: 'sticky', borderRadius: '8px' }}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem',
                        padding: '0 10px',
                      }}
                    >
                      <DialogTitle>
                        <h1 style={{ fontWeight: 'bold', color: '#fff', textDecoration: 'underline', fontSize: '1.2rem' }}>
                          New Message
                        </h1>
                      </DialogTitle>
                      <IconButton onClick={handleCloseMail}>
                        <CancelOutlinedIcon fontSize="large" color="error" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>

              <Grid container spacing={1} alignItems="center" style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                  <TextField
                    label="From"
                    type="text"
                    size="small"
                    value={maill}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ marginBottom: '10px' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="messagesTos"
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        placeholder="Select User Email"
                        options={userList}
                        value={userList.find((option) => option.value === getValues('messagesTos'))}
                        onChange={(selectedOption) => {
                          setValue('messagesTos', selectedOption.value);
                          setMessagesToss([
                            {
                              email: selectedOption.value,
                              name: selectedOption.label,
                            },
                          ]);
                          setEmail(selectedOption.value);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '40px', // Ensure consistent height
                            zIndex: 1, // Keep the control above overlapping elements
                          }),
                          menu: (base) => ({
                            ...base,
                            backgroundColor: '#fff', // Solid white background for the dropdown
                            border: '1px solid #ccc', // Optional border for the dropdown
                            zIndex: 9999, // Ensure dropdown is above other elements
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                          }),
                          menuList: (base) => ({
                            ...base,
                            maxHeight: '200px', // Limit height to prevent overflow
                            overflowY: 'auto', // Enable scrolling if options exceed height
                            padding: '0', // Remove default padding
                            backgroundColor: '#ffffff', // Solid white background
                            borderRadius: '4px', // Rounded corners
                            '&::-webkit-scrollbar': {
                              width: '6px', // Scrollbar width
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#d1d1d1', // Scrollbar color
                              borderRadius: '10px', // Rounded scrollbar
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                              backgroundColor: '#a1a1a1', // Darker on hover
                            },
                          }),
                          option: (base, state) => ({
                            ...base,
                            backgroundColor: state.isFocused ? '#e6f7ff' : '#ffffff', // Light blue hover
                            color: state.isFocused ? '#000' : '#333', // Text color for hover and default
                            padding: '10px',
                            cursor: 'pointer',
                            ':active': {
                              backgroundColor: '#bae7ff', // Stronger blue for active option
                            },
                          }),
                        }}
                        
                        
                        
                      />
                    )}
                  />
                  <FormHelperText error>{errors.messagesTos?.message}</FormHelperText>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="To"
                    type="text"
                    value={email}
                    placeholder="Please Select Email"
                    fullWidth
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ marginBottom: '10px' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Subject"
                    type="text"
                    {...register('subject')}
                    fullWidth
                    size="small"
                    margin="dense"
                    error={Boolean(errors.subject)}
                    style={{ marginBottom: '10px' }}
                  />
                  <FormHelperText error>{errors.subject?.message}</FormHelperText>
                </Grid>

                <Grid item xs={12}>
                  <TextareaAutosize
                    aria-label="body message"
                    minRows={5}
                    placeholder="Enter message"
                    style={{
                      width: '100%',
                      padding: '10px',
                      borderRadius: '4px',
                      fontSize: '14px',
                      borderColor: errors.bodyMessage ? '#f44336' : '#ccc',
                    }}
                    {...register('bodyMessage')}
                  />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="Button"
                    onClick={handleSubmit(onSubmit)}
                    style={{
                      marginTop: '10px',
                      float: 'right',
                      height: '35px',
                      color: 'white',
                      fontSize: '14px',
                      width: '100%',
                      borderRadius: '4px',
                    }}
                  // disabled={submitting}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Modal>

        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 2 }}>
            <TabList onChange={handleTabChange}>
              <Tab
                icon={<InboxIcon />}
                iconPosition="start"
                label="Inbox"
                value="1"
              />
              <Tab
                icon={<MailIcon />}
                iconPosition="start"
                label="Messages"
                value="2"
              />
            </TabList>
          </Box>

          <TabPanel value="2">
            {loading ? (
              <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2} sx={{
                maxHeight: "60vh",
                overflowY: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f5f5f5",
                },
              }} >
                {sentMessagesData.length > 0 ? (
                  sentMessagesData.map((item) => (
                    <Grid item xs={12} key={item.id} sx={{
                      maxHeight: "300px", // Fixed height for the grid
                      overflowY: "auto", // Enable vertical scrolling
                      scrollbarWidth: "thin", // For Firefox: thin scrollbar
                      "&::-webkit-scrollbar": {
                        width: "6px", // Thin scrollbar width for Webkit browsers
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f5f5f5", // Scrollbar track color
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "brown", // Scrollbar thumb color
                        borderRadius: "8px", // Rounded scrollbar thumb
                      },
                    }}>
                      <Box
                        sx={{
                          border: "1px solid #ddd",
                          padding: 2,
                          borderRadius: 1,
                          boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                          backgroundColor: "#f9f9f9",
                          transition: "all 0.3s",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={() => handleRowClick(item)}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#356F60")
                            (e.currentTarget.style.color = "#fff")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f9f9f9")
                            (e.currentTarget.style.color = "black")

                        }
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            fontSize: "0.9rem",
                            // color: "#666",
                          }}
                        >
                          <Typography >
                            Sent: {new Date(item.sendDate).toLocaleString()}
                          </Typography>
                        </Box>
                        <Typography fontSize={22} fontWeight='bold' >{item.sentBy}</Typography>
                        <Typography style={{
                          maxWidth: '100%',
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-wrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          textAlign: 'justify'
                        }}>{item.bodyMessage}</Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography>No Messages Found</Typography>
                )}
              </Grid>
            )}
          </TabPanel>

          <TabPanel value="1">
            {loading ? (
              <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2} sx={{
                maxHeight: "60vh",
                overflowY: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f5f5f5",
                },
              }} >
                {inboxData.length > 0 ? (
                  inboxData.map((item) => (
                    <Grid item xs={12} key={item.id} >
                      <Box
                        sx={{
                          border: "1px solid #ddd",
                          padding: 2,
                          borderRadius: 1,
                          boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                          backgroundColor: "#f9f9f9",
                          transition: "all 0.3s",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={() => handleRowClick(item)}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#356F60")
                            (e.currentTarget.style.color = "#fff")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f9f9f9")
                            (e.currentTarget.style.color = "black")

                        }
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            fontSize: "0.9rem",
                            // color: "#666",
                          }}
                        >
                          <Typography variant="caption">
                            Read: {new Date(item.readDate).toLocaleString()}
                          </Typography>
                        </Box>
                        <Typography fontSize={22} fontWeight='bold'>{item.sentBy}</Typography>
                        <Typography style={{
                          maxWidth: '100%',
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-wrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          textAlign: 'justify'
                        }}>{item.bodyMessage}</Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography>No Messages Found</Typography>
                )}
              </Grid>
            )}
          </TabPanel>
        </TabContext>

        <Modal open={detailsModalOpen} onClose={() => {
          setDetailsModalOpen(false);
          setReplyFormVisible(false);
        }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              boxShadow: 24,
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                borderBottom: "1px solid #ddd",
                pb: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#3f51b5" }}>
                {selectedMessage?.sentBy}
              </Typography>
              <Button
                onClick={() => {
                  setDetailsModalOpen(false);
                  setReplyFormVisible(false);
                }}
                sx={{
                  minWidth: "auto",
                  padding: 0,
                  color: "#f44336",
                  "&:hover": { backgroundColor: "rgba(244, 67, 54, 0.1)" },
                }}
              >
                <CancelOutlinedIcon />
              </Button>
            </Box>

            {/* Display key details */}
            <Box mb={2}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Subject: {selectedMessage?.subject}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                To: {selectedMessage?.emailTo || "Not specified"}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Sent Date: {new Date(selectedMessage?.sendDate).toLocaleString() || "No date available"}
              </Typography>
              <Typography variant="body1" style={{
                fontSize: "14px",
                maxWidth: "100%",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                textAlign: "justify",
                fontWeight: "bold"
              }}>
                Message: {selectedMessage?.bodyMessage || "No message content available."}
              </Typography>
            </Box>

            {successMessageVisible  && (
        <Box
          sx={{
            backgroundColor: "#e8f5e9",
            color: "#388e3c",
            border: "1px solid #388e3c",
            padding: "10px",
            borderRadius: "4px",
            mb: 2,
          }}
        >
         Reply Sent Successfully!
        </Box>
      )}
            {tabValue === "1" && (
              <>
                {replyFormVisible && (
                  <Grid container spacing={1} style={{ padding: '5px' }}>
                    <Grid item xs={6}>
                      <InputLabel style={{ fontSize: '14px' }}>From</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        value={maill || ''}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>To</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        value={replyData ? replyData.sentBy : ''}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>Subject</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        value={replyData ? replyData.subject : ''}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>Message</InputLabel>
                      <TextareaAutosize
                        aria-label="Minimum height"
                        minRows={5}
                        style={{ width: '100%', padding: '5px' }}
                        value={replyMessage}
                        onChange={(e) => setReplyMessage(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={2.4}>
                      <Button
                        className="Button"
                        onClick={handleReplySend}
                        style={{
                          marginBottom: '10px',
                          margin: '10px',
                          width: '120px',
                          height: '35px',
                          color: 'white',
                          fontSize: '14px',
                        }}
                      >
                        Send
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        className="Button"
                        onClick={handleCloseReply}
                        style={{
                          marginBottom: '10px',
                          margin: '10px',
                          width: '120px',
                          height: '35px',
                          color: 'white',
                          fontSize: '14px',
                        }}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {tabValue === "1" && (
              <>
                {!replyFormVisible && (
                  <Button
                    variant="contained"
                    className="Button"
                    startIcon={<ReplyIcon />}
                    onClick={handleReplyButtonClick}
                    sx={{
                      mt: 2,
                      backgroundColor: "#3f51b5",
                      "&:hover": {
                        backgroundColor: "#303f9f",
                      },
                    }}
                  >
                    Reply
                  </Button>

                )}

              </>
            )}
          </Box>
        </Modal>
      </Container>
    </div>
  );
};

export default Message;
