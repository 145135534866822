import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Container,
  IconButton,
  LinearProgress,
  InputAdornment,
  Grid,
  CardContent,
  Card,
  InputLabel,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Modal
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import CircularProgress from '@mui/material/CircularProgress'
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate, useLocation } from "react-router-dom";
import { VisibilityOff, Visibility, Height } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { ArrowBack, Subscriptions } from "@mui/icons-material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import md5 from "md5";
import axios from "axios";
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';

export default function SupervisorSignupDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const pakid = location.state.pakid;
  const supervisorId = location.state?.supervisorId || null;
  // const emailofUser = location.state.emailofUser;

  const styles = {
    container: {
      minHeight: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
    },
    backgroundImageContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("/mavesory.png")',
      backgroundSize: '45%',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      zIndex: -3,
    },
    innerContainer: {
      backgroundColor: '#fff',
      borderRadius: '20px',
      padding: '20px',
      maxWidth: '540px',
      width: '100%',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      borderTop: '3px solid rgba(0, 0, 0, 0.1)',
      animation: 'fadeIn 0.5s ease-in-out',
    },
    formTitle: {
      color: '#2C3E2D',
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    textField: {
      background: 'white',
      borderRadius: '4px',
      marginBottom: '15px',
    },
    nextButton: {
      background: '#7A9483',
      color: 'white',
      padding: '10px 30px',
      borderRadius: '20px',
      marginTop: '20px',
      '&:hover': {
        background: '#658570',
      },
    },
    card: {
      marginBottom: '10px',
      cursor: 'pointer',
      backgroundColor: '#a1c2a2',
      borderRadius: '10px',
      alignItems: 'center',
      transition: 'all 0.3s ease',
    },
    selectedCard: {
      border: '5px solid #b3946d',
    },
    showAgreementButton: {
      backgroundColor: "#8C7A63",
      color: "white",
      border: "3px solid #fff",
      padding: "6px",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
      transition: "transform 0.2s, background-color 0.2s",
    },
    modalTitle: {
      textAlign: "center",
      backgroundColor: "#8C7A63",
      color: "white",
      padding: "10px",
      fontWeight: "bold",
    },
    modalContent: {
      padding: "20px",
      textAlign: "left",
      maxHeight: "500px",
      overflowY: "auto",
    },
    heading: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    content: {
      fontSize: "16px",
      lineHeight: "1.5",
    },
    signatureSection: {
      marginTop: "30px",
      fontWeight: "bold",
    },
  };
  const animations = `
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;
  const fields = [
    "email",
    "profilePictureURL",
    "uploadTrainingCertificateURL",
    "uploadCopyofInsuranceURL",
    "userAggreementCheck",
    "createdDate",
    "sigantures",
    "signature",
    "gender",
    "preferredGender",
    "ethnicity",
    "race",
    "licenseType",
    "licenseState",
    "licenseNumber",
    "initialLicensureDate",
    "licenseExpirationDate",
    "uploadStateLicenseURL",
    "uploadStateLicense",
    "licenseApprovalDate",
    "dateIDIssued",
    "dateIDExpiration",
    "supervisortrainingtitle",
    "dateofCompletedTraining",
    "liabilityInsuranceCarrier",
    "directhourshave",
    "directhoursrequired",
    "indirecthoursrequired",
    "indirecthourshave",
    "profilePictureBase64",
    "uploadTrainingCertificateBase64",
    "uploadCopyofInsuranceBase64",
    "uploadStateLicenseBase64",
    "insurancePolicyStartDate",
    "insurancePolicyEndDate",
  ];
  const defValues = {
    email: "",
    profilePictureURL: "",
    uploadTrainingCertificateURL: "",
    uploadCopyofInsuranceURL: "",
    userAggreementCheck: false,
    createdDate: "",
    sigantures: "",
    signature: "",
    gender: "",
    preferredGender: "",
    ethnicity: "",
    race: "",
    licenseType: "",
    licenseState: "",
    licenseNumber: "",
    initialLicensureDate: "",
    licenseExpirationDate: "",
    uploadStateLicenseURL: "",
    uploadStateLicense: "",
    licenseApprovalDate: "",
    dateIDIssued: "",
    dateIDExpiration: "",
    supervisortrainingtitle: "",
    dateofCompletedTraining: "",
    liabilityInsuranceCarrier: "",
    directhourshave: 0,
    directhoursrequired: 0,
    indirecthoursrequired: 0,
    indirecthourshave: 0,
    profilePictureBase64: "",
    uploadTrainingCertificateBase64: "",
    uploadCopyofInsuranceBase64: "",
    uploadStateLicenseBase64: "",
    insurancePolicyStartDate: '',
    insurancePolicyEndDate: ''
  };
  const SelectedRole = localStorage.getItem("selectedRole")

  const validationSchema = Yup.object({
    liabilityInsuranceCarrier: Yup.string()
      .required("Liability insurance carrier is required"),
    policyNumber: Yup.string()
      .required("Policy number is required"),
    insurancePolicyStartDate: Yup.date()
      .required('Insurance policy start date is required')
      .typeError('Invalid date format'),
    insurancePolicyEndDate: Yup.date()
      .required('Insurance policy end date is required')
      .typeError('Invalid date format')
      .min(
        Yup.ref('insurancePolicyStartDate'),
        'End date must be later than start date'
      ),
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [showForm, setShowForm] = useState(0);


  const [stateLicenseBase64, setStateLicenseBase64] = useState("");
  const [stateLicenseURL, setStateLicenseURL] = useState("");

  const handleFileChangeState = (event) => {
    const file = event.target.files[0];
    if (file) {
      setStateLicenseURL(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setStateLicenseBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [AgreementError, setAgreementError] = useState(false);
  const [LicenseNumberError, setLicenseNumberError] = useState(false);
  const [Agreement, setAgreement] = useState(null);

  const [base64ImageAgreement, setBase64ImageAgreement] = useState("");
  const [ImageAgreementUrl, setImageAgreementUrl] = useState("");
  const [CopyInsurance, setCopyInsurance] = useState("");
  const [ProfileError, setProfileError] = useState(false);

  const [CopyInsuranceeURL, setCopyInsuranceeURL] = useState("");
  const handleFileChangeStateInsurance = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCopyInsuranceeURL(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCopyInsurance(reader.result);
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };
  const [genderError, setGenderError] = useState(false);
  const [preferredGenderError, setPreferredGenderError] = useState(false);
  const [TrainingCertificate, setTrainingCertificate] = useState("");
  const [TrainingCertificateURL, setTrainingCertificateURL] = useState("");

  const handleFileChangeTrainingCertificate = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTrainingCertificateURL(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setTrainingCertificate(reader.result);
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };

  const handleImageUploadAgreement = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageAgreementUrl(file.name)
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64ImageAgreement(base64String); // Save Base64 data
        setAgreement(true); // Update agreement state
      };
      reader.readAsDataURL(file); // Reads the file as Base64
    }
    setAgreementError(false)
  };

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const preferredGenderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Non-Binary", label: "Non-Binary" },
    { value: "Intersex", label: "Intersex" },
    { value: "Trans Woman", label: "Trans Woman" },
    { value: "Trans Man", label: "Trans Man" },
    { value: "Prefer Not to Say", label: "Prefer Not to Say" },
  ];

  const ethnicityOptions = [
    { value: "Hispanic or Latino", label: "Hispanic or Latino" },
    { value: "Not Hispanic or Latino", label: "Not Hispanic or Latino" },
  ];
  const [selectedState, setSelectedState] = useState(null);
  const [LicenseState, setLicenseState] = useState(null);


  const states = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];
  const [selectedImage, setSelectedImage] = useState(null);

  const [base64Image, setBase64Image] = useState("");
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setProfileError(false)
  };
  const [gender, setGender] = useState(null);
  const [preferredGender, setPreferredGender] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);
  const [OtherField, setOtherField] = useState(false);
  const [loading, setLoading] = useState(false);
  const HandleCloseModal = () => {
    navigate('/login');
  };
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const [userAggreementCheck, setuserAggreementCheck] = useState(false)
  const handleCheckuserAggreementCheck = (event) => {
    setuserAggreementCheck(event.target.checked); // Updates the state based on the checkbox value
  };
  const [agreementCheckError, setagreementCheckError] = useState(false);
  const [race, setRace] = useState([]);
  const [licenseType, setLicenseType] = useState([]);
  const handleRaceChange = (value) => {
    setRace((prevState) => {
      const updatedState = prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value];
      return updatedState;
    });
  };
  const [isPendingLicense, setIsPendingLicense] = useState(false);
  useEffect(() => {
    if (isPendingLicense) {
      setValue('licenseNumber', '');
      setValue('initialLicensureDate', '');
      setValue('licenseExpirationDate', '');
      setLicenseNumberError(false)
    }
  }, [isPendingLicense, setValue]);
  const handleLicenseTypeChange = (value) => {
    setLicenseType((prevState) => {
      const updatedState = prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value];
      if (value === "pendingLicens") {
        setIsPendingLicense(!licenseType.includes(value));
      }
      return updatedState;
    });
  };
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleSelectUser = (userId) => {
    setSelectedUserId(userId);
  };
  const onSubmit = (data) => {
    const raceStr = race.join(',');
    const licenseStr = licenseType.join(',');
    let postData = {
      ...data,
      // type: 'Supervisor',
      supervisorId:supervisorId,
      directhoursrequired: parseInt(data.directhoursrequired, 10) || 0, // Default to 0 if invalid
      directhourshave: parseInt(data.directhourshave, 10) || 0,
      indirecthoursrequired: parseInt(data.indirecthoursrequired, 10) || 0,
      indirecthourshave: parseInt(data.indirecthourshave, 10) || 0,
      race: raceStr,
      licenseType: licenseStr,
      state: selectedState,
      email: emailofUser,
      profilePictureURL: selectedImage,
      profilePictureBase64: base64Image,
      gender: gender,
      preferredGender: preferredGender,
      ethnicity: ethnicity,
      licenseState: LicenseState,
      uploadStateLicenseBase64: stateLicenseBase64,
      uploadStateLicenseURL: stateLicenseURL,
      uploadStateLicense: stateLicenseBase64,
      uploadStateLicenseURL: stateLicenseURL,
      pdf: base64ImageAgreement,
      uploadTrainingCertificateURL: TrainingCertificateURL,
      uploadTrainingCertificateBase64: TrainingCertificate,
      uploadCopyofInsuranceBase64: CopyInsurance,
      uploadCopyofInsuranceURL: CopyInsuranceeURL,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Users/addUserDetail`, postData, { headers })
      .then((response) => {
        Swal.fire({
          title: 'Success!',
          text: 'Record Submitted Successfully to Maevisory!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        }).then(() => {
          HandleCloseModal();
        });
      })
      .catch((error) => {
        console.error('Error submitting data:', error);
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
          showConfirmButton: true,
        });
      });
  };
  const [formErrors, setFormErrors] = useState({
    initialLicensureDate: '',
    licenseExpirationDate: '',
  });

  const validateDates = () => {
    const startDate = getValues('initialLicensureDate');
    const endDate = getValues('licenseExpirationDate');
    const newErrors = { ...formErrors };

    if (!startDate) {
      newErrors.initialLicensureDate = 'Initial Licensure Date is required';
    } else {
      newErrors.initialLicensureDate = '';
    }

    if (!endDate) {
      newErrors.licenseExpirationDate = 'License Expiration Date is required';
    } else if (new Date(endDate) < new Date(startDate)) {
      newErrors.licenseExpirationDate =
        'License Expiration Date cannot be earlier than Initial Licensure Date';
    } else {
      newErrors.licenseExpirationDate = '';
    }

    setFormErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };
  const handleNextClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (showForm == 1) {
      if (!selectedImage) {
        setLoading(false);
        setProfileError(true)
        return;
      }
    } else if (showForm == 2) {
      if (!gender) {
        setGenderError(true)
        setLoading(false);
        return;
      }
      if (!preferredGender) {
        setPreferredGenderError(true)
        setLoading(false);
        return;
      }
    } else if (showForm == 3) {
      const LicenseNumber = getValues('licenseNumber');
      if (!isPendingLicense) {
        if (!LicenseNumber) {
          setLicenseNumberError(true); // Show error if no LicenseNumber is provided
          setLoading(false);
          return;
        }
        if (!validateDates()) {
          return;
        }
      }
      // If isPendingLicense is true, no error should be set for LicenseNumber
      setLicenseNumberError(false);
    } else if (showForm == 4) {
      const isValid = await trigger("supervisortrainingtitle");
      if (!isValid) {
        setLoading(false);
        return;
      }
    }
    else if (showForm == 5) {
      const isValid = await trigger("liabilityInsuranceCarrier") && await trigger("policyNumber") && await trigger("insurancePolicyStartDate") && await trigger("insurancePolicyEndDate");
      if (!isValid) {
        setLoading(false);
        return;
      }
    }
    else if (showForm == 6) {
      // if (!base64ImageAgreement) {
      //   setAgreementError(true)
      //   setLoading(false);
      //   return;
      // }
      const isCheckboxChecked = getValues('userAggreementCheck');
      if (!isCheckboxChecked) {
        setagreementCheckError(true);
        setLoading(false);
        return;
      }
      setAgreementError(false);

    }
    if (showForm < 6) {
      // setShowForm(showForm + 1);
      setTimeout(() => {
        setShowForm(showForm + 1);
        setLoading(false);
      }, 500)
    } else if (showForm == 6) {
      onSubmit(getValues());
    }
  };
  const handleBackClick = () => {

    setShowForm(showForm - 1);
    // navigate('/SubscriptionConfirmationPage');


  };
  const [invoiceUrl, setinvoiceUrl] = useState('')
  const emailofUser = localStorage.getItem("SignUpuserEmail")
  const [Loadingpak, setLoadingoak] = useState(false)
  useEffect(() => {
    setLoadingoak(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/Users/UserSubscription?packId=${pakid}&email=${emailofUser}`, {
        headers
      }
      )
      .then((response) => {
        setinvoiceUrl(response.invoiceUrl)
        setLoadingoak(false)
      })

      .catch((error) => {
        setLoadingoak(false)
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [emailofUser, pakid])

  const handleDelete = () => {
    if (emailofUser) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/Users/delete-incompelete?email=${emailofUser}`)
        .then(response => {
          navigate("/login");
        })
        .catch(error => {
          navigate("/login");
        });
    }
  };
  const [showAgreement, setShowAgreement] = useState(false);

  const handleShowAgreement = () => {
    setShowAgreement(true);
  };
  const handleAgreementToggle = () => {
    setShowAgreement(!showAgreement);
  };
  const handleCloseAgreement = () => {
    setShowAgreement(false);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [supervisors, setSupervisors] = useState([]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/supervisorMarketingList`)
      .then((response) => {
        setSupervisors(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching supervisors", error);
        setLoading(false);
      });
  }, []);
 
  return (
    <React.Fragment>
      <style>{animations}</style>

      <Container maxWidth={false} style={styles.container}>
        <div style={styles.backgroundImageContainer}></div>
        {loading && <LinearProgress style={{ width: '100%', position: 'absolute', top: 0, color: 'green' }} />}
        {showForm === 0 && (
          <>
            <Box style={styles.innerContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Adjust width as needed
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    marginTop: '-5px',
                    float: 'right',
                    alignItems: 'right',
                    margin: '3px',
                    justifyContent: "right",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Typography
                mb={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Thank You For Purchasing
              </Typography>
              <Grid item xs={12}
                style={{ textAlign: 'center' }}

              >

                {Loadingpak ? (
                  <div >
                    <CircularProgress />
                  </div>) : (
                  <>
                    {invoiceUrl && (
                      <a
                        href={invoiceUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "inline-block",
                          padding: "10px 15px",
                          textAlign: 'center',
                          background: "#007bff",
                          color: "#fff",
                          textDecoration: "none",
                          borderRadius: "4px",
                        }}
                      >
                        Download invoice
                      </a>
                    )}
                  </>
                )}
              </Grid>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 1 && (
          <>
            <Box style={styles.innerContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Adjust width as needed
                }}
              >
                <IconButton
                  disabled={showForm == 0}
                  onClick={handleBackClick}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    marginTop: '-5px',
                    float: 'right',
                    alignItems: 'right',
                    margin: '3px',
                    justifyContent: "right",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    mb={2}
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    UPLOAD PROFILE PICTURE
                  </Typography>
                  <div
                    onClick={() => document.getElementById('fileInput').click()}
                    style={{
                      width: "250px",
                      height: "250px",
                      margin: "20px auto",
                      backgroundColor: "#fff",
                      border: '2px dashed grey',
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",

                    }}
                  >
                    {selectedImage ? (
                      <img
                        src={base64Image}
                        alt="Uploaded Preview"
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      />
                    ) : (
                      <label style={{ cursor: "pointer" }}>
                        Please Select Picture

                      </label>
                    )}
                  </div>
                  <div
                    style={{
                      margin: "20px auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="fileInput"
                      onChange={handleImageUpload}
                      style={{ display: "none" }}
                    />
                    <IconButton onClick={() => document.getElementById('fileInput').click()}>
                      <FileDownloadOutlinedIcon

                        fontSize="large" color="black" cursor="pointer" fontWeight='bold'>
                      </FileDownloadOutlinedIcon>
                      Upload
                    </IconButton>
                  </div>
                  {ProfileError && (
                    <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px', textAlign: 'center' }}>
                      Please Select Picture
                    </Typography>
                  )}

                </Grid>




              </Grid>
              {/* <Typography
                mb={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                UPLOAD PROFILE PICTURE
              </Typography> */}
              {/* <div
                style={{
                  width: "250px",
                  height: "250px",
                  margin: "20px auto",
                  backgroundColor: "#fff",
                  border: '2px dashed grey',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                {selectedImage ? (
                  <img
                    src={base64Image}
                    alt="Uploaded Preview"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : (
                  <label style={{ cursor: "pointer" }}>
                    Please Select Picture

                  </label>
                )}
              </div>
              <div
                style={{
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />
                <IconButton>
                  <FileDownloadOutlinedIcon
                    onClick={() => document.getElementById('fileInput').click()}
                    fontSize="large" color="black" cursor="pointer" fontWeight='bold' />
                </IconButton>
              </div> */}
              {/* {ProfileError && (
                <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px', textAlign: 'center' }}>
                  Please Select Picture
                </Typography>
              )} */}



              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 2 && (
          <>
            <Box style={styles.innerContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Adjust width as needed
                }}
              >
                <IconButton
                  disabled={showForm == 0}
                  onClick={handleBackClick}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    marginTop: '-5px',
                    float: 'right',
                    alignItems: 'right',
                    margin: '3px',
                    justifyContent: "right",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Grid container spacing={1} style={{
                height: '444px', overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#aec3b0 transparent',
                '&::-webkit-scrollbar': {
                  width: 8,
                  height: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#aec3b0',
                  borderRadius: 10,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },

                '&::-webkit-scrollbar-thumb:horizontal': {
                  width: 4,
                },
              }}>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                    Gender
                  </Typography>
                  <Select
                    options={genderOptions}
                    value={genderOptions.find(option => option.label === gender)}
                    onChange={(option) => {
                      setGender(option.label);
                    }}
                    placeholder="Gender"


                  />
                  {genderError && (
                    <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                      Please Select Gender
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                    Preferred Gender
                  </Typography>
                  <Select
                    options={preferredGenderOptions}
                    value={preferredGenderOptions.find(option => option.label === preferredGender)}
                    onChange={(option) => {
                      setPreferredGender(option.label);
                    }}
                    placeholder="Preferred Gender"


                  />
                  {preferredGenderError && (
                    <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                      Please Select Preferred Gender
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                    Ethnicity
                  </Typography>
                  <Select
                    options={ethnicityOptions}
                    value={ethnicityOptions.find(option => option.label === ethnicity)}
                    onChange={(option) => {
                      setEthnicity(option.label);
                    }}
                    placeholder="Ethnicity"


                  />
                </Grid>

                <Box
                  sx={{
                    backgroundColor: "#aec3b0",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: "10px",
                    maxWidth: "600px",
                  }}
                >
                  <Typography style={{ color: "black", fontWeight: "bold", textAlign: 'left', marginBottom: '3px', textTransform: 'uppercase' }}>
                    Race
                  </Typography>

                  <Grid container spacing={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                    {[
                      { label: 'Black or African American', value: 'black' },
                      { label: 'White', value: 'white' },
                      { label: 'American Indian or Alaska Native', value: 'americanIndian' },
                      { label: 'Native Hawaiian or Other Pacific Islander', value: 'nativeHawaiian' },
                      { label: 'Asian', value: 'asian' },
                      { label: 'Middle Eastern or North African (MENA)', value: 'middleEastern' },
                      { label: 'Multiracial', value: 'multiracial' },
                      { label: 'Other', value: 'other' },
                    ].map(({ label, value }) => (
                      <Grid item xs={12} key={value}>
                        <input
                          type="checkbox"
                          // checked={race.includes(value)}
                          id={value}
                          value={race.includes(value)}
                          onChange={() => handleRaceChange(value)}
                        />
                        <label htmlFor={value} style={{ marginLeft: '3px', color: 'black' }}>
                          {label}
                        </label>
                      </Grid>
                    ))}

                    {race.includes('other') && ( // Show text input if 'Other' is selected
                      <Grid item xs={12}>
                        <Controller
                          name="others"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              placeholder="Specify other..."
                              InputProps={{
                                style: { backgroundColor: '#8C7A63', color: '#fff', height: '40px' },
                              }}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>


                </Box>
              </Grid>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 3 && (
          <>
            <Box style={styles.innerContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Adjust width as needed
                }}
              >
                <IconButton
                  disabled={showForm == 0}
                  onClick={handleBackClick}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    marginTop: '-5px',
                    float: 'right',
                    alignItems: 'right',
                    margin: '3px',
                    justifyContent: "right",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Grid container spacing={1} style={{
                height: '444px', overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#aec3b0 transparent',
                '&::-webkit-scrollbar': {
                  width: 8,
                  height: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#aec3b0',
                  borderRadius: 10,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },

                '&::-webkit-scrollbar-thumb:horizontal': {
                  width: 4,
                },
              }}>
                <Box
                  sx={{
                    backgroundColor: "#aec3b0",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: "10px",
                    maxWidth: "600px",
                  }}
                >
                  <Typography
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "3px",
                    }}
                  >
                    LICENSED TYPE
                  </Typography>
                  <Grid container spacing={1} style={{ textAlign: "left" }}>
                    {(SelectedRole == "Supervisor"
                      ? [
                        { label: "LPC-Supervisor", value: "lpcSupervisor" },
                        { label: "LCSW-Supervisor", value: "lcswSupervisor" },
                        { label: "LMFT-Supervisor", value: "lmftSupervisor" },
                        { label: "BCBA or BCBA-D", value: "bcba" },
                        { label: "Licensed Specialist in School Psychology", value: "licensedSpecialist" },
                        { label: "Psychologist", value: "psychologist" },
                      ]
                      : [
                        { label: "LPC Associate", value: "lpcAssociate" },
                        { label: "LMSW or MSW", value: "lmswormsw" },
                        { label: "LCDC-I", value: "lcdci" },
                        { label: "BCBA Trainee", value: "bcbaTrainee" },
                        { label: "Psychological Associate", value: "psychologicalAssociate" },
                        { label: "Pending License (Looking for Supervisor w/Maevisory)", value: "pendingLicens" },
                      ]
                    ).map(({ label, value }) => (
                      <Grid item xs={12} key={value}>
                        <input
                          type="checkbox"
                          id={value}
                          // checked={licenseType.includes(value)}
                          value={licenseType.includes(value)}
                          onChange={() => handleLicenseTypeChange(value)}
                        />
                        <label htmlFor={value} style={{ marginLeft: '3px', color: 'black' }}>
                          {label}
                        </label>
                      </Grid>
                    ))}
                  </Grid>

                </Box>

                <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                  <Grid item xs={6}>
                    <InputLabel>License State</InputLabel>
                    <Select
                      options={states}
                      value={states.find(option => option.label === LicenseState)}
                      onChange={(option) => {
                        setLicenseState(option.label);
                      }}
                      placeholder="License State"

                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>License Number</InputLabel>
                    <Controller
                      name="licenseNumber"
                      {...register("licenseNumber")}
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          disabled={isPendingLicense}
                          placeholder="License Number"
                          InputProps={{
                            readOnly: isPendingLicense,
                            style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                          }}
                          {...field}
                          fullWidth
                          onChange={(e) => {
                            setValue('licenseNumber', e.target.value);
                            setLicenseNumberError(false)
                          }}
                        />}
                    />
                    {LicenseNumberError && (
                      <p style={{ color: 'red', marginTop: '5px' }}>Please Enter License Number</p>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Initial Licensure Date</InputLabel>
                    <Controller
                      name="initialLicensureDate"
                      {...register("initialLicensureDate")}
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          placeholder="Initial Licensure Date"
                          type="date"
                          disabled={isPendingLicense}
                          InputProps={{
                            readOnly: isPendingLicense,
                            style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                          }}
                          {...field}
                          fullWidth
                          onChange={(e) => {
                            const dateValue = e.target.value;
                            if (!dateValue || /^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
                              setValue("initialLicensureDate", dateValue, {
                                shouldValidate: true,
                              });
                              setFormErrors({ ...formErrors, initialLicensureDate: '' });
                            } else {
                              setValue("initialLicensureDate", ""); // Clear invalid input
                            }
                          }}
                          error={!!formErrors.initialLicensureDate}
                          helperText={formErrors.initialLicensureDate}
                        />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>License Expiration Date</InputLabel>
                    <Controller
                      name="licenseExpirationDate"
                      {...register("licenseExpirationDate")}
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          placeholder="License Expiration Date"
                          type="date"
                          disabled={isPendingLicense}
                          InputProps={{
                            readOnly: isPendingLicense,
                            style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                          }}
                          {...field}
                          fullWidth
                          onChange={(e) => {
                            const dateValue = e.target.value;
                            if (!dateValue || /^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
                              setValue("licenseExpirationDate", dateValue, {
                                shouldValidate: true,
                              });
                              setFormErrors({ ...formErrors, licenseExpirationDate: '' });
                            } else {
                              setValue("licenseExpirationDate", ""); // Clear invalid input

                            }
                          }}
                          error={!!formErrors.licenseExpirationDate}
                          helperText={formErrors.licenseExpirationDate}
                        />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel> {SelectedRole == "Supervisor" ? "Upload State License" : "Upload State License/Upload Form of ID"}</InputLabel>
                    <input
                      type="file"
                      accept="image/*,application/pdf"
                      id="Licence"
                      onChange={handleFileChangeState}
                      style={{ display: "none" }}
                    />
                    <TextField
                      variant="outlined"
                      // type="file"
                      placeholder="Upload State License"
                      value={stateLicenseURL}
                      InputProps={{
                        endAdornment: <FileDownloadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => document.getElementById("Licence").click()} />,
                        style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                      }}
                      fullWidth
                    />

                  </Grid>
                </Grid>
              </Grid>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 4 && (
          <>
            <Box style={styles.innerContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Adjust width as needed
                }}
              >
                <IconButton
                  disabled={showForm == 0}
                  onClick={handleBackClick}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    marginTop: '-5px',
                    float: 'right',
                    alignItems: 'right',
                    margin: '3px',
                    justifyContent: "right",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              {SelectedRole == 'Supervisor' ? (
                <>
                  <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                    <Grid item xs={12}>
                      <InputLabel>Name of Supervisor Training</InputLabel>
                      <Controller
                        name="supervisortrainingtitle"
                        {...register("supervisortrainingtitle")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Name of Supervisor Training"
                            InputProps={{
                              style: { backgroundColor: "#aec3b0", color: "black" },
                            }}
                            {...field}
                            fullWidth
                            onChange={(e) => {
                              setValue('supervisortrainingtitle', e.target.value);
                            }}
                          />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Date of Completed Training</InputLabel>
                      <Controller
                        name="dateofCompletedTraining"
                        {...register("dateofCompletedTraining")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            type="date"
                            InputProps={{
                              style: { backgroundColor: "#aec3b0", color: "black" },
                            }}
                            {...field}
                            fullWidth
                            // onChange={(e) => {
                            //   setValue('dateofCompletedTraining', e.target.value);
                            // }}
                            onChange={(e) => {
                              const dateValue = e.target.value;

                              if (!dateValue || /^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
                                setValue("dateofCompletedTraining", dateValue, {
                                  shouldValidate: true,
                                });
                              } else {
                                setValue("dateofCompletedTraining", ""); // Clear invalid input
                              }
                            }}
                          />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Upload Training Certificate</InputLabel>
                      <input
                        type="file"
                        accept="image/*,application/pdf"
                        id="INSURANCE"
                        onChange={handleFileChangeTrainingCertificate}
                        style={{ display: "none" }}
                      />
                      <TextField
                        variant="outlined"
                        // type="file"
                        placeholder="Upload Insurance Certificate"
                        value={TrainingCertificateURL}
                        InputProps={{
                          endAdornment: <FileDownloadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => document.getElementById("INSURANCE").click()} />,
                          style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                        }}
                        fullWidth
                      />

                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                    <Grid item xs={12}>
                      <InputLabel style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textTransform: "capitalize",
                        display: "block",
                      }}>How many direct hours are required for your license type?</InputLabel>
                      <Controller
                        name="directhoursrequired"
                        {...register("directhoursrequired")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            multiline
                            // label="DIRECT HOURS REQUIRED"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                              style: { fontWeight: "bold", color: "black", fontSize: '18px' }, // Making the label bold and dark
                            }}
                            placeholder="How many direct hours are required for your license type?"
                            rows={2}
                            InputProps={{
                              style: { backgroundColor: "#aec3b0", color: "black" },
                            }}
                            {...field}
                            fullWidth
                            onChange={(e) => {
                              setValue('directhoursrequired', e.target.value);
                            }}

                          />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textTransform: "capitalize",
                        display: "block",
                      }}>How many direct hours do you already have?</InputLabel>
                      <Controller
                        name="directhourshave"
                        {...register("directhourshave")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            multiline
                            // label="DIRECT HOURS ALREADY HAVE"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                fontWeight: "bold",
                                color: "#3f51b5", // Adding a primary color for the label
                                fontSize: "18px",
                                textTransform: "uppercase", // Making the label uppercase for emphasis
                                letterSpacing: "1.5px", // Adding spacing for better readability
                              },
                            }}
                            placeholder="How many direct hours do you already have?"
                            rows={2}
                            InputProps={{
                              style: { backgroundColor: "#aec3b0", color: "black" },
                            }}
                            {...field}
                            fullWidth
                            onChange={(e) => {
                              setValue('directhourshave', e.target.value);
                            }}

                          />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textTransform: "capitalize",
                        display: "block",
                      }}>How many indirect hours are required for your license type?</InputLabel>
                      <Controller
                        name="indirecthoursrequired"
                        {...register("indirecthoursrequired")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            multiline
                            // label="INDIRECT HOURS REQUIRED"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                              style: { fontWeight: "bold", color: "black", fontSize: '18px' },
                            }}
                            placeholder="How many indirect hours are required for your license type?"
                            rows={2}
                            InputProps={{
                              style: { backgroundColor: "#aec3b0", color: "black" },
                            }}
                            {...field}
                            fullWidth
                            onChange={(e) => {
                              setValue('indirecthoursrequired', e.target.value);
                            }}
                          />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textTransform: "capitalize",
                        display: "block",
                      }}>How many indirect hours do you already have?</InputLabel>
                      <Controller
                        name="indirecthourshave"
                        {...register("indirecthourshave")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            multiline
                            // label="INDIRECT HOURS ALREADY HAVE"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                              style: { fontWeight: "bold", color: "black", fontSize: '18px' },
                            }}
                            placeholder="How many indirect hours do you already have?"
                            rows={2}
                            InputProps={{
                              style: { backgroundColor: "#aec3b0", color: "black" },
                            }}
                            {...field}
                            fullWidth
                            onChange={(e) => {
                              setValue('indirecthourshave', e.target.value);
                            }}

                          />}
                      />
                    </Grid>
                    {/* <Grid item xs={12} textAlign="center">
                      <Button variant="contained" style={styles.nextButton} onClick={handleOpen}>Supervisors</Button>
                    </Grid> */}
                  </Grid>
                </>
              )}
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 5 && (
          <>
            <Box style={styles.innerContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Adjust width as needed
                }}
              >
                <IconButton
                  disabled={showForm == 0}
                  onClick={handleBackClick}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    marginTop: '-5px',
                    float: 'right',
                    alignItems: 'right',
                    margin: '3px',
                    justifyContent: "right",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                <Grid item xs={12}>
                  <InputLabel>Liability Insurance Carrier</InputLabel>

                  <Controller
                    name="liabilityInsuranceCarrier"
                    {...register("liabilityInsuranceCarrier")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Liability Insurance Carrier"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('liabilityInsuranceCarrier', e.target.value);
                        }}
                        error={!!errors.liabilityInsuranceCarrier}
                        helperText={errors.liabilityInsuranceCarrier?.message}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Policy Number</InputLabel>
                  <Controller
                    name="policyNumber"
                    {...register("policyNumber")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Policy Number"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('policyNumber', e.target.value);
                        }}
                        error={!!errors.policyNumber}
                        helperText={errors.policyNumber?.message}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Insurance Policy Start Date</InputLabel>
                  <Controller
                    name="insurancePolicyStartDate"
                    {...register("insurancePolicyStartDate")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        type="date"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          // setValue('insurancePolicyStartDate', e.target.value);
                          const dateValue = e.target.value;
                          if (!dateValue || /^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
                            setValue("insurancePolicyStartDate", dateValue, {
                              shouldValidate: true,
                            });
                          } else {
                            setValue("insurancePolicyStartDate", ""); // Clear invalid input
                          }
                        }}
                        error={!!errors.insurancePolicyStartDate}
                        helperText={errors.insurancePolicyStartDate?.message}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Insurance Policy End Date</InputLabel>
                  <Controller
                    name="insurancePolicyEndDate"
                    {...register("insurancePolicyEndDate")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        type="date"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          // setValue('insurancePolicyEndDate', e.target.value);
                          const dateValue = e.target.value;
                          if (!dateValue || /^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
                            setValue("insurancePolicyEndDate", dateValue, {
                              shouldValidate: true,
                            });
                          } else {
                            setValue("insurancePolicyEndDate", ""); // Clear invalid input
                          }
                        }}
                        error={!!errors.insurancePolicyEndDate}
                        helperText={errors.insurancePolicyEndDate?.message}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Upload Copy of Insurance</InputLabel>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    id="INSURANCE"
                    onChange={handleFileChangeStateInsurance}
                    style={{ display: "none" }}
                  />
                  <TextField
                    variant="outlined"
                    // type="file"
                    placeholder="Upload Insurance Certificate"
                    value={CopyInsuranceeURL}
                    InputProps={{
                      endAdornment: <FileDownloadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => document.getElementById("INSURANCE").click()} />,
                      style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                    }}
                    fullWidth
                  />

                </Grid>
              </Grid>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 6 && (
          <>
            <Box style={styles.innerContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Adjust width as needed
                }}
              >
                <IconButton
                  disabled={showForm == 0}
                  onClick={handleBackClick}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  style={{
                    backgroundColor: "#8C7A63",
                    color: "white",
                    border: "3px solid #fff",
                    padding: "6px",
                    borderRadius: "50%",
                    display: "flex",
                    marginTop: '-5px',
                    float: 'right',
                    alignItems: 'right',
                    margin: '3px',
                    justifyContent: "right",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s, background-color 0.2s",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Box
                style={{
                  backgroundColor: "#aec3b0",
                  borderRadius: "10px",
                  padding: "20px",
                  margin: "auto",
                  textAlign: "center",
                  height: '311px',
                  overflowY: 'auto',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#aec3b0 transparent',
                  '&::-webkit-scrollbar': {
                    width: 8,
                    height: 8,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#aec3b0',
                    borderRadius: 10,
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },

                  '&::-webkit-scrollbar-thumb:horizontal': {
                    width: 4,
                  },
                }}>

                <Typography
                  mb={5}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    textTransform: 'uppercase'
                  }}
                >
                  Complete User Agreement
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleAgreementToggle}
                      variant="contained"
                      style={{ backgroundColor: "#8C7A63", color: "white", marginBottom: "20px" }}
                    >
                      {showAgreement ? "Hide Agreement" : "Show Agreement"}
                    </Button>
                  </Grid>

                  <Dialog open={showAgreement} onClose={handleCloseAgreement} fullWidth>
                    <DialogTitle style={styles.modalTitle}>Maevisory User Agreement</DialogTitle>
                    <DialogContent style={styles.modalContent}>
                      <Typography variant="h6" style={styles.heading}>
                        Effective Date:
                      </Typography>
                      <Typography style={styles.content}>
                        Welcome to Maevisory! This User Agreement (“Agreement”) governs your use of the Maevisory application (“Software”), designed to enhance transparency and access to quality licensed supervisors for supervised and pre-supervised clinicians across the United States.
                        <br />
                        Our goal is to reduce barriers to obtaining full licensure by connecting supervisees with qualified supervisors.
                        <br />
                        <br />
                        <b>1. Introduction</b>
                        <br />
                        By accessing or using Maevisory, you agree to comply with and be bound by this Agreement. If you do not agree with these terms, please do not use the Software.
                        <br />
                        <br />
                        <b>2. Scope of Services</b>
                        <br />
                        Maevisory is a platform that facilitates connections between supervisees and licensed supervisors in the mental health field. Our services include:
                        <ul>
                          <li>Maintaining a centralized database of licensed supervisors with details on their work history, experience, and availability. </li>
                          <li>Enabling supervisors to manage licensee records, generate invoices, collect payments, and confirm supervision contracts. </li>
                          <li>Providing tools for tracking supervision hours and professional development, which help ensure compliance with licensing board requirements and internship/practicum placements. </li>
                          <li>Supporting licensed supervisors in remaining compliant with their respective licensing boards by offering features to document and report supervision activities. </li>
                          <li>Conducting virtual supervision sessions and collecting performance feedback.</li>
                          <li>Offering visibility through user reviews to improve the quality of supervision and enhance marketability for supervisors. </li>
                        </ul>
                        <br />
                        <b>3. User Obligations</b>
                        <br />
                        As a user, you agree to:
                        <ul>
                          <li>Provide accurate and complete information when creating your account.</li>
                          <li>Maintain the confidentiality of your login credentials and notify Maevisory immediately of any unauthorized use of your account.</li>
                          <li>Ensure that you do not share your PHI with unauthorized individuals.</li>
                        </ul>
                        <br />
                        <b>4. Data Protection and Security</b>
                        <br />
                        Maevisory implements industry-standard security measures to protect your PHI. However, you are responsible for ensuring the security of your account and for protecting your login credentials.
                        <br />
                        <br />
                        <b>5. Use of PHI</b>
                        <br />
                        Maevisory may use your PHI for the following purposes:
                        <ul>
                          <li>To provide and improve our services.</li>
                          <li>To communicate with you regarding your account or our services.</li>
                          <li>For research and analysis, in a manner that does not identify you personally.</li>
                        </ul>
                        <br />
                        <b>6. Compliance with Laws</b>
                        <br />
                        Maevisory is committed to complying with HIPAA and other applicable laws regarding the handling of PHI. By using the Software, you agree to comply with all relevant laws and regulations.
                        <br />
                        <br />
                        <b>7. User Rights</b>
                        <br />
                        You have the right to:
                        <ul>
                          <li>Access your PHI.</li>
                          <li>Request corrections to your PHI.</li>
                          <li>Request the deletion of your PHI, subject to applicable laws.</li>
                        </ul>
                        <br />
                        <b>8. Breach Notification</b>
                        <br />
                        <p>In the event of a data breach involving your PHI, Maevisory will notify you in accordance with applicable laws. You are encouraged to report any suspected breaches to us immediately. </p>
                        <br />
                        <b>9. Termination</b>
                        <br />
                        <p>You may terminate your account at any time. Maevisory reserves the right to terminate its services with notice given by the monthly anniversary of your subscription. Upon termination, we will take steps to securely delete your PHI in accordance with our data retention policies.</p>
                        <br />
                        <b>10.	Refund Policy</b>
                        <br />
                        <p>No refunds will be provided for any subscriptions or payments made.</p>
                        <br />
                        <b>11. Data Retention</b>
                        <br />
                        <p> Maevisory will retain your data for a period of seven (7) years, after which it will be securely deleted in accordance with our data retention policies. </p>
                        <br />
                        <b>12.	Liability and Indemnification</b>
                        <br />
                        <p>Maevisory’s liability is limited to the maximum extent permitted by law. You agree to indemnify and hold Maevisory harmless from any claims, damages, or expenses arising out of your use of the Software or your breach of this Agreement. Furthermore, you acknowledge that Maevisory shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the Software or related services. </p>
                        <br />
                        <b>13.	Governing Law</b>
                        <br />
                        <p>This Agreement shall be governed by the laws of the State of Texas, without regard to its conflict of law principles. 14. Amendments Maevisory reserves the right to amend this Agreement at any time. We will notify you of any changes via the Software or through other means. Your continued use of the Software after changes indicates your acceptance of the new terms. 15. Contact Information For questions or concerns regarding this Agreement or the Software, please contact us at: Email: useragreement@maevisory.com 16. Acceptance of Terms By using Maevisory, you acknowledge that you have read, understood, and agree to be bound by this User Agreement. </p>
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseAgreement} style={styles.modalTitle} variant="contained">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Grid item xs={12} >
                    <input
                      name='userAggreementCheck'
                      size='small'
                      type='checkbox'
                      {...register('userAggreementCheck')}
                      id='userAggreementCheck'
                    />
                    <label style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>
                      I Agree To The User Agreement
                    </label>

                    {agreementCheckError && (
                      <p style={{ color: 'red', marginTop: '5px' }}>Please confirm that you agree to the user agreement by selecting the checkbox.</p>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="signature"
                      {...register("signature")}
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          placeholder="Sign Here :"
                          multiline
                          rows={2}
                          InputProps={{
                            style: { backgroundColor: "#fff", color: "black" },
                          }}
                          {...field}
                          fullWidth
                          onChange={(e) => {
                            setValue('signature', e.target.value);
                          }}
                        />
                      }
                    /></Grid>
                </Grid>
              </Box>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
      </Container>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 900,
          bgcolor: "white",
          p: 4,
          boxShadow: 24,
          borderRadius: 2,
          maxHeight: "80vh",
          overflowY: "auto",
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#3A6A57',
            borderRadius: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '8px',
          }
        }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom sx={{ textAlign: "center", backgroundColor: "#8C7A63", color: "white", padding: "10px", borderRadius: "8px" }}>
            Supervisor List
          </Typography>
          <Box>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress sx={{ color: "#1B4D3E" }} />
              </Box>
            ) : (
              supervisors.map((supervisor) => (
                <Card key={supervisor.userId}
                  sx={{
                    backgroundColor: selectedUserId === supervisor.userId ? "#8C7A63" : "#1B4D3E",
                    color: "white",
                    mb: 3,
                    borderRadius: 3,
                    boxShadow: 3,
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    '&:hover': { backgroundColor: "#658570" },
                    border: selectedUserId === supervisor.userId ? "3px solid #b3946d" : "none"
                  }}
                  onClick={() => handleSelectUser(supervisor.userId)}>
                  <CardContent>
                    <Typography variant="h6" fontWeight="bold" color='#C2A27C'>
                      {supervisor.fullName} | {supervisor.licenseType || "LPC-S"} | {supervisor.licenseState} | {supervisor.chargeforSupervisor}
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold" color='#fff'>
                      Bio
                    </Typography>
                    <Typography variant="body2">{supervisor.briefBio}</Typography>
                    <Box mt={2}>
                      <Typography variant="subtitle1" fontWeight="bold" color='#fff'>SUPERVISION STYLE</Typography>
                      <ul>
                        <li>{supervisor.dropDown1}</li>
                        <li>{supervisor.dropDown2}</li>
                      </ul>
                    </Box>
                    <Box mt={2} display="flex" alignItems="center">
                      {[...Array(5)].map((_, i) => (
                        <StarIcon key={i} sx={{ color: "#C2A27C" }} />
                      ))}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                      <Button variant="contained" sx={{ mt: 2, backgroundColor: "#C2A27C", fontWeight: "bold" }}>
                        SEE ALL REVIEWS
                      </Button>
                      <GroupIcon sx={{ fontSize: 40, color: "#C2A27C" }} />
                    </Box>
                  </CardContent>
                </Card>
              ))
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right", alignItems: "center", mt: 2 }}>
          <Button  variant="contained" sx={{ backgroundColor: "#C2A27C", fontWeight: "bold" }} onClick={handleClose} >Close</Button>
          <Button  variant="contained" sx={{ ml: 2 ,backgroundColor: "#C2A27C", fontWeight: "bold" }} onClick={handleClose} >Submit</Button>
        </Box>
        </Box>
      </Modal>
    </React.Fragment >
  );
}
