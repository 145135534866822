// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Container from '@mui/material/Container'
import React from 'react'
import Button from '@mui/material/Button'
import { Controller, useForm } from 'react-hook-form';
import { Grid, Typography, TextField, InputLabel, Paper, CircularProgress, Box } from '@mui/material'
import ReactSelect from "react-select";
import InputMask from 'react-input-mask';
import Moment from 'moment'
import Swal from "sweetalert2";
import md5 from "md5";
const Profile = () => {
  document.title = "Profile | Maevisory";
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const [refresh, setRefresh] = useState(false)
  const fields = [
    "city",
    "contactNo",
    "userName",
    "createdBy",
    "createdDate",
    "dateJoined",
    "defaultFacilityId",
    "defaultPracticeId",
    "dob",
    "email",
    "fullName",
    "userName",
    "gender",
    "id",
    "provider_id",
    "isActive",
    "isStaff",
    "isSuperUser",
    "isVerified",
    "lastLogin",
    "password",
    "profilePhoto",
    "state",
    "street",
    "token",
    "updatedBy",
    "updatedDate",
    "userType",
    "zipCode",
    "provider",
    "address",
    "PracticeId",
    "photoUrl",
    "userslinked",
    "linkedUsersId",
    "resetPassword",
    "age",
    "ethnicity",
    "race",
    "licenseType",
    "licenseNumber",
    "licenseApprovalDate",
    "licenseExpirationDate",
    "dateIDIssued",
    "dateIDExpiration",
    "preferredGender",
    "insuranceCarrierName",
    "policyNumber",
    "insurancePolicyDate",
    "insuranceEndDate",
    "nameCard",
    "cardNumber",
    "expiration",
    "cvc",
    "billingAddress",
    "supervisorId",
    "emergencyContact",
    "licenseState",
    "insurancePolicyEndDate",
    "insurancePolicyStartDate",
    "liabilityInsuranceCarrier"
  ];
  const defValues = {
    city: "",
    userName: "",
    contactNo: "",
    createdBy: "",
    userName: "",
    createdDate: "",
    dateJoined: "",
    defaultFacilityId: "",
    defaultPracticeId: "",
    dob: "",
    userslinked: "",
    email: "",
    fullName: "",
    gender: "",
    id: 0,
    provider_id: "",
    isActive: "",
    isStaff: "",
    isSuperUser: "",
    isVerified: "",
    lastLogin: "",
    password: '',
    profilePhoto: "",
    state: "",
    street: "",
    token: "",
    updatedBy: "",
    updatedDate: "",
    userType: "",
    zipCode: "",
    provider: "",
    address: "",
    PracticeId: "",
    photoUrl: "",
    linkedUsersId: "",
    resetPassword: "",
    age: "",
    ethnicity: "",
    race: "",
    licenseType: "",
    licenseNumber: "",
    licenseApprovalDate: "",
    licenseExpirationDate: "",
    dateIDIssued: "",
    dateIDExpiration: "",
    preferredGender: "",
    insuranceCarrierName: "",
    policyNumber: "",
    insurancePolicyDate: "",
    insuranceEndDate: "",
    nameCard: "",
    cardNumber: "",
    expiration: "",
    cvc: "",
    billingAddress: "",
    supervisorId: "",
    emergencyContact: [],
    licenseState: '',
    insurancePolicyStartDate: '',
    insurancePolicyEndDate: '',
    liabilityInsuranceCarrier: ""

  };
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: defValues
  });
  const [SupervisorList, setSupervisorList] = useState([]);
  const [Dob, setDob] = React.useState('')
  const [licenseApprovalDate, setlicenseApprovalDate] = React.useState('')
  const [licenseExpirationDate, setlicenseExpirationDate] = React.useState('')
  const [dateidissued, setDateidissued] = React.useState('')
  const [dateIDExpiration, setDateIDExpiration] = React.useState('')
  const [insurancePolicyDate, setInsurancePolicyDate] = React.useState('')
  const [insuranceEndDate, setInsuranceEndDate] = React.useState('')
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const _userId = localStorage.getItem('userid')

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Supervisor'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setSupervisorList(prac);

      })
      .catch(error => { });

  }, []);
  useEffect(() => {
    if (_userId) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Users/getUserById?id=${_userId}`, {
          headers
        })
        .then(response => {
          fields.forEach(field => {
            if (response[field] !== undefined) {
              setValue(field, response[field]);
            }
            if (field === "password") {
              setValue("confirmPassword", response[field]);
            }
          });
          var dateYMD = Moment(response.dob).format('YYYY-MM-DD')
          setDob(dateYMD)
          var dateYMD = Moment(response.licenseApprovalDate).format('YYYY-MM-DD')
          setlicenseApprovalDate(dateYMD)
          var dateYMD = Moment(response.insurancePolicyStartDate).format('YYYY-MM-DD')
          setDateidissued(dateYMD)
          var dateYMD = Moment(response.insurancePolicyEndDate).format('YYYY-MM-DD')
          setDateIDExpiration(dateYMD)
          var dateYMD = Moment(response.insurancePolicyDate).format('YYYY-MM-DD')
          setInsurancePolicyDate(dateYMD)
          var dateYMD = Moment(response.insuranceEndDate).format('YYYY-MM-DD')
          setInsuranceEndDate(dateYMD)
          var dateYMD = Moment(response.licenseExpirationDate).format('YYYY-MM-DD')
          setlicenseExpirationDate(dateYMD)
          setLoading(false);
        })
        .catch(error => { });
    }
  }, [refresh]);
  const onSubmit = data => {
    let postData = { ...data };
    if (isPasswordChange) {
      postData.password = md5(postData.password);
      postData.confirmPassword = md5(postData.confirmPassword);
    } else {
      postData.password = postData.password;
      postData.confirmPassword = postData.confirmPassword;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Users/userRegistration`,
        postData,
        { headers }
      )
      .then(response => {
        setRefresh(!refresh)
        Swal.fire({
          title: "Success!",
          text: response.message || "User registered successfully.",
          icon: "success",
          confirmButtonText: "OK",
          timer: 2000,
          timerProgressBar: true,
        });

      })
      .catch(error => {
        Swal.fire({
          title: "Error!",
          text:
            error.response?.message ||
            "Something went wrong. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
          timer: 2000,
          timerProgressBar: true,
        });
      });
  };
  const Emailaddress = localStorage.getItem('Emailaddress')
  const UserType = localStorage.getItem('User_Type')
  const [loginLinkUrl, setLoginLinkUrl] = useState("");
  const handleLink = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Stripe/create-login-link?email=${Emailaddress}`,
        {},
        { headers }
      );

      setLoginLinkUrl(response.loginLinkUrl);
      setLoading(false);
    } catch (error) {
      console.error("Error linking Stripe account", error);
    } finally {
      setLoading(false);

    }
  };
  return (
    <div className="page-content">
      <Container >
        <Paper
          elevation={5}
          sx={{
            p: 4,
            borderRadius: 3,
            marginBottom: 4,
            bgcolor: "#f9f9f9",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            border: "1.5px solid #98b2a1",
          }}
        >
          <Typography variant="h5"
            sx={{
              mb: 3,
              fontWeight: "bold",
              textAlign: "center",
              color: "#fff",
              background: "#C2A27C",
              borderRadius: "8px",
              height: "50px",
              display: "flex", // Enables flexbox
              alignItems: "center", // Centers content vertically
              justifyContent: "center", // Centers content horizontally
            }}
          >
            User Profile
          </Typography>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Email
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="userName"
                  {...register("userName")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Please enter full name"
                      {...field}
                      onChange={(e) => {
                        setValue("userName", e.target.value)
                        setValue("fullName", e.target.value)
                      }}
                      fullWidth
                      error={errors.userName}
                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.userName?.message}</p>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="email"
                  {...register("email")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Please enter email"
                      {...field}
                      fullWidth
                      error={errors.email}
                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.email?.message}</p>
              </Grid>

              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Password
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Confirm Password
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="password"
                  {...register("password")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      value={field.value ? field.value : ''}
                      size="small"
                      placeholder="Please enter password"
                      type="password"
                      onChange={(e) => {
                        setValue("password", e.target.value);
                      }}
                      fullWidth
                      error={!!errors.password}
                    />}
                />

                <p style={{ color: 'red', fontSize: '14px' }}>{errors.password?.message}</p>
              </Grid>

              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="confirmPassword"
                  {...register("confirmPassword")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Please enter confirm password"
                      {...field}
                      fullWidth
                      type="password"
                      onChange={(e) => {
                        setIsPasswordChange(true);
                        setValue("confirmPassword", e.target.value);

                      }}
                      error={errors.confirmPassword}
                    />
                  }
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.confirmPassword?.message}</p>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  DOB
                </Typography>
              </Grid>

              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Zip Code
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="dob"
                  // {...register("dob")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='date'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='enter DOB'
                      value={Dob ? Dob : ''}
                      onChange={e => {
                        setValue('dob', e.target.value)
                        setDob(e.target.value)
                      }}

                    />
                  }
                />

              </Grid>


              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="zipCode"
                  {...register("zipCode")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='text'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='enter zipCode'
                    />
                  }
                />

              </Grid>

              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Gender
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Preferred Gender
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name='gender'
                  margin='dense'
                  {...register('gender')}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      style={{ marginTop: 0, marginBottom: 0 }}

                      {...field}
                      options={[
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                        { value: 'Other', label: 'Other' }
                      ]}
                      value={{ label: getValues('gender') }}
                      onChange={value => setValue('gender', value.label)}
                      size='small'
                    />
                  )}
                />

              </Grid>

              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name='preferredGender'
                  margin='dense'
                  {...register('preferredGender')}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      style={{ marginTop: 0, marginBottom: 0 }}

                      {...field}
                      options={[
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                        { value: 'NB', label: 'NB' }
                      ]}
                      value={{ label: getValues('preferredGender') }}
                      onChange={value => setValue('preferredGender', value.label)}
                      size='small'
                    />
                  )}
                />

              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Ethnicity
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Race
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="ethnicity"
                  {...register("ethnicity")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='text'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='Enter ethni city'
                    />
                  }
                />

              </Grid>

              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="race"
                  {...register("race")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='text'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='Enter race'
                    />
                  }
                />

              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Address
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Phone No
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="address"
                  {...register("address")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='text'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='Enter address'
                    />
                  }
                />

              </Grid>

              <Grid item xs={6} sm={6} xl={6}>

                <Controller
                  name="contactNo"
                  control={control}
                  render={({ field }) => (
                    <InputMask mask="(999) 999-9999" {...field}>
                      {(inputProps) => <TextField {...inputProps} size="small" placeholder="Enter Fax Number" fullWidth error={errors.fax} />}
                    </InputMask>
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  License Type
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  License Number
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="licenseType"
                  {...register("licenseType")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='text'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='Enter license type'
                    />
                  }
                />

              </Grid>

              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="licenseNumber"
                  {...register("licenseNumber")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='text'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='Enter license number'
                    />
                  }
                />

              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  License State
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  City
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="licenseState"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size='small'
                      fullWidth
                      {...field}
                      onChange={e => {
                        setValue('licenseState', e.target.value)
                      }}

                    />
                  }
                />

              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size='small'
                      fullWidth
                      {...field}
                      onChange={e => {
                        setValue('city', e.target.value)
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  License Approval Date
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  License Expiration  Date
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="licenseApprovalDate"
                  // {...register("dob")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='date'
                      size='small'
                      fullWidth
                      {...field}
                      value={licenseApprovalDate ? licenseApprovalDate : ''}
                      onChange={e => {
                        setValue('licenseApprovalDate', e.target.value)
                        setlicenseApprovalDate(e.target.value)
                      }}

                    />
                  }
                />

              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="licenseExpirationDate"
                  // {...register("dob")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='date'
                      size='small'
                      fullWidth
                      {...field}
                      value={licenseExpirationDate ? licenseExpirationDate : ''}
                      onChange={e => {
                        setValue('licenseExpirationDate', e.target.value)
                        setlicenseExpirationDate(e.target.value)
                      }}

                    />
                  }
                />

              </Grid>
              <Grid item xs={12} sm={12} xl={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Liability Insurance Carrier
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} xl={12}>
                <Controller
                  name="liabilityInsuranceCarrier"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size='small'
                      fullWidth
                      {...field}
                      onChange={e => {
                        setValue('liabilityInsuranceCarrier', e.target.value)
                      }}

                    />
                  }
                />

              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Liability Insurance Start Date
                </Typography>
              </Grid>

              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Liability Insurance Policy End Date
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="insurancePolicyStartDate"
                  // {...register("dob")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='date'
                      size='small'
                      fullWidth
                      {...field}
                      value={dateidissued ? dateidissued : ''}
                      onChange={e => {
                        setValue('insurancePolicyStartDate', e.target.value)
                        setDateidissued(e.target.value)
                      }}

                    />
                  }
                />

              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="insurancePolicyEndDate"
                  // {...register("dob")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type='date'
                      size='small'
                      fullWidth
                      {...field}
                      value={dateIDExpiration ? dateIDExpiration : ''}
                      onChange={e => {
                        setValue('insurancePolicyEndDate', e.target.value)
                        setDateIDExpiration(e.target.value)
                      }}

                    />
                  }
                />

              </Grid>

              <Grid item xs={6} >
                <InputLabel style={{ fontSize: '14px', color: 'black' }}> Policy Number:</InputLabel>
                <Controller
                  name="policyNumber"
                  {...register("policyNumber")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Please enter policy number"
                      {...field}
                      fullWidth

                    />}
                />
              </Grid>
              <>
                {UserType === "Supervisor" && !loginLinkUrl ? (
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        mt: 3,
                        backgroundColor: "#C2A27C",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
                        borderRadius: "8px",
                        "&:hover": { backgroundColor: "#B2956E" },
                      }}
                      onClick={handleLink}
                    >
                      {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Link Stripe Account"}
                    </Button>
                  </Grid>
                ) : loginLinkUrl ? (
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      href={loginLinkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        mt: 3,
                        backgroundColor: "#4CAF50",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
                        borderRadius: "8px",
                        "&:hover": { backgroundColor: "#45A049" },
                      }}
                    >
                      Link
                    </Button>
                  </Grid>
                ) : null}
              </>
              <Grid item xs={12} >
                <Typography style={{ fontSize: '14px' }}>
                  Type
                </Typography>
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="userType"
                  margin="dense"
                  {...register("userType")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      style={{ marginTop: 0, marginBottom: 0 }}
                      {...field}
                      size="small"
                      options={[
                        // { value: "Admin", label: "Admin" },
                        // { value: "Supervisor", label: "Supervisor" },
                        { value: "Therapist", label: "Therapist" },
                      ]}
                      value={{ label: getValues("userType") }}
                      onChange={value => {
                        setValue("userType", value.value);

                      }}
                      error={errors.userType}

                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.userType?.message}</p>

              </Grid>
              <Grid item xs={12} >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    backgroundColor: "#C2A27C",
                    color: "#fff",
                    ":hover": { backgroundColor: "#b0926e" },
                    py: 1.5,
                    fontWeight: "bold",
                  }}
                >
                  Update Profile
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Container>
    </div>


  )
}

export default Profile
