import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  IconButton,
  Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
const SubscriptionFormPage = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const useremail = location.state?.useremail;
  const supervisorId = location.state?.supervisorId || null;
  // const isUserOlder = !!location.state?.isUserOlder;
  console.log('useremail', useremail)
  const userRole = localStorage.getItem("selectedRole")
  const [packages, setPackages] = useState([]); // To store fetched packages
  const [selectedPackage, setSelectedPackage] = useState(null); // To store selected package
  const [loading, setLoading] = useState(false); // To manage loading state

  useEffect(() => {
    // Fetch packages from API
    setLoading(true); // Set loading to false in case of an error

    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/GetAllPackage`)
      .then((response) => {
        setPackages(response);
        // Store the fetched data
        setLoading(false); // Set loading to false when the API call completes
      })
      .catch((error) => {
        console.error('Error fetching packages:', error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, []);
  const filteredPackages = userRole === 'Therapist'
    ? packages.slice(0, 2) // First 2 rows for therapists
    : packages.slice(2, 4); // Next 2 rows for others

  const [error, setError] = useState('');
  const handleCardClick = (duration, stripeProductId) => {
    setSelectedPackage({ duration, stripeProductId });
    setError('')
  };
  const [pakid, setpakid] = useState()
  console.log('pakid', pakid)
  const [step, setStep] = React.useState(1);
  // useEffect(() => {
  //   if(isUserOlder ==true){
  //     setStep(2)
  //   }
  // }, [isUserOlder]); 
  const handleNext = (event) => {
    event.preventDefault();
    if (step === 2 && !selectedPackage) {
      setError('Please select a package before proceeding.');
      return;
    }
    setError('')
    if (userRole !== 'Supervisor') {
      setTimeout(() => {
        setLoading(false);
        navigate('/SubscriptionConfirmationPage', { state: { pakid: pakid, useremail: useremail,supervisorId:supervisorId  } });
      }, 500); // 
    }
    setLoading(true);
    const container = document.getElementById('Subscription-container');
    container.style.animation = 'shuffleOut 0.5s ease-in-out';
    setTimeout(() => {
      setStep(step + 1);
      setLoading(false);
    }, 500);
  };
  const [StripeError, setStripeError] = useState(false);
  const [createStripeClicked, setCreateStripeClicked] = useState(false);
  const [linkStripeClicked, setLinkStripeClicked] = useState(false);
  const handleNextPage = (event) => {
    if (!createStripeClicked && !linkStripeClicked) {
      setStripeError(true);
      return;
    }
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/SubscriptionConfirmationPage', { state: { pakid: pakid, useremail: useremail,supervisorId:supervisorId  } });
      // navigate('/SubscriptionConfirmationPage');
    }, 500); // Delay for animation effect
  }
  const styles = {
    container: {
      minHeight: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
    },
    backgroundImageContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("/mavesory.png")',
      backgroundSize: '45%',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      zIndex: -3,
    },
    innerContainer: {
      backgroundColor: '#fff',
      borderRadius: '20px',
      padding: '20px',
      maxWidth: '540px',
      width: '100%',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      borderTop: '3px solid rgba(0, 0, 0, 0.1)',
      animation: 'fadeIn 0.5s ease-in-out',
    },
    formTitle: {
      color: '#2C3E2D',
      marginBottom: '20px',
      fontWeight: 'bold',
      display: 'flex'
    },
    textField: {
      borderRadius: '4px',
      marginBottom: '10px',
      '& .MuiOutlinedInput-root': {
        background: 'green', // Green background color for the input
        borderRadius: '4px',
        height: '40px', // Set height of the input
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)', // Optional: Adjust border color
        },
        '&:hover fieldset': {
          borderColor: 'darkgreen', // Optional: Adjust hover border color
        },
        '&.Mui-focused fieldset': {
          borderColor: 'darkgreen', // Optional: Adjust focus border color
        },
      },
      '& .MuiInputBase-input': {
        fontSize: '14px', // Adjust font size
        // padding: '10px', // Add padding for better alignment
      },
    },
    nextButton: {
      background: '#7A9483',
      color: 'white',
      padding: '10px 30px',
      borderRadius: '20px',
      marginTop: '20px',
      '&:hover': {
        background: '#658570',
      },
    },
    card: {
      marginBottom: '10px',
      cursor: 'pointer',
      backgroundColor: '#a1c2a2',
      borderRadius: '10px',
      alignItems: 'center',
      transition: 'all 0.3s ease',
    },
    selectedCard: {
      border: '5px solid #b3946d',
    },
    button: {
      width: "200px",
      backgroundColor: "#a1c2a2",
      color: "white",
      padding: "10px 0",
      borderRadius: "5px",
      margin: '5px'
    },
    errorMessage: {
      color: 'red',
      textAlign: 'center',
      marginTop: '10px',
    },
  };

  // CSS for animations
  const animations = `
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;
  const [createStripe, setcreateStripe] = React.useState('');
  console.log('createStripe', createStripe)
  const [linkStripe, setlinkStripe] = React.useState('');

  // const emailofUser = localStorage.getItem("SignUpuserEmail")
  const handleCreateStripe = () => {
    if (useremail) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/Users/createStripeAccount?email=${useremail}`)
        .then(response => {
          setcreateStripe(response.accountLinkUrl)
          setLoading(false);
          setCreateStripeClicked(true);
        })
        .catch((error) => {
          console.error("Error creating Stripe account:", error);
          setLoading(false);
        });
    }
  };
  const handleLinkStripe = () => {
    if (useremail) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/Users/linkStripeAccount?email=${useremail}`)
        .then(response => {
          setlinkStripe(response.accountLinkUr)
          setLoading(false);
          setLinkStripeClicked(true);
        })
        .catch((error) => {
          console.error("Error creating Stripe account:", error);
          setLoading(false);
        });
    }
  };
  const handleDelete = () => {
    if (useremail) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/Users/delete-incompelete?email=${useremail}`)
        .then(response => {
          navigate("/login");
        })
        .catch(error => {
          navigate("/login");
        });
    }
  };
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const handleSubscribe = async () => {
    if (!otp) {
      Swal.fire({
        icon: "warning",
        title: "OTP is Required!",
        text: "OTP is Required!",
      });
      return;
    }
    const sanitizedEmail = useremail
      .trim()
      .replace(/[",]/g, "") // Removes commas and quotes
      .replace(/\s+/g, ""); // Removes extra spaces
    const parsedOtp = parseInt(otp, 10);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Users/EmailVerification?email=${sanitizedEmail}&otp=${parsedOtp}`
      );
      Swal.fire({
        icon: "success",
        title: "Verification Successful",
        text: "You have been successfully Verified!",
      });
      setStep(step + 1);
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Verification Failed",
        text: "Failed to Verified. Please try again.",
      });

    }
  };
  return (
    <React.Fragment>
      {/* Include animations in the same file */}
      <style>{animations}</style>
      <Container maxWidth={false} style={styles.container}>
        <div style={styles.backgroundImageContainer}></div>
        {loading && <LinearProgress style={{ width: '100%', position: 'absolute', top: 0, color: 'green' }} />}
        {step === 1 && (
          <Box id="Subscription-container" style={styles.innerContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%", // Adjust width as needed
              }}
            >
              <Typography variant="h5" style={{ margin: '3px', fontWeight: 'bold' }}>
                EMAIL VERIFICATION
              </Typography>
              <IconButton
                onClick={handleDelete}
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  marginTop: '-5px',
                  float: 'right',
                  alignItems: 'right',
                  margin: '3px',
                  justifyContent: "right",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Grid container spacing={1} mt={2} >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="EMAIL"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      style={styles.textField}
                      value={useremail}
                      // onChange={(e) => setEmail(e.target.value)}
                      InputProps={{ style: { height: '40px' }, readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="OTP"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      style={styles.textField}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      InputProps={{ style: { height: '40px' } }}
                    />
                  </Grid>


                </Grid>

                <Button
                  variant="contained"
                  fullWidth
                  style={styles.nextButton}
                  onClick={handleSubscribe}
                // disabled={!selectedPackage} // Disable if no package is selected
                >
                  VERIFY
                </Button>
              </>
            )}

          </Box>
        )}
        {step === 2 && (
          <Box id="Subscription-container" style={styles.innerContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%", // Adjust width as needed
              }}
            >
              <Typography variant="h5" style={{ margin: '3px', fontWeight: 'bold' }}>
                Subscriptions
              </Typography>
              <IconButton
                onClick={handleDelete}
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  marginTop: '-5px',
                  float: 'right',
                  alignItems: 'right',
                  margin: '3px',
                  justifyContent: "right",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>

                {/* Display packages if loaded */}
                {filteredPackages && filteredPackages.length > 0 ? (
                  filteredPackages.map((pkg) => (
                    <Card
                      key={pkg.id}
                      onClick={() => handleCardClick(pkg.duration, pkg.stripeProductId, setpakid(pkg.id))}
                      sx={{
                        ...styles.card,
                        ...(selectedPackage?.duration === pkg.duration && styles.selectedCard),
                      }}
                    >
                      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{ fontWeight: 'bold' }}>{pkg.packageName}</Typography>
                        {pkg.id === 5 ? (
                          <Typography>{`$65.99`}</Typography>
                        ) : ''}
                        <Typography>{`$${pkg.price} + TAX`}</Typography>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography>No packages available</Typography>
                )}
                {error && <Typography style={styles.errorMessage}>{error}</Typography>}
                <Button
                  variant="contained"
                  fullWidth
                  style={styles.nextButton}
                  onClick={handleNext}
                // disabled={!selectedPackage} // Disable if no package is selected
                >
                  Subscribe
                </Button>
              </>
            )}

          </Box>
        )}

        {step === 3 && userRole == 'Supervisor' ? (
          <Box id="Stripe-container" style={styles.innerContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%", // Adjust width as needed
              }}
            >
              <Typography variant="h5" style={{ margin: '3px', fontWeight: 'bold' }}>
                Stripe
              </Typography>
              <IconButton
                onClick={handleDelete}
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  marginTop: '-5px',
                  float: 'right',
                  alignItems: 'right',
                  margin: '3px',
                  justifyContent: "right",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            < TextField
              label="Account ID"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{ style: { height: '40px' } }}
            />
            <Button onClick={handleCreateStripe} variant='contained' style={styles.button} >
              Create Stripe
            </Button>
            <Button onClick={handleLinkStripe} variant='contained' style={styles.button}>
              Link Stripe
            </Button>
            <Box
              style={{
                marginTop: "20px",
                padding: "15px",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: "14px",
                  color: "#333",
                  lineHeight: "1.6",
                  fontWeight: "500",
                }}
              >
                <strong>Note:</strong> Click <strong>"Create Stripe"</strong> to establish a new Stripe connection.
                Alternatively, if you already have an account, enter your <strong>Account ID</strong> in the input field above
                and click <strong>"Link Stripe"</strong> to link your existing account. Ensure all details are accurate
                before proceeding.
              </Typography>
            </Box>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
              >
                <CircularProgress />
              </Box>
            ) : null}
            {createStripe && (
              <a
                href={createStripe}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "inline-block",
                  padding: "10px 15px",
                  background: "#007bff",
                  color: "#fff",
                  textDecoration: "none",
                  borderRadius: "4px",
                }}
              >
                Create Stripe Account
              </a>
            )}
            {linkStripe && (
              <a
                href={linkStripe}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "inline-block",
                  padding: "10px 15px",
                  background: "#007bff",
                  color: "#fff",
                  textDecoration: "none",
                  borderRadius: "4px",
                }}
              >
                linkStripe Your Stripe Account
              </a>
            )}
            {StripeError && <Typography style={styles.errorMessage}>To proceed, please create or link your Stripe account.</Typography>}
            <Button
              variant="contained"
              fullWidth
              style={styles.nextButton}
              onClick={handleNextPage}
            // disabled={!selectedPackage} // Disable if no package is selected
            >
              NEXT
            </Button>
          </Box>
        ) : (<>
          {step === 3 && (<Button
            variant="contained"
            fullWidth
            style={styles.nextButton}
            onClick={handleNextPage}
          // disabled={!selectedPackage} // Disable if no package is selected
          >
            NEXT
          </Button>)}

        </>
        )}
      </Container>
    </React.Fragment >
  );
};

export default SubscriptionFormPage;
