import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import RadioGroup from '@mui/material/RadioGroup'



import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";



const EducationRegistration = props => {
  const fields = [
    "id",
    "practiceId",
    "supervisorId",
    "sponsorName",
    "course",
    "programTitle",
    "nbcc",
    "registeredPlayTherapy",
    "emdr",
    "apa",
    "nasw",
    "mft",
    "others",
    "numberOfCes",
    "completionDate"
  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    supervisorId: 0,
    sponsorName: "",
    course: "",
    programTitle: "",
    nbcc: "",
    registeredPlayTherapy: "",
    emdr: "",
    apa: "",
    nasw: "",
    mft: "",
    others: "",
    numberOfCes: "",
    completionDate: "",
  };
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const location = useLocation();
  const [Eudid, setEudid] = useState(props.callingFrom == 'dashboard' ? props.uid : props.Eduid)


  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    sponsorName: Yup.string().required("Name is required"),

    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });

  const [completionDate, setcompletionDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const onSubmit = data => {
    let postData = data;
    postData.supervisorId = props.SEduid
    postData.therapistId = props.TEduid
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/EducationDetails/addEducationDetails`,
        postData,
        { headers }
      )
      .then(response => {
        setSubmitting(false);

        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            props.handleCloseEducation()

          }
        });
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/EducationDetails/getEducationDetailsById?id=${Eudid}`, {
        headers
      })
      .then(response => {
        fields.forEach(field => {
          setValue(field, response[field]);
          var dateYMD = Moment(response.completionDate).format('YYYY-MM-DD')
          setcompletionDate(dateYMD)
        });
      })
      .catch(error => { });
  }, []);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 2,
    borderRadius: '8px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  function handleCancel() {
    navigate("/SitePage");
  }
  return (
    <React.Fragment >
      <Grid container spacing={1}>


        <Grid item xs={12} >
          <Typography style={{ fontSize: '14px' }}>
            Provider/Sponsor Name
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <Controller
            name="sponsorName"
            {...register("sponsorName")}
            control={control}
            render={({ field }) =>
              <TextField
                size="small"
                placeholder="Please Enter Full Name"
                {...field}
                fullWidth
                error={errors.sponsorName}
              />}
          />
          <p style={{ color: 'red', fontSize: '14px' }}>{errors.sponsorName?.message}</p>
        </Grid>
        <Grid item xs={12} >
          <Typography style={{ fontSize: '14px' }}>
            Course/Activity
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <Controller
            name="course"
            {...register("course")}
            control={control}
            render={({ field }) =>
              <TextField
                size="small"
                placeholder="Please Enter Course"
                {...field}
                fullWidth
              // error={errors.locationName}
              />}
          />
          {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.locationName?.message}</p> */}
        </Grid>
        <Grid item xs={12} >
          <Typography style={{ fontSize: '14px' }}>
            Program Title
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <Controller
            name="programTitle"
            {...register("programTitle")}
            control={control}
            render={({ field }) =>
              <TextField
                size="small"
                placeholder="Please Enter Program Title"
                {...field}
                fullWidth
              // error={errors.locationName}
              />}
          />
          {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.locationName?.message}</p> */}
        </Grid>
        <Grid item xs={12} >
          <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Certificate Type (select all that applies):
          </Typography>
        </Grid>
        <Grid container  >
            <Grid container spacing={1} style={{ border: '2px solid #96ACEF', borderRadius: '5px', marginTop: "10px", }}>
              <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px', }}>
                  NBCC/ACEP
                </Typography>
              </Grid>
              <Grid item xs={2.5} sm={2.5} xl={2.5}>
                <Typography style={{ fontSize: '14px', }}>
                  Registered Play Therapy/APT
                </Typography>
              </Grid>
              <Grid item xs={1.5} sm={1.5} xl={1.5}>
                <Typography style={{ fontSize: '14px', }}>
                  EMDR
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} xl={1}>
                <Typography style={{ fontSize: '14px', }}>
                  APA
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px', }}>
                  NASW/ASWB
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} xl={1}>
                <Typography style={{ fontSize: '14px', }}>
                  MFT
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px', }}>
                  Other
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} xl={2}>
                <input
                  name='nbcc'
                  size='small'
                  type='checkbox'
                  {...register('nbcc')}
                  id='nbcc'
                />
              </Grid>
              <Grid item xs={2.5} sm={2.5} xl={2.5}>
                <input
                  name='registeredPlayTherapy'
                  size='small'
                  type='checkbox'
                  {...register('registeredPlayTherapy')}
                  id='registeredPlayTherapy'
                />
              </Grid>
              <Grid item xs={1.5} sm={1.5} xl={1.5}>
                <input
                  name='emdr'
                  size='small'
                  type='checkbox'
                  {...register('emdr')}
                  id='emdr'
                />
              </Grid>
              <Grid item xs={1} sm={1} xl={1}>
                <input
                  name='apa'
                  size='small'
                  type='checkbox'
                  {...register('apa')}
                  id='apa'
                />
              </Grid>
              <Grid item xs={2} sm={2} xl={2}>
                <input
                  name='nasw'
                  size='small'
                  type='checkbox'
                  {...register('nasw')}
                  id='nasw'
                />
              </Grid>
              <Grid item xs={1} sm={1} xl={1}>
                <input
                  name='mft'
                  size='small'
                  type='checkbox'
                  {...register('mft')}
                  id='mft'
                />
              </Grid>
              <Grid item xs={2} sm={2} xl={2}>
                <input
                  name='others'
                  size='small'
                  type='checkbox'
                  {...register('others')}
                  id='others'
                />
              </Grid>
            </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>




        </Grid>
   
        <Grid item xs={6} style={{ marginBottom: '10px' }}>
        <Typography style={{ fontSize: '14px', marginTop: '10px', marginBottom: '10px' }}>
        Completion Date
          </Typography>
          <Controller
            name="completionDate"
            // {...register("completionDate")}
            control={control}
            render={({ field }) =>
              <TextField
                type="date"
                size="small"
                {...field}
                fullWidth
                value={completionDate}
                onChange={e => {
                  setValue('completionDate', e.target.value)
                  setcompletionDate(e.target.value)
                }}
              />}
          />

        </Grid>
      
        <Grid item xs={6} style={{ marginBottom: '10px' }}>
        <Typography style={{ fontSize: '14px', marginTop: '10px', marginBottom: '10px' }}>
            Number of CE’s
          </Typography>
          <Controller
            name="numberOfCes"
            {...register("numberOfCes")}
            control={control}
            render={({ field }) =>
              <TextField
                type="text"
                size="small"
                placeholder="Please Enter  Number of CES"
                {...field}
                fullWidth

              />}
          />

        </Grid>
        <Grid item xs={12} sm={12} xl={12} style={{ marginTop: '10px' }}>
          <Button
            className="Button"
            onClick={handleSubmit(onSubmit)}
            disabled={submitting}
            fullWidth
            style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
            type="submit"
          >
            Save
          </Button>
        </Grid>


      </Grid>


    </React.Fragment>
  );
};
export default EducationRegistration;
