import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import github from '../../../assets/images/brands/github.png'
import bitbucket from '../../../assets/images/brands/bitbucket.png'
import dribbble from '../../../assets/images/brands/dribbble.png'
import dropbox from '../../../assets/images/brands/dropbox.png'
import mail_chimp from '../../../assets/images/brands/mail_chimp.png'
import slack from '../../../assets/images/brands/slack.png'

//i18n
import { withTranslation } from "react-i18next";

const RequestsDropDown = () => {
  const [singlebtn, setSinglebtn] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={singlebtn}
        toggle={() => setSinglebtn(!singlebtn)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
<svg width="36" height="36" viewBox="0 0 24 24" fill="currentColor" >
    <path d="M16 17H9.375q-.575 0-.937.35t-.438.8t.138.875t.762.625l5.725 2.275q.25.1.488.063t.437-.163t.325-.337T16 21zM10 2q1.65 0 2.825 1.175T14 6t-1.175 2.825T10 10T7.175 8.825T6 6t1.175-2.825T10 2m9.225 9q.725 0 1.25.487T21 12.7q0 .575-.325 1.05t-.875.675L18 15H9.375q-1.425 0-2.4.975T6 18.375q0 .45.125.863T6.45 20H3v-4q0-2.075 1.463-3.537T8 11z"/>
</svg>









        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end">

          <div className="px-lg-2">
            <SimpleBar >


              <Row className="g-0">
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={github} alt="Github" />
                    <span>GitHub</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={bitbucket} alt="bitbucket" />
                    <span>Bitbucket</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={dribbble} alt="dribbble" />
                    <span>Dribbble</span>
                  </Link>
                </Col>
              </Row>
              <Row className="g-0">
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={dropbox} alt="dropbox" />
                    <span>Dropbox</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={mail_chimp} alt="mail_chimp" />
                    <span>Mail Chimp</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={slack} alt="slack" />
                    <span>Slack</span>
                  </Link>
                </Col>
              </Row>
            </SimpleBar>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(RequestsDropDown);