import { useState, useEffect, startTransition } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SignInIcon from '@mui/icons-material/PersonAdd';

const AgendaRegistration = props => {
  const fields = [
    "id",
    "userId",
    "therapistsId",
    "practiceId",
    "supervisorId",
    "appointmentId",
    "modality",
    "userType",
    "taskPerformed",
    "approvedBy",
    "discussionIC",
    "userContent",
    "dateTime",
    "startTime",
    "endTime",
    "goals",
    "approve",
    "pendingApproval",
    "inActive",
    "createdDate",
    "updatedDate",
    "createdBy",
    "updatedBy",
    "approvedDateTime",
    "therapistTimeStamp",
    "supervisorSign",
    "superviseeSign"

  ];
  const defValues = {
    id: 0,
    userId: 0,
    therapistsId: 0,
    practiceId: 0,
    supervisorId: 0,
    appointmentId: 0,
    modality: "",
    userType: "",
    taskPerformed: "",
    approvedBy: "",
    discussionIC: "",
    userContent: "",
    dateTime: "",
    startTime: "",
    endTime: "",
    goals: "",
    approve: true,
    pendingApproval: true,
    inActive: true,
    createdDate: "",
    updatedDate: "",
    createdBy: "",
    updatedBy: "",
    approvedDateTime: "",
    therapistTimeStamp: "",
    supervisorSign: "",
    superviseeSign: ""
  }
  const [Date, setDate] = React.useState('')
  useEffect(() => {
    if (props.appointmentDate) {
      setDate(props.appointmentDate);
    }
  }, [props.appointmentDate]);
  const [StartTime, setStartTime] = React.useState(props.sTime)
  const [EndTime, setEndTime] = React.useState(props.eTime)
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [_userId, set_userId] = useState(props.callingFrom == 'dashboard' ? props.uid : props.UID)
  const [approve, setapprove] = useState(false);
  const [Approval, setapprovel] = useState(false);
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const Usertype = localStorage.getItem('User_Type')
  const UserName = localStorage.getItem('UserName')
  // const validationSchema = Yup.object({
  //   dateTime: Yup.string().required("Date is required"),

  // });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [SupervisorSignError, setSupervisorSignError] = useState(false);
  const [therapistSignError, settherapistSignError] = useState(false);

  const handleSignInSupervisor = () => {
    setValue('supervisorSign', UserName);
    setSupervisorSignError(false)
  };

  const handleSignInSupervisee = () => {
    setValue('superviseeSign', UserName);
    settherapistSignError(false)

  };
  const [dateError, setDateError] = useState(false);
  const SupervisorIdOfTherapist = localStorage.getItem('SupervisorID')
  const USERID = localStorage.getItem('userid')

  const onSubmit = (data) => {
    let postData = { ...data };
    postData.pendingApproval = true;
    setSubmitting(true);
    if (!Date) {
      setDateError(true);
      setSubmitting(false);
      return;
    }
    if (Usertype == 'Supervisor') {
      postData.supervisorId = USERID
    } else if (Usertype == 'Therapist') {
      postData.supervisorId = SupervisorIdOfTherapist
    }
    postData.appointmentId = typeof _userId == 'string' ? parseInt(_userId, 10) : _userId;
    postData.dateTime = Date;
    postData.startTime = StartTime;
    postData.endTime = EndTime;
    postData.therapistsId = props.TherapistID
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/UserNotes/AddUserNote`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

            onClose: () => {
              if (props.callingFrom == 'dashboard') {
                props.handleCloseprofile()
              }
              else {
                props.handleClose()
              }

            }

          });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     if(props.callingFrom == 'dashboard')
          //     {
          //       props.handleCloseprofile()
          //     }
          //     navigate("/Agendas");
          //   }
          // });
        } else if (response.length > 0) {

          setSubmitting(false);
          return;
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };

  const onSubmitagenda = (data) => {
    let postData = { ...data };
    postData.therapistsId = props.TherapistID || data.therapistsId
    postData.appointmentId = typeof _userId == 'string' ? parseInt(_userId, 10) : _userId;
    postData.dateTime = Date;
    postData.startTime = StartTime ? StartTime :data.startTime;
    postData.endTime = EndTime ? EndTime :data.endTime;
    setSubmitting(true);
    if (!Date) {
      setDateError(true);
      setSubmitting(false);
      return;
    }

    if (Usertype == 'Therapist') {
      postData.supervisorId = SupervisorIdOfTherapist;
      postData.therapistTimeStamp = Moment().format('YYYY-MM-DD HH:mm');
      if (!data.superviseeSign) {
        settherapistSignError(true);
        setSubmitting(false);
        return;
      }
    }

    else if (Usertype == 'Supervisor') {
      postData.approve = true;
      postData.pendingApproval = false
      postData.approvedBy = UserName;
      postData.approvedDateTime = Moment().format('YYYY-MM-DD HH:mm');
      postData.supervisorId = USERID;
      if (!data.supervisorSign) {
        setSupervisorSignError(true);
        setSubmitting(false);
        return;
      }
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/UserNotes/AddUserNote`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

            onClose: () => {
              if (props.callingFrom == 'dashboard') {
                props.handleCloseprofile()
              }
              else {
                props.handleClose()
              }
            }

          });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     if(props.callingFrom == 'dashboard')
          //     {
          //       props.handleCloseprofile()
          //     }
          //     navigate("/Agendas");

          //   }
          // });
        } else if (response.length > 0) {

          setSubmitting(false);
          return;
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const [Data, setData] = useState([])

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [Disable, SetDisable] = useState(false);
  useEffect(() => {
    if (props.callingFrom == 'dashboard') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/UserNotes/getUserNoteByAppointmnetId?AppointmentId=${_userId}`, {
          headers
        })
        .then(response => {
          fields.forEach(field => {
            setValue(field, response[field]);
          });

          if (response.data) {
            setIsDataLoaded(true);
          }
          if (response && response.status !== 204) {
            SetDisable(true);
          }
          setapprove(response.approve)
          setData(response)
          setapprovel(response.pendingApproval)
          //   var dateYMD4 = Moment(response.startTime).format("HH:mm:ss")
          // setStartTime(dateYMD4)
          // var dateYMD5 = Moment(response.endTime).format("HH:mm:ss")
          // setEndTime(dateYMD5)
          if (response.dateTime) {
            var dateYMD = Moment(response.dateTime).format('YYYY-MM-DD')
            setValue('dateTime', dateYMD);
            setDate(dateYMD);
          }


        })

        .catch(error => { });
    }
    else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/UserNotes/getLocationById?userNoteId=${_userId}`, {
          headers
        })
        .then(response => {
          fields.forEach(field => {
            setValue(field, response[field]);
          });
          if (response && response.status !== 204) {
            SetDisable(true);
          }
          var dateYMD = Moment(response.dateTime).format('YYYY-MM-DD')
          setValue('dateTime', dateYMD);
          setDate(dateYMD);
          setapprove(response.approve)
          setData(response)



        })
        .catch(error => { });
    }
  }, []);

  function handleCancel() {
    navigate("/Agendas");
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const style1 = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    // border: '2px solid #EA7772',
    // boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    // borderRadius: '8px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const [TherapistID, setTherapistID] = useState("");
  const [TherapistList, setTherapistList] = useState([]);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.fullName,
            value: field.id
          });
        });
        setTherapistList(prac);

      })
      .catch(error => { });

  }, []);

  const handleDownload = async () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    doc.setDrawColor(25, 42, 86);
    doc.setLineWidth(0.2);
    const margin = 10; // Margin from the edge
    doc.rect(margin, margin, pageWidth - margin * 2, pageHeight - margin * 2);

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 102, 204);
    const title = 'Agenda Registration Report';
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - titleWidth) / 2, 20);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0);

    const formattedDate = Moment(Data.dateTime).format('MM-DD-YYYY');
    const formattedApprovedTime = Data.approvedDateTime
      ? Moment(Data.approvedDateTime).format('MM-DD-YYYY [at] HH:mm')
      : ' N/A';

    const formattedTherapistTime = Data.therapistTimeStamp
      ? Moment(Data.therapistTimeStamp).format('MM-DD-YYYY [at] HH:mm')
      : ' N/A';

    doc.autoTable({
      startY: 40,
      theme: 'grid',
      headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255], fontStyle: 'bold' },
      styles: { lineWidth: 0.2, lineColor: [0, 0, 0] },
      body: [
        [
          { content: 'Start Time', styles: { halign: 'center', fontStyle: 'bold', textDecoration: 'underline' } },
          { content: Data.startTime, styles: { halign: 'center' } },
          { content: 'End Time', styles: { halign: 'center', fontStyle: 'bold' } },
          { content: Data.endTime, styles: { halign: 'center' } }
        ],
        [
          { content: 'Task Performed', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.taskPerformed || '', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Discussion IC', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.discussionIC || '', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'UserContent', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.userContent || '', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Date', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: formattedDate || '', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Goals', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.goals || '', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Signed By Supervisor:' + Data.supervisorSign, colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        // [
        //   { content: Data.supervisorSign || '', colSpan: 4, styles: { halign: 'left' } }
        // ],
        [
          {
            content: 'Supervisor Signed On' + formattedApprovedTime,
            colSpan: 4,
            styles: { halign: 'left', fontStyle: 'bold' }
          },
        ],
        [
          { content: 'Signed By Supervisee:' + Data.superviseeSign, colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        // [
        //   { content: Data.superviseeSign || '', colSpan: 4, styles: { halign: 'left' } }
        // ],
        [
          {
            content: 'Supervisee Signed On ' + formattedTherapistTime,
            colSpan: 4,
            styles: { halign: 'left', fontStyle: 'bold' }
          }
        ],
      ],
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 50 },
        2: { cellWidth: 40 },
        3: { cellWidth: 50 },
      },
    });

    // Save the PDF
    const fileName = `Agenda_Request_Pdf.pdf`;
    doc.save(fileName);
  };

  return (
    <React.Fragment>
      <Grid container>
        {props.callingFrom == 'dashboard' ? (<>
          <Box sx={style1} >
            <Grid container spacing={1}>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Appointment Date
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name="dateTime"
                  // {...register("dateTime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="date"
                      {...field}
                      fullWidth
                      value={Date}
                      InputProps={{
                        readOnly: Disable,
                      }}
                      onChange={e => {
                        setValue('dateTime', e.target.value)
                        setDate(e.target.value)
                        setDateError(false)
                      }}

                    />}
                />
                {dateError && <p style={{ color: 'red' }}>Please Select Date</p>}
              </Grid>

              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Therapist
                </Typography>
              </Grid>


              <Grid item xs={12} lg={12}>
                <Controller
                  name="therapistsId"
                  {...register("therapistsId")}
                  control={control}
                  render={({ field }) => {
                    const selectedValue = TherapistList.find(option => option.value === props.TherapistID);
                    return (
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={selectedValue}
                        isDisabled={true}
                        onChange={e => {
                          setValue("therapistsId", e.value);
                          setTherapistID(e.value);
                        }}
                        options={TherapistList}
                      />
                    );
                  }}
                />

              </Grid>


              {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                Therapist
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid> */}


              <Grid item xs={6} lg={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Start Time
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Typography style={{ fontSize: '14px' }}>
                  End Time
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Controller
                  name="startTime"
                  // {...register("startTime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="time"
                      size="small"
                      {...field}
                      fullWidth
                      InputProps={{
                        readOnly: Disable,
                      }}
                      value={StartTime ? StartTime : ''}
                      // value={field.value} 
                      onChange={e => {
                        setValue('startTime', e.target.value)

                        // field.onChange(e.target.value);
                        setStartTime(e.target.value);
                      }}
                    />}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <Controller
                  name="endTime"
                  // {...register('endTime')}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="time"
                      fullWidth
                      InputProps={{
                        readOnly: Disable,
                      }}
                      value={EndTime ? EndTime : ''}
                      // value={field.value} 
                      onChange={e => {
                        setValue('endTime', e.target.value)
                        // field.onChange(e.target.value);
                        setEndTime(e.target.value);
                      }}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Activities Performed
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name='taskPerformed'
                  {...register('taskPerformed')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter activities'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                      disabled={Disable}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Issues/Concerns Discussed
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name='discussionIC'
                  {...register('discussionIC')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter issues'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                      disabled={Disable}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Specific Supervisor Content
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name='userContent'
                  {...register('userContent')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter supervisor content'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                      disabled={Disable}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Goals for Provisionally Licensed therapist
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name='goals'
                  {...register('goals')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter goals'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                      disabled={Disable}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="superviseeSign"
                  control={control}
                  {...register("superviseeSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisee Signature..."
                      {...field}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSignInSupervisee}
                              disabled={Usertype == 'Supervisor'} // Disable button for Therapist
                            >
                              <SignInIcon style={{ color: '#356F60' }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      onChange={e => {
                        setValue('superviseeSign', e.target.value);
                      }}

                    />
                  }
                />
                {therapistSignError && <p style={{ color: 'red' }}> Supervisee Sign required</p>}
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="supervisorSign"
                  control={control}
                  {...register("supervisorSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisor Signature..."
                      {...field}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSignInSupervisor}
                              disabled={Usertype == 'Therapist'} // Disable button for Therapist
                            >
                              <SignInIcon style={{ color: '#356F60' }} />

                            </IconButton>
                          </InputAdornment>
                        ),
                      }}

                      onChange={e => {
                        setValue('supervisorSign', e.target.value);
                      }}

                    />
                  }
                />
                {SupervisorSignError && <p style={{ color: 'red' }}> Supervisor Sign required</p>}

              </Grid>


              <Grid item xs={6} lg={6}>
                <Button
                  className="Button"
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting || Approval || approve}
                  fullWidth
                  style={{ borderRadius: '10px', color: (Approval || approve) ? 'grey' : '#fff', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  type="submit"
                >
                  Save Agenda
                </Button>
              </Grid>
              {Usertype == 'Therapist' ? (<>
                {approve == true ? (<>
                  <Grid item xs={6} lg={6}>
                    <Button
                      className="Button"
                      onClick={handleSubmit(onSubmitagenda)}
                      disabled={submitting}

                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >

                      Approved
                      <TaskAltIcon
                        style={{
                          color: approve == true ? 'green' : 'red',
                          // cursor: "not-allowed",
                        }}
                      >

                      </TaskAltIcon>
                    </Button>
                  </Grid>

                </>) : (<>
                  <Grid item xs={6} lg={6}>
                    <Button
                      className="Button"
                      onClick={handleSubmit(onSubmitagenda)}
                      disabled={submitting}
                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >
                      Send For Approval
                      <TaskAltIcon
                        style={{
                          color: Approval == true ? 'green' : 'red',
                          // cursor: "not-allowed",
                        }}
                      >

                      </TaskAltIcon>
                    </Button>
                  </Grid>

                </>)}
              </>) : (
                <>
                  {approve == true ? (<>
                    <Grid item xs={6} lg={6}>
                      <Button
                        className="Button"
                        onClick={handleSubmit(onSubmitagenda)}
                        disabled={submitting}
                        fullWidth
                        style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                        type="submit"
                      >
                        Approved
                        <TaskAltIcon
                          style={{
                            color: approve == true ? 'green' : 'red',
                            // cursor: "not-allowed",
                          }}
                        >

                        </TaskAltIcon>
                      </Button>
                    </Grid></>) : (

                    <>
                      <Grid item xs={6} lg={6}>
                        <Button
                          className="Button"
                          onClick={handleSubmit(onSubmitagenda)}
                          disabled={submitting}
                          fullWidth
                          style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                          type="submit"
                        >
                          <TaskAltIcon
                            style={{
                              color: approve == true ? 'green' : 'red',
                              // cursor: "not-allowed",
                            }}
                          />
                          Approved

                        </Button>
                      </Grid>

                    </>
                  )}
                </>
              )}
              {/* <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                <Button

                  onClick={props.callingFrom == 'dashboard' ? props.handleCloseprofile : props.handleClose}

                  disabled={submitting}
                  fullWidth
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", backgroundColor: '#377562', fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}

                >

                  View More Agendas
                </Button>

              </Grid> */}
            </Grid>



          </Box>

        </>) : (<>
          {/* <Box sx={style} > */}

          <Grid container spacing={1}>


            {/* <Grid item xs={12} sm={12} xl={12} > 
        <Headroom >
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Agenda Registration

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                          onClick={props.callingFrom == 'dashboard'?props.handleCloseprofile:handleCancel}

                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              </Grid> */}
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={8} sm={8} xl={8}></Grid>
            <Grid item xs={3} sm={3} xl={3}>
              <Button
                className="Button"
                onClick={handleDownload}
                fullWidth
                disabled={!Data || Data.length === 0}
                variant='contained'
                style={{
                  fontSize: '14px', fontWeight: 'bold',
                  float: 'right',
                  marginTop: '10px'
                }}
                type="submit"
              >
                Download
                <DownloadForOfflineIcon />
              </Button>
            </Grid>

            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={0.1} sm={0.1} xl={0.1}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Date
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11} style={{ marginTop: '3px' }}>
                <Controller
                  name="dateTime"
                  // {...register("dateTime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="date"
                      {...field}
                      fullWidth
                      value={Date ? Date : ''}
                      InputProps={{
                        readOnly: Disable,
                      }}
                      onChange={e => {
                        setValue('dateTime', e.target.value)
                        setDate(e.target.value)
                      }}
                      error={errors.dateTime}
                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.dateTime?.message}</p>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Therapist
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name="therapistsId"
                  // {...register("therapistsId")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={TherapistList.filter(function (option) {
                        return (
                          option.value === getValues("therapistsId")
                        );
                      })}
                      onChange={e => {
                        setValue("therapistsId", e.value);
                        setTherapistID(e.value);


                      }}
                      options={TherapistList}
                      isDisabled={Disable}
                    />

                  }
                />
              </Grid>
            </>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5} >
                <Typography style={{ fontSize: '14px' }}>
                  Start Time
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}  >
                <Typography style={{ fontSize: '14px' }}>
                  End Time
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5} >
                <Controller
                  name="startTime"
                  {...register("startTime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="time"
                      size="small"
                      {...field}
                      InputProps={{
                        readOnly: Disable,
                      }}
                      fullWidth

                    />}
                />
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5} >
                <Controller
                  name="endTime"
                  {...register("endTime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="time"
                      size="small"
                      {...field}
                      fullWidth
                      InputProps={{
                        readOnly: Disable,
                      }}

                    />}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Activities Performed
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>


              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='taskPerformed'
                  {...register('taskPerformed')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter activities'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                      disabled={Disable}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Issues/Concerns Discussed
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='discussionIC'
                  {...register('discussionIC')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter issues'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                      disabled={Disable}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Specific Supervisor Content
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='userContent'
                  {...register('userContent')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter supervisor content'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                      disabled={Disable}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Goals for Provisionally Licensed therapist
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='goals'
                  {...register('goals')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter goals'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'

                      }}
                      {...field}
                      fullWidth
                      disabled={Disable}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
              <Grid item xs={5.5}>
                <Controller
                  name="superviseeSign"
                  control={control}
                  {...register("superviseeSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisee Signature..."
                      {...field}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSignInSupervisee}
                              disabled={Usertype == 'Supervisor'} // Disable button for Therapist
                            >
                              <SignInIcon style={{ color: '#356F60' }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      onChange={e => {
                        setValue('superviseeSign', e.target.value);
                      }}

                    />
                  }
                />
                {therapistSignError && <p style={{ color: 'red' }}> Supervisee Sign required</p>}
              </Grid>

              <Grid item xs={5.5}>
                <Controller
                  name="supervisorSign"
                  control={control}
                  {...register("supervisorSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisor Signature..."
                      {...field}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSignInSupervisor}
                              disabled={Usertype == 'Therapist'} // Disable button for Therapist
                            >
                              <SignInIcon style={{ color: '#356F60' }} />

                            </IconButton>
                          </InputAdornment>
                        ),
                      }}

                      onChange={e => {
                        setValue('supervisorSign', e.target.value);
                      }}

                    />
                  }
                />
                {SupervisorSignError && <p style={{ color: 'red' }}> Supervisor Sign required</p>}

              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

            </>

            <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
            <Grid item xs={5.5} sm={5.5} xl={5.5} >
              <Button
                className="Button"
                onClick={handleSubmit(onSubmit)}
                disabled={submitting || approve || Approval}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                type="submit"
              >
                Save Agenda
              </Button>
            </Grid>
            {Usertype == 'Therapist' || Usertype == 'Supervisor' || Usertype == 'Admin' ? (<>
              {approve == true ? (
                <>

                  <Grid item xs={5.5} sm={5.5} xl={5.5} >
                    <Button
                      className="Button"
                      onClick={handleSubmit(onSubmitagenda)}
                      disabled={submitting}

                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >

                      Approved
                      <TaskAltIcon
                        style={{
                          color: "green",
                          // cursor: "not-allowed",
                        }}
                      >


                      </TaskAltIcon>
                    </Button>
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                </>
              ) : (<>
                <Grid item xs={5.5} sm={5.5} xl={5.5} >
                  <Button
                    className="Button"
                    onClick={handleSubmit(onSubmitagenda)}
                    disabled={submitting}
                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                    type="submit"
                  >
                    {Usertype == 'Therapist' ? 'Submit for Approval' : 'Approve'}
                    <TaskAltIcon
                      style={{
                        color: "red",
                        // cursor: "not-allowed",
                      }}
                    />
                  </Button>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>


              </>)}


            </>) : (<>
              <Grid item xs={5.5} sm={5.5} xl={5.5} >
                <Button
                  className="Button"
                  onClick={handleSubmit(onSubmitagenda)}
                  disabled={submitting || approve}
                  fullWidth
                  // style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  type="submit"
                >
                  Approved
                </Button>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

            </>)}

          </Grid>

        </>)}

      </Grid>
    </React.Fragment>
  );
};
export default AgendaRegistration;
