import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import User from "../Pages/User";
import Therapist from "../Pages/Therapist";
import Supervisor from "../Pages/Supervisor";


import TrackHours from "../Pages/TrackHours";
import Scheduler from "../Pages/Scheduler";
import Agendas from "../Pages/Agendas";
import SitePage from "../Pages/SitePage";
import IncidentReporting from "../Pages/IncidentReporting";
import Education from "../Pages/Education";


import Message from "../Pages/Message";
import EducationDetails from "../Pages/EducationDetails";
import SuperviserDashboard from "../Pages/SuperviserDashboard";
import TherapistDashboard from "../Pages/TherapistDashboard";
import LeaveRequest from "../Pages/LeaveRequest";
import PaymentMethod from "../Pages/PaymentMethod";

import VideoComponent from "../Pages/VideoComponent";









import Profile from "../Pages/Profile";
import UserRegistration from "../Pages/UserRegistration";
import TrackHoursRegistration from "../Pages/TrackHoursRegistration";
import AgendaRegistration from "../Pages/AgendaRegistration";
import TherapistRegistration from "../Pages/TherapistRegistration";
import SupervisorRegistration from "../Pages/SupervisorRegistration";
import Documents from "../Pages/Documents";
import SitePageRegistration from "../Pages/SitePageRegistration";
import AppointmentCreate from "../Pages/AppointmentCreate";
import IncidentRegistration from "../Pages/IncidentRegistration";
import EducationRegistration from "../Pages/EducationRegistration";
import GrowthPlanRegistration from "../Pages/GrowthPlanRegistration";
import LeaveRegistration from "../Pages/LeaveRegistration";
import TakePayment from "../Pages/TakePayment";
import ProcessPayment from "../Pages/ProcessPayment";
import SignupRequests from "../Pages/SignupRequests";
import SubscriptionFormPage  from "../Pages/SubscriptionFormPage";
import SubscriptionConfirmationPage from "../Pages/SubscriptionConfirmationPage ";
import SupervisorSignupDetails from "../Pages/SupervisorSignupDetails";
import SignUpForm from "../Pages/SignUpForm";
import ReniewSubscrition from "../Pages/ReniewSubscription";
import Tickets from "../Pages/Tickets";
import SupervisorSupervisee from "../Pages/SupervisorSupervisees";
import MarketingProfile from "../Pages/MarketingProfile";
import NotAllowed from "../Pages/NotAllowed";
import SupervisorSearch from "../Pages/SupervisorSearch";
import TherapistRequest from "../Pages/TherapistRequests";
import MavesorySubscription from "../Pages/MavesorySubscription";
// import SignUpForm from "../Pages/SignUpForm";

const getUserType = () => localStorage.getItem("User_Type");

const UserPageWrapper = () => {
  return getUserType() === "Admin" ? <User /> : <NotAllowed />;
};
const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // { path: "/User", component: getUserType() === "Admin" ? <User /> : <NotAllowed />, },
  { path: "/User", component: <UserPageWrapper /> },
  // { path: "/User", component: <User /> },
  { path: "/TrackHours", component: <TrackHours /> },
  { path: "/Scheduler", component: <Scheduler /> },
  { path: "/Agendas", component: <Agendas /> },
  { path: "/Therapist", component: <Therapist /> },
  { path: "/Supervisor", component: <Supervisor /> },
  { path: "/SitePage", component: <SitePage /> },
  { path: "/IncidentReporting", component: <IncidentReporting /> },
  { path: "/Education", component: <Education /> },
  { path: "/Tickets", component: <Tickets/> },
  { path: "/Supervisee", component: <SupervisorSupervisee/> },
  { path: "/MarketingProfile", component: <MarketingProfile/> },


  
  { path: "/Message", component: <Message /> },
  { path: "/EducationDetails", component: <EducationDetails /> },
  { path: "/SuperviserDashboard", component: <SuperviserDashboard /> },
  { path: "/TherapistDashboard", component: <TherapistDashboard /> },
  { path: "/LeaveRequest", component: <LeaveRequest /> },
  { path: "/PaymentMethod", component: <PaymentMethod /> },

  
  { path: "/VideoComponent", component: <VideoComponent /> },

  
  
  { path: "/Profile", component: <Profile /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/MavesorySubscription", component: <MavesorySubscription /> },
  { path: "/UserRegistration", component: <UserRegistration /> },
  { path: "/TrackHoursRegistration", component: <TrackHoursRegistration /> },
  { path: "/AgendaRegistration", component: <AgendaRegistration /> },
  { path: "/TherapistRegistration", component: <TherapistRegistration /> },
  { path: "/SupervisorRegistration", component: <SupervisorRegistration /> },
  { path: "/Documents", component: <Documents /> },
  { path: "/SitePageRegistration", component: <SitePageRegistration /> },
  { path: "/AppointmentCreate", component: <AppointmentCreate /> },
  { path: "/IncidentRegistration", component: <IncidentRegistration /> },
  { path: "/EducationRegistration", component: <EducationRegistration /> },
  { path: "/GrowthPlane", component: <GrowthPlanRegistration /> },
  { path: "/LeaveRegistration", component: <LeaveRegistration /> },
  { path: "/TakePayment", component: <TakePayment/> },
  { path: "/ProcessPayment", component: <ProcessPayment/> },
  { path: "/SignupRequests", component: <SignupRequests/> },
  { path: "/SupervisorSearch", component: <SupervisorSearch/> },
  { path: "/TherapistRequest", component: <TherapistRequest/> },

  
  

  
  

  
  

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
 
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/SignUpForm", component: <SignUpForm/> },
  { path: "/SupervisorSignupDetails", component: <SupervisorSignupDetails/> },
  { path: "/SubscriptionFormPage", component: <SubscriptionFormPage/> },
  { path: "/SubscriptionConfirmationPage", component: <SubscriptionConfirmationPage/> },
  { path: "/ReniewSubscrition", component: <ReniewSubscrition/> },
  {
    path: "/",
    component: <Navigate to="/Login" />,
  },

];

export { authProtectedRoutes, publicRoutes };
