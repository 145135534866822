// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography, NativeSelect, Paper } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
const LeaveRequest = () => {
  document.title = "LeaveRequest | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  function applyRefresh() {
    setRefresh(!refresh)
  }
  const navigate = useNavigate();

  const columns = [
    {
      field: 'leavePurpose',
      headerClassName: 'super-app-theme--header',
      headerName: 'Leave Purpose',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          style={{
            maxWidth: '100%',
            // wordWrap: 'break-word',
            // whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            // textOverflow: 'ellipsis',
            display: 'block',
          }}
          onClick={(event) => {
            openLeaveRequestRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.leavePurpose}`}</Button>
      )
    },

    {
      field: 'additionalInfo',
      headerClassName: 'super-app-theme--header',
      headerName: 'Additional Information',
      flex: 0.2
    },

    {
      field: 'leaveStartDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'StartDate',
      flex: 0.2,
    },
    {
      field: 'leaveEndDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'EndDate',
      flex: 0.2,
    },

    {
      field: 'createdDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Activity time stamp ',
      flex: 0.23,
      renderCell: (params) => {
        return params.value ? moment(params.value).format('MM/DD/YYYY HH:mm') : '';
      },
    },
    {
      field: 'approveDateTime',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved Date',
      flex: 0.3
    },
    {
      field: 'approvedBy',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved By',
      flex: 0.19
    },
    {
      field: 'approved',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approve',
      flex: 0.12,
      renderCell: cellValues => {
        return cellValues.row.approved ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          <CloseIcon style={{ color: 'red' }} />
        )
      }
    },

    {
      field: 'isActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {

                      inactive: true,
                      id: cell.row.id
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }

  ]
  const [selectedValue, setSelectedValue] = useState('')
  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    applyRefresh(newValue)
    console.log("Selected value my kya ha : ", newValue)
  }
  const [rows, setRows] = useState(null)
  const UserType = localStorage.getItem("User_Type")
  const [seletedTherpist, setseletedTherpist] = useState(0);
  const [TherapistName, setTherapistName] = useState('');
  const [TherapistFilter, setTherapistFilter] = useState(false);

  const onFilterSearch = event => {
    if (UserType == 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}&TherpistId=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    } else {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}&TherpistId=${seletedTherpist ? seletedTherpist :''}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    }

  }
  useEffect(() => {
    if (UserType == 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}&TherpistId=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    } 
  }, [refresh, selectedValue, UserType,seletedTherpist])
  useEffect(() => {
    if (UserType !== 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}&TherpistId=${seletedTherpist ? seletedTherpist :''}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    } 
  }, [refresh, selectedValue, UserType])
  const UserName = localStorage.getItem("UserName")
  const [Data, setData] = useState([])
  const Userid = localStorage.getItem('userid')

 

  const openLeaveRequestRegistration = (event, id = 0) => {
    navigate('/LeaveRegistration', { state: { LeaveRequestid: id } })
  }
  const handleDownload = async () => {
    const doc = new jsPDF();
  
    // Set margins and page dimensions
    const margin = 10;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
    // Draw the title and border on the first page
    const drawFirstPageHeader = () => {
      // Title text
      const title = 'Leave Request';
      doc.setTextColor(0, 102, 204); // Blue color
      doc.setFontSize(19);
      doc.setFont('helvetica', 'bold');
  
      const titleWidth = doc.getTextWidth(title);
      const titleX = (pageWidth - titleWidth) / 2; // Center the title
      const titleY = 20; // Fixed y position for the title on the first page
      doc.text(title, titleX, titleY);
  
      doc.setFont('helvetica', 'normal');
      doc.setTextColor(0, 0, 0);
    };
  
    const drawBorder = () => {
      doc.setDrawColor(0, 0, 0); // Border color (black)
      doc.rect(margin, margin, pageWidth - margin * 2, pageHeight - margin * 2); // Border dimensions
    };
  
    drawFirstPageHeader();
    drawBorder();
    let yPosition = 30;
  
    // Center therapist's report text if the filter is true
    if (TherapistFilter === true) {
      const reportText = `${TherapistName}'s Report`;
      doc.setFontSize(14); // Set font size for report text
      doc.setTextColor(0, 0, 0); // Set color for report text
  
      const reportTextWidth = doc.getTextWidth(reportText);
      const reportTextX = (pageWidth - reportTextWidth) / 2; // Center the report text
      doc.text(reportText, reportTextX, yPosition); // Use the calculated x-position
      yPosition += 15; // Adjust spacing
    }
  
    const columns = [
      { header: 'ID', dataKey: 'id' },
      { header: 'Leave Purpose', dataKey: 'leavePurpose' },
      { header: 'Additional Information', dataKey: 'additionalInfo' },
      { header: 'Start Date', dataKey: 'leaveStartDate' },
      { header: 'End Date', dataKey: 'leaveEndDate' },
      { header: 'Approved By', dataKey: 'createdBy' },
    ];
  
    const rows = Data.map(item => ({
      id: item.id,
      leavePurpose: item.leavePurpose,
      additionalInfo: item.additionalInfo,
      leaveStartDate: item.leaveStartDate,
      leaveEndDate: item.leaveEndDate,
      createdBy: item.createdBy,
    }));
  
    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: rows.map(item => Object.values(item)),
      startY: yPosition, // Start table below the therapist's report text
      margin: { top: margin + 5, bottom: margin + 5 }, // Padding for table within border
  
      didDrawPage: (data) => {
        drawBorder(); // Draw border on every page
      },
    });
  
    const fileName = `leave_Request_${moment(new Date()).format('DD_MMM_YY')}.pdf`; // Adjusted filename for readability
    doc.save(fileName);
  };
  ;
  
  
  
  
  const [TherapistList, setTherapistList] = useState([]);
  const { control, setValue, getValues } = useForm();


  const userType = localStorage.getItem('User_Type');

  useEffect(() => {
    if (userType =='Admin'){
      axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.fullName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    } else{
      axios
        .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/GetThrepistListBySupervisorId?supervisorId=${Userid}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.fullName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    }
  }, []);
  const onSeacrhButtonClick = event => {
    setLoading(true)
    setTherapistFilter(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}&TherpistId=${seletedTherpist ? seletedTherpist :''}`,
        { headers }
      )
      .then(response => {
        console.log('response.data: ', response)
        setData(response)
        setRows(response)
        setLoading(false)

      })
      .catch(error => {
        console.error('There was an error!', error)
      })
   

  }
  return (
    <div className="page-content">
      <Container >
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <Paper
              elevation={4}
              style={{
                padding: '20px',
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                marginBottom: '16px',
                width: '100%',
                margin: '10px auto',
                border: '2px solid #326C1D',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      color: 'black',
                      fontSize: '18px',
                      marginTop: '6px',
                      fontWeight: 'bold', // Make bold
                    }}
                  >
                    Type:
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <NativeSelect
                    onChange={handleChange}
                    value={selectedValue}
                    style={{
                      width: '100%',
                      height: '40px',
                      padding: '5px 10px',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: '400',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      outline: 'none',
                      appearance: 'none',
                      backgroundColor: 'white',
                    }}
                  >
                    <option value=''>All</option>
                    <option value='pending'>Pending</option>
                    <option value='approved'>Approved</option>
                  </NativeSelect>
                </Grid>

                {userType === 'Supervisor' || userType === 'Admin' ? (
                  <>
                    <Grid item xs={1.3}>
                      <Typography
                        style={{
                          color: '#333',
                          fontSize: '18px',
                          marginTop: '6px',
                          fontWeight: 'bold',
                          width: '90px',
                        }}
                      >
                        Therapist:
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <ReactSelect
                        options={TherapistList}
                        value={TherapistList.find(
                          (option) => option.value === getValues('therapistsId')
                        )}
                        onChange={(selectedOption) => {
                          const therapistId = selectedOption ? selectedOption.value : 0;
                          setValue('therapistsId', therapistId);
                          setTherapistName(selectedOption.label)
                          setseletedTherpist(therapistId);
                          // onTherapistSearch(therapistId);
                        }}
                        styles={{
                          container: (base) => ({
                            ...base,
                            width: '100%',
                          }),
                          control: (base) => ({
                            ...base,
                            height: '40px',
                            padding: '0px 5px',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: '400',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            backgroundColor: 'white',
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            padding: '0px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#aaa',
                          }),
                        }}
                        placeholder="Select Therapist"
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                    <Button
                      className="Button"
                      onClick={onSeacrhButtonClick}
                      fullWidth
                      // disabled={onFilterSearch}
                      variant='contained' style={{
                        fontSize: '14px', fontWeight: 'bold',
                      }}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Grid>
                  </>
                ) : (
                  <Grid item xs={1}></Grid>
                )}

                <Grid container item xs={userType === 'Therapist' ? 6 : 12} justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      className="Button"
                      onClick={handleDownload}
                      fullWidth
                      disabled={!Data || Data.length === 0}
                      variant='contained' style={{
                        fontSize: '14px', fontWeight: 'bold',
                        float: 'right'
                      }}
                      type="submit"
                    >
                      Download
                      <DownloadForOfflineIcon />
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button className="Button" onClick={onFilterSearch} variant='contained' style={{
                      fontSize: '14px', fontWeight: 'bold',
                      float: 'right'
                    }}>
                      <CachedOutlinedIcon
                        fontSize='small'
                        style={{
                          color: 'white',
                          paddingRight: '4px',
                          paddingBottom: '2px',

                        }}
                      ></CachedOutlinedIcon>
                      Refresh
                    </Button>
                  </Grid>

                  <Grid item>
                    <Grid item >
                      <Button className="Button" onClick={openLeaveRequestRegistration} variant='contained' style={{
                        fontSize: '14px', fontWeight: 'bold',
                        float: 'right'
                      }} >
                        <AddOutlinedIcon />
                        Add New
                      </Button>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} xl={12}>
            <Box mb={0} style={{ marginTop: 0 }}>
              {isLoading ? (
                <div style={{ margin: '5rem', textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rows === null || rows.length === 0 ? ("No Record Found") : (
                    <DataGrid
                      rows={rows}
                      classes={gridClasses}
                      rowHeight={20}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>


  )
}

export default LeaveRequest
