import React, { useState } from "react";

import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';

//i18n
import { withTranslation } from "react-i18next";

//import images
import logoSm from "../../assets/images/maevisorylogo.png";
import logoDark from "../../assets/images/maevisorylogo.png";
import logoLight from "../../assets/images/maevisorylogo.png";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import AppsDropdown from "../../components/Common/TopbarDropdown/AppsDropdown";
import PaymentDropdown from "../../components/Common/TopbarDropdown/PaymentDropdown";
import SearchIcon from '@mui/icons-material/Search';
import RequestsDropDown from "../../components/Common/TopbarDropdown/RequestsDropDown";
const Header = (props) => {
  const [search, setsearch] = useState(false);
  const navigate = useNavigate();
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  const User_Type = localStorage.getItem('User_Type')
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center" style={{ background: '#98b2a1' }}>
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img alt="logo-sm-dark" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="logo-dark" height="42" />
                </span>
              </Link>

              {/* <Link to="/" className="logo logo-light"> */}
              <div className="logo logo-light">
                <span className="logo-sm">
                  {/* <h4 style={{ color: 'white' }}>Maevisory</h4> */}
                  <img src={logoSm} alt="logo-sm-light" height="24" width="24" />
                </span>
                <Container >
                  <Grid container alignItems="center" >
                    <Grid item xs={12}>
                      {/* <img src={logoLight} alt="logo-light" style={{width:"70px",height:"70px"}} /> */}
                    </Grid>
                    {/* <Grid item xs={6}>
                      <h4 style={{ color: 'white', marginLeft: '-70px', marginTop: '7px' }}>Maevisory</h4>
                    </Grid> */}
                  </Grid>
                </Container>

              </div>
              {/* </Link> */}
            </div>

            {/* <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle();
              }}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button> */}

            <form className="app-search d-none d-lg-block" style={{ marginLeft: '25px' }}>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="ri-search-line"></span>
              </div>
            </form>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="ri-search-line" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="ri-search-line" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <RequestsDropDown /> */}
            {User_Type !== 'Therapist' && (
              <div className="dropdown d-none d-lg-inline-block ms-1" style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => navigate("/TherapistRequest")}>
                <svg width="36" height="36" viewBox="0 0 24 24" fill="currentColor" >
                  <path d="M16 17H9.375q-.575 0-.937.35t-.438.8t.138.875t.762.625l5.725 2.275q.25.1.488.063t.437-.163t.325-.337T16 21zM10 2q1.65 0 2.825 1.175T14 6t-1.175 2.825T10 10T7.175 8.825T6 6t1.175-2.825T10 2m9.225 9q.725 0 1.25.487T21 12.7q0 .575-.325 1.05t-.875.675L18 15H9.375q-1.425 0-2.4.975T6 18.375q0 .45.125.863T6.45 20H3v-4q0-2.075 1.463-3.537T8 11z" />
                </svg>

              </div>
            )}
            {User_Type == 'Therapist' && (
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  onClick={() => navigate("/SupervisorSearch")}
                  className="btn header-item noti-icon"
                  data-toggle="fullscreen"
                >
                  <SearchIcon sx={{ fontSize: 39, fontWeight: 'bold' }} />
                </button>
              </div>
            )}
            {User_Type == 'Supervisor' && (
              <div className="dropdown d-none d-lg-inline-block ms-1" style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => navigate("/MarketingProfile")}>
                <svg width="38" height="38" viewBox="0 0 16 16">
                  <path
                    fill="currentColor"
                    d="M9.5 5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM9 9.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-2.39-3C6.61 7.328 5.891 8 5 8s-1.61-.672-1.61-1.5S4.109 5 5 5s1.61.672 1.61 1.5M5 8h-.04c-.92 0-1.72.585-1.94 1.42c-.08.295.16.582.485.582h3c.326 0 .565-.286.486-.582C6.768 8.586 5.971 8 5.051 8h-.04z"
                  />
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M.327 3.64C0 4.282 0 5.12 0 6.8v2.4c0 1.68 0 2.52.327 3.16a3.02 3.02 0 0 0 1.31 1.31c.642.327 1.48.327 3.16.327h6.4c1.68 0 2.52 0 3.16-.327a3 3 0 0 0 1.31-1.31c.327-.642.327-1.48.327-3.16V6.8c0-1.68 0-2.52-.327-3.16a3 3 0 0 0-1.31-1.31c-.642-.327-1.48-.327-3.16-.327h-6.4c-1.68 0-2.52 0-3.16.327a3.02 3.02 0 0 0-1.31 1.31m10.9-.638h-6.4c-.857 0-1.44 0-1.89.038c-.438.035-.663.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.145.38-.18.819c-.037.45-.038 1.03-.038 1.89v2.4c0 .857.001 1.44.038 1.89c.036.438.101.663.18.819c.192.376.498.682.874.874c.156.08.381.145.819.18c.45.036 1.03.037 1.89.037h6.4c.857 0 1.44 0 1.89-.037c.438-.036.663-.101.819-.18c.376-.192.682-.498.874-.874c.08-.156.145-.381.18-.82c.037-.45.038-1.03.038-1.89v-2.4c0-.856-.001-1.44-.038-1.89c-.036-.437-.101-.662-.18-.818a2 2 0 0 0-.874-.874c-.156-.08-.381-.145-.819-.18c-.45-.037-1.03-.038-1.89-.038"
                    clipRule="evenodd"
                  />
                </svg>

              </div>
            )}

            <NotificationDropdown />
            <PaymentDropdown />

            <ProfileMenu />



            {/* <div
              className="dropdown d-inline-block"
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-cog"></i>
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
