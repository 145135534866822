import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import { withTranslation } from "react-i18next";
import { Grid, Typography, NativeSelect, IconButton ,Button  ,CircularProgress } from '@mui/material'
import moment from 'moment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

const PaymentDropdown = () => {
  const [singlebtn, setSinglebtn] = useState(false);

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }


  const [isLoading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('')
  const [rows, setRows] = useState(null)

  const [userType, setUserType] = useState("");




  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])



  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };



  function applyRefresh() {
    setRefresh(!refresh)
  }
  const [invoiceData, setInvoiceData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInvoiceClick = (rawId) => {
    setIsModalOpen(true);
    setInvoiceData(null); // Clear previous data
    axios
      .post(`${process.env.REACT_APP_API_URL}/Payment/Get-Invoice?id=${rawId}`, null, { headers })
      .then((response) => {
        setInvoiceData(response); // Store invoice data
        seturlFile(response.hostedInvoiceUrl)
      })
      .catch((error) => {

      });
  };
  const [urlFile, seturlFile] = useState('')
  const [fileUrl, setFileUrl] = useState('');
  const [ShowFram, setShowFram] = useState(false);

  const columns = [
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Therapist',
      cellClassName: 'gridCell',
      flex: 0.4, // Reduced flex to adjust width
      minWidth: 150, // Added minWidth to prevent shrinking too small
      renderCell: (cellValues) => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            openTakePayment(event, cellValues.row.id)
          }}
        >
          {`${cellValues.row.userName}`}
        </Button>
      ),
    },
  
    {
      field: 'totalPayment',
      headerClassName: 'super-app-theme--header',
      headerName: 'Payment Amount',
      flex: 0.25, // Reduced flex value
      minWidth: 120,
    },
  
    {
      field: 'paymentMode',
      headerClassName: 'super-app-theme--header',
      headerName: 'Mode Of Payment',
      flex: 0.25, // Reduced flex value
      minWidth: 130,
    },
  
    {
      field: 'paymentDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Payment Date',
      flex: 0.25, // Reduced flex value
      minWidth: 120,
      renderCell: (cellValues) => moment(cellValues.value).format('MM-DD-YYYY'),
    },
  
    {
      field: 'status',
      headerClassName: 'super-app-theme--header',
      headerName: 'Status',
      flex: 0.2, // Reduced flex value
      minWidth: 100,
    },
  
    {
      field: 'invoice',
      headerClassName: 'super-app-theme--header',
      headerName: 'Invoice',
      flex: 0.2, // Reduced flex value
      minWidth: 90,
      renderCell: (cellValues) => (
        <IconButton color="primary" onClick={() => handleInvoiceClick(cellValues.row.id)}>
          <ReceiptIcon />
        </IconButton>
      ),
    },
  ];
  
  useEffect(() => {
    setUserType(localStorage.getItem("User_Type"));
  }, [])
  const [totalCompletePayment, setTotalCompletePayment] = useState(0);
  const [totalPendingPayment, setTotalPendingPayment] = useState(0);
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/getPaymentRequests`, { headers })
      .then(response => {
        setLoading(false)
        const data = response;
        if (Array.isArray(data)) {
          setRows(data);

          // Filter the data to include only items where the status is "complete"
          const totalComplete = data
            .filter(payment => payment.status === 'Completed')
            .reduce((sum, payment) => {
              console.log('Complete Payment:', payment.totalPayment); // Log each complete payment
              return sum + (payment.totalPayment || 0); // Ensure totalPayment is a number
            }, 0);

          const totalPending = data
            .filter(payment => payment.status === 'Pending')
            .reduce((sum, payment) => {
              console.log('Pending Payment:', payment.totalPayment); // Log each pending payment
              return sum + (payment.totalPayment || 0); // Ensure totalPayment is a number
            }, 0);
          setTotalCompletePayment(totalComplete);
          setTotalPendingPayment(totalPending);
        } else {
          console.error('Data is not an array:', data);
        }
      })
      .catch(error => { })


  }, [refresh, selectedValue])
  const openTakePayment = (event, id = 0) => {
    navigate('/TakePayment', { state: { paymentId: id } })
    setSinglebtn(false)
  }
  
  return (
    <React.Fragment>
    <Dropdown
      isOpen={singlebtn}
      toggle={() => setSinglebtn(!singlebtn)}
      className="dropdown d-inline-block ms-1"
      tag="li"
    >
      <DropdownToggle
        className="btn header-item noti-icon"
        tag="button"
        id="page-header-notifications-dropdown"
      >
        <AttachMoneySharpIcon sx={{ fontSize: 34, fontWeight: "bold" }} />
      </DropdownToggle>
      <DropdownMenu
         sx={{
          width: "80vw",
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: "#fff",
          overflow: "hidden",
          border: "1px solid #ddd",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // This centers the content vertically, which may not be necessary if you want the button on top
          height: "auto",
          }}
        >
          <Container sx={{
             width: "70vw",
             maxWidth: "80vw",
             minWidth: "70vw",
             overflow: "hidden",
             padding: 0,
             display: "flex",
             flexDirection: "column",
             justifyContent: "flex-start", // Al
          }}>
            {/* Modal for Invoice Details */}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 700,
                  bgcolor: "background.paper",
                  p: 4,
                  borderRadius: 2,
                  boxShadow: 24,
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    borderBottom: "1px solid #ddd",
                    pb: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#3f51b5" }}
                  >
                    Invoice Detail
                  </Typography>
                  <Button
                    onClick={() => setIsModalOpen(false)}
                    sx={{
                      minWidth: "auto",
                      padding: 0,
                      color: "#f44336",
                      "&:hover": {
                        backgroundColor: "rgba(244, 67, 54, 0.1)",
                      },
                    }}
                  >
                    <CancelOutlinedIcon />
                  </Button>
                </Box>

                {invoiceData ? (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        textAlign: "justify",
                      }}
                    >
                      Invoice is available.
                    </Typography>
                    <Grid item xs={12} sm={12} xl={12} mb={2}>
                      <a
                        href={urlFile}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{ color: "#fff", backgroundColor: "#3f51b5" }}
                        >
                          View Invoice
                        </Button>
                      </a>
                    </Grid>
                  </Box>
                ) : (
                  <Typography>No Invoice found</Typography>
                )}

                <Box mt={2} textAlign="right">
                  <Button
                    variant="contained"
                    onClick={() => setIsModalOpen(false)}
                    sx={{
                      backgroundColor: "#3f51b5",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#303f9f",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Modal>

            {/* Payment Request Details */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{ color: "#333", fontSize: "18px", fontWeight: "bold" }}
                >
                  Payment Request
                </Typography>
              </Grid>

              <Grid item xs={6} textAlign="right">
                {userType !== "Therapist" && (
                  <Button
                    variant="contained"
                    onClick={openTakePayment}
                    className='Button'
                    // sx={{
                    //   backgroundColor: "#3f51b5",
                    //   color: "#fff",
                    //   fontWeight: "bold",
                    //   "&:hover": {
                    //     backgroundColor: "#303f9f",
                    //   },
                    // }}
                  >
                    <AddOutlinedIcon
                      fontSize="small"
                      sx={{ marginRight: 0.5 }}
                    />
                    Request Payment
                  </Button>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{ color: "#555", fontSize: "15px" }}
                >
                  Total Paid: <b>${totalCompletePayment}</b>
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography
                  sx={{ color: "#555", fontSize: "15px" }}
                >
                  Total Pending: <b>${totalPendingPayment}</b>
                </Typography>
              </Grid>
            </Grid>

            {/* DataGrid for Records */}
            <Box mb={2}>
              {isLoading ? (
                <Box
                  sx={{ display: "flex", justifyContent: "center", margin: "4rem 0" }}
                >
                  <CircularProgress />
                </Box>
              ) : rows?.length ? (
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowSpacing={getRowSpacing}
                    sx={gridRowStyle}
                    pagination
                    autoPageSize
                  />
                </Box>
              ) : (
                <Typography textAlign="center">No Record Found</Typography>
              )}
            </Box>
          </Container>
      </DropdownMenu>
    </Dropdown>
  </React.Fragment>
  )
}

export default withTranslation()(PaymentDropdown);