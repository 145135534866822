import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Moment from 'moment'
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Typography,
  Grid,
  TextField,
  InputLabel
} from "@mui/material";
import { useAlert } from "react-alert";

const SitePageRegistration = props => {
  const fields = [
    "id",
    "userId",
    "practiceId",
    "managerPhone",
    "address",
    "phone",
    "website",
    "startDate",
    "endDate",
    "locationName",
    "locationType",
    "otherDetails",
    "inActive",
    "otherDetails",
    "otherDetailsCounseling",
    "hospital",
    "school",
    "government",
    "privatePractice",
    "agency",
    "nonProfit",
    "otherCheck",
    "general",
    "groups",
    "marriageFamily",
    "drugAlcohol",
    "carrierVocational",
    "rehabilitation",
    "academic",
    "childAdolescent",
    "artTherapy",
    "otherCounselingCheck",
    "userName",
    "siteType",
    "siteSupervisorTitle",
    "siteSupervisorEmail",
    "activeSite",
    "sexOffender",
    "supervisorlastName",
    "supervisorfirstName"
  ];
  const defValues = {
    id: 0,
    userId: 0,
    practiceId: 0,
    managerPhone: "",
    address: "",
    phone: "",
    website: "",
    startDate: "",
    endDate: "",
    locationName: "",
    locationType: "",
    otherDetails: "",
    inActive: true,
    otherDetails: "",
    otherDetailsCounseling: "",
    hospital: "",
    school: "",
    government: "",
    privatePractice: "",
    agency: "",
    nonProfit: "",
    otherCheck: "",
    general: "",
    groups: "",
    marriageFamily: "",
    drugAlcohol: "",
    carrierVocational: "",
    rehabilitation: "",
    academic: "",
    childAdolescent: "",
    artTherapy: "",
    otherCounselingCheck: "",
    userName: "",
    siteType: '',
    siteSupervisorEmail: '',
    siteSupervisorTitle: '',
    activeSite: '',
    supervisorfirstName: '',
    supervisorlastName: '',
    sexOffender: ''

  };

  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const _userId = props.uid;
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  const validationSchema = Yup.object({
    locationName: Yup.string().required("Site  Name is required"),
    managerPhone: Yup.string()
      .required("Please Enter Supervisor Cell Phone Number"),
    phone: Yup.string()
      .required("Supervisor Office Number"),
    address: Yup.string()
      .required("Please Enter address")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const locationType = watch('locationType', '');
  const carrierVocational = watch('otherCounselingCheck', false);

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }


  const [managerPhone, setmanagerPhone] = useState("");
  const [Phone, setPhone] = useState("");
  const [endDate, setendDate] = React.useState('')
  const [startDate, setstartDate] = React.useState('')
  const Userid = localStorage.getItem('userid')
  const onSubmit = data => {
    let postData = data;
    postData.userId = Userid
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Locations/AddLocations`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

            onClose: () => {
              props.handleClose()

            }

          });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     navigate("/SitePage");
          //   }
          // });
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  useEffect(() => {
    if (_userId) {

      axios
        .get(`${process.env.REACT_APP_API_URL}/Locations/getLocationById?id=${_userId}`, {
          headers
        })
        .then(response => {
          fields.forEach(field => {
            setValue(field, response[field]);
            setmanagerPhone(response.managerPhone)
            setPhone(response.phone)
            var dateYMD = Moment(response.endDate).format('YYYY-MM-DD')
            setendDate(dateYMD)
            var dateYMD = Moment(response.startDate).format('YYYY-MM-DD')
            setstartDate(dateYMD)


          });



        })
        .catch(error => { });
    }
  }, []);


  return (
    <React.Fragment>
      <Grid container>

        {/* <Box sx={style}> */}

        <Grid container spacing={1}>

          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}>  Site Name</InputLabel>
            <Controller
              name="locationName"
              {...register("locationName")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please Enter site name"
                  {...field}
                  fullWidth
                  error={errors.locationName}
                />}
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.locationName?.message}</p>
          </Grid>
          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}>Site Address</InputLabel>
            <Controller
              name="address"
              {...register("address")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please Enter address"
                  {...field}
                  fullWidth
                  error={!!errors.address}
                  helperText={errors.address ? errors.address.message : ''}

                />}
            />

          </Grid>
          {/* New Fileds */}
          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}>Site Website</InputLabel>
            <Controller
              name="website"
              {...register("website")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please Enter Website"
                  {...field}
                  fullWidth
                />}
            />
          </Grid>
          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}>Supervisor First Name</InputLabel>
            <Controller
              name="supervisorfirstName"
              {...register("supervisorfirstName")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please Enter First Name"
                  {...field}
                  fullWidth
                />}
            />
          </Grid>
          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}>Supervisor Last Name</InputLabel>
            <Controller
              name="supervisorlastName"
              {...register("supervisorlastName")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please Enter Last Name"
                  {...field}
                  fullWidth
                />}
            />
          </Grid>

          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}>Site Supervisor Email</InputLabel>
            <Controller
              name="siteSupervisorEmail"
              {...register("siteSupervisorEmail")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please Enter Supervisor Email"
                  {...field}
                  fullWidth
                />}
            />

          </Grid>
          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}>   Site Supervisor Cell Phone Number
            </InputLabel>
            <InputMask
              mask='+1 999-999-9999'
              disabled={false}
              value={managerPhone}
              style={{ height: '30px' }}
              onChange={ev => {
                console.log('ee:', ev.target.value)
                setValue('managerPhone', ev.target.value)
                setmanagerPhone(ev.target.value)

              }
              }
            >
              {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter cell phone'
                error={!!errors.managerPhone}
                helperText={errors.managerPhone ? errors.managerPhone.message : ''}
              />}
            </InputMask>
            <Typography
              variant="caption"
              style={{ color: 'red', fontSize: '12px', marginTop: '4px', display: 'block' }}
            >
              **Emergency Use Only**
            </Typography>
          </Grid>

          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}> Site Supervisor Office Number </InputLabel>
            <InputMask
              mask='+1 999-999-9999'
              disabled={false}
              value={Phone}
              style={{ height: '30px' }}
              onChange={ev => {
                console.log('ee:', ev.target.value)
                setValue('phone', ev.target.value)
                setPhone(ev.target.value)

              }
              }
            >
              {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter Office Number'
                error={!!errors.address}
                helperText={errors.phone ? errors.phone.message : ''}
              />}
            </InputMask>

          </Grid>

          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}>Site Supervisor Title</InputLabel>
            <Controller
              name="siteSupervisorTitle"
              {...register("siteSupervisorTitle")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please Enter Tittle"
                  {...field}
                  fullWidth
                />}
            />

          </Grid>
          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}> Start Date</InputLabel>

            <Controller
              name="startDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={startDate ? startDate : ''}
                  onChange={e => {
                    setValue('startDate', e.target.value)
                    setstartDate(e.target.value)
                  }}
                // error={errors.startDate}

                />

              }
            />
            {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.startDate?.message}</p> */}

          </Grid>

          <Grid item xs={6} >
            <InputLabel style={{ fontSize: '14px' }}> End Date</InputLabel>

            <Controller
              name="endDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={endDate ? endDate : ''}
                  onChange={e => {
                    setValue('endDate', e.target.value)
                    setendDate(e.target.value)
                  }}


                />
              }
            />

          </Grid>
          <Grid item xs={1} style={{ marginTop: '35px' }} >
            <lable style={{ fontWeight: 'bold' }}>Or</lable>
          </Grid>
          <Grid item xs={5} style={{ marginTop: '35px', marginLeft: '-16px' }} >
            <input
              name='activeSite'
              size='small'
              type='checkbox'
              {...register('activeSite')}
              id='activeSite'
            />
            <lable >Still an Active Site</lable>
          </Grid>
          <Grid item xs={12} >
            <InputLabel style={{ fontSize: '14px' }}> Site Type </InputLabel>
            <Controller
              name="siteType"
              margin="dense"
              {...register("siteType")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  style={{ marginTop: 0, marginBottom: 0 }}
                  {...field}
                  size="small"
                  options={[
                    { value: "Private Practice", label: "Private Practice" },
                    { value: "Hospital", label: "Hospital" },
                    { value: "Agency", label: "Agency" },
                    { value: "School k-12", label: "School k-12" },
                    { value: "University", label: "University" },
                    { value: "Prison", label: "Prison" },
                    { value: "Retirement Facility", label: "Retirement Facility" },
                    { value: "Military", label: "Military" },
                    { value: "Other", label: "Other" },


                  ]}
                  value={{ label: getValues("siteType") }}
                  onChange={value => {
                    setValue("siteType", value.value);

                  }}
                />}
            />
          </Grid>
          <Grid container spacing={1} style={{ padding: '15px' }}>
            <Grid item xs={12} sm={12} xl={12}>
              <Typography style={{ fontSize: '14px', color: 'black', fontWeight: 'bold' }}>
                Type of Counseling Services: (select all that Applies)

              </Typography>
            </Grid>

            <Grid item xs={6} >

              <input
                name='general'
                size='small'
                type='checkbox'
                {...register('general')}
                id='general'
              />
              <lable style={{ marginLeft: '3px', }}>Individuals</lable>
            </Grid>
            <Grid item xs={6} >
              <input
                name='groups'
                size='small'
                type='checkbox'
                {...register('groups')}
                id='groups'
              />
              <lable style={{ marginLeft: '3px', }}>Groups</lable>

            </Grid>
            <Grid item xs={6} >
              <input
                name='marriageFamily'
                size='small'
                type='checkbox'
                {...register('marriageFamily')}
                id='marriageFamily'
              />
              <lable style={{ marginLeft: '3px', }}>Marriage and Family</lable>

            </Grid>
            <Grid item xs={6} >
              <input
                name='drugAlcohol'
                size='small'
                type='checkbox'
                {...register('drugAlcohol')}
                id='drugAlcohol'
              />
              <lable style={{ marginLeft: '3px', }}>Drug and Alcohol</lable>

            </Grid>
            <Grid item xs={6} >
              <input
                name='carrierVocational'
                size='small'
                type='checkbox'
                {...register('carrierVocational')}
                id='carrierVocational'
              />
              <lable style={{ marginLeft: '3px', }}>Career and Vocational</lable>

            </Grid>
            <Grid item xs={6} >
              <input
                name='rehabilitation'
                size='small'
                type='checkbox'
                {...register('rehabilitation')}
                id='rehabilitation'
              />
              <lable style={{ marginLeft: '3px', }}>Rehabilitation</lable>

            </Grid>
            <Grid item xs={6} >
              <input
                name='academic'
                size='small'
                type='checkbox'
                {...register('academic')}
                id='academic'
              />
              <lable style={{ marginLeft: '3px', }}>Academic</lable>

            </Grid>
            <Grid item xs={6} >
              <input
                name='childAdolescent'
                size='small'
                type='checkbox'
                {...register('childAdolescent')}
                id='childAdolescent'
              />
              <lable style={{ marginLeft: '3px', }}>Child and Adolescent</lable>

            </Grid>
            <Grid item xs={6} >
              <input
                name='sexOffender'
                size='small'
                type='checkbox'
                {...register('sexOffender')}
                id='sexOffender'
              />
              <lable style={{ marginLeft: '3px', }}>Sex Offender</lable>

            </Grid>
            {/* <Grid item xs={6} >
              <input
                name='artTherapy'
                size='small'
                type='checkbox'
                {...register('artTherapy')}
                id='artTherapy'
              />
              <lable style={{ marginLeft: '3px', }}>Art Therapy</lable>

            </Grid> */}
            <Grid item xs={6} >
              <input
                name='otherCounselingCheck'
                size='small'
                type='checkbox'
                {...register('otherCounselingCheck')}
                id='otherCounselingCheck'
              />
              <lable style={{ marginLeft: '3px', }}>Others</lable>

            </Grid>
            {carrierVocational && (
              <>
                <Grid item xs={6} >
                  <Typography style={{ fontSize: '14px', marginTop: '10px' }}>
                    Type Other Details if selected other above:

                  </Typography>

                </Grid>
                <Grid item xs={6} style={{ marginBottom: '10px' }}>
                  <Controller
                    name="otherDetailsCounseling"
                    {...register("otherDetailsCounseling")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Please Enter Other details"
                        {...field}
                        fullWidth

                      />}
                  />

                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={6} style={{ marginTop: '10px' }}>
            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              disabled={submitting}
              fullWidth
              style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
              type="submit"
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={6} style={{ marginTop: '10px' }}>
            <Button
              className="Button"
              onClick={props.handleClose}
              disabled={submitting}
              fullWidth
              style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
            >
              Cancel
            </Button>
          </Grid>

        </Grid>

      </Grid>
    </React.Fragment>
  );
};
export default SitePageRegistration;
