import React from "react";

const NotAllowed = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#1a1a1a",
        color: "#fff",
        fontFamily: "'Arial', sans-serif",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          textAlign: "center",
          padding: "40px",
          backgroundColor: "#2a2a2a",
          borderRadius: "20px",
          boxShadow: "0 8px 30px rgba(0, 0, 0, 0.5)",
          animation: "fadeIn 1.5s ease-in-out",
          position: "relative",
        }}
      >
        <h1
          style={{
            fontSize: "3rem",
            color: "#ff6b6b",
            animation: "bounce 2s infinite",
            marginBottom: "20px",
          }}
        >
          🚫 Access Denied
        </h1>
        <p
          style={{
            fontSize: "1.5rem",
            color: "#ddd",
            marginBottom: "30px",
          }}
        >
          Oops! You don't have permission to view this page.
        </p>

        {/* Animated background elements */}
        <div
          style={{
            position: "absolute",
            top: "-50px",
            left: "-50px",
            width: "100px",
            height: "100px",
            backgroundColor: "rgba(255, 107, 107, 0.2)",
            borderRadius: "50%",
            animation: "float 6s infinite ease-in-out",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "-50px",
            right: "-50px",
            width: "150px",
            height: "150px",
            backgroundColor: "rgba(255, 107, 107, 0.2)",
            borderRadius: "50%",
            animation: "float 8s infinite ease-in-out",
          }}
        />
      </div>

      {/* Define animations using <style> tag */}
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(-20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-20px);
            }
            60% {
              transform: translateY(-10px);
            }
          }

          @keyframes float {
            0%, 100% {
              transform: translateY(0) rotate(0deg);
            }
            50% {
              transform: translateY(-20px) rotate(10deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default NotAllowed;